import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useUserState } from "../../../Hooks/useUserState";
import jwt_decode from "jwt-decode"

const RequireAuth = ({ allowedRoles }) => {
    const { setAuth, ...state } = useUserState();
    const location = useLocation();

    const decoded: { _id: string, sectionRoles: [string] } = state?.accessToken
        ? jwt_decode(state?.accessToken)
        : undefined;

    return (
        decoded?.sectionRoles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : state?.accessToken //changed from user to accessToken to persist login after refresh
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/welcome" state={{ from: location }} replace />
    );
}

export default RequireAuth;