import React, { useEffect, useRef, useState } from "react";
import { Descriptions, Modal, QRCode } from "antd";
import { useCurrentVehicule } from "../hooks/useCurrentVehicule";
import moment from "moment";

const FleetViewModal = ({ isOpen, onRequestClose }) => {
  const { currentVehicule } = useCurrentVehicule();

  const [mapInitialized, setMapInitialized] = useState(false);
  const mapContainerRef = useRef(null);

  const handleCancel = () => {
    onRequestClose(false);
  };

  useEffect(() => {
    if (isOpen && !mapInitialized) {
      // Define a function to initialize the Google Map and marker
      const initializeMap = () => {
        const mapOptions = {
          center: {
            lat: currentVehicule?.location?.coordinates.latitude,
            lng: currentVehicule?.location?.coordinates.longitude,
          },
          zoom: 12,
        };

        const mapInstance = new window.google.maps.Map(mapContainerRef.current, mapOptions);

        // Create a marker for the vehicle's location
        const markerOptions = {
          position: {
            lat: currentVehicule?.location?.coordinates.latitude,
            lng: currentVehicule?.location?.coordinates.latitude,
          },
          map: mapInstance,
          title: "Vehicule Location",
        };

        const vehicleMarker = new window.google.maps.Marker(markerOptions);

        setMapInitialized(true);
      };

      // Check if the Google Maps API is available
      if (window.google) {
        initializeMap();
      } else {
        // Load the Google Maps API dynamically
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.addEventListener("load", initializeMap);
        document.body.appendChild(script);
      }
    }
  }, [isOpen, currentVehicule, mapInitialized]);

  return (
    <Modal width={800} bodyStyle={{ height: 700 }} open={isOpen} onCancel={handleCancel} footer={null}>
      <Descriptions title={`Fleet details #${currentVehicule?.logicalId}`} bordered style={{borderRadius: "8px 8px 0px 0px"}}>
        <Descriptions.Item span={3} label="Mileage">
          {(currentVehicule?.mileage * 10) / 1000} Km
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Power Type">
          {currentVehicule?.powerType}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Operated On">
          {currentVehicule?.operatedDate
            ? moment(Number(currentVehicule.operatedDate)).format("DD-MM-YYYY")
            : ""}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="qrCode">
          <QRCode value={currentVehicule?.qrcode} />
        </Descriptions.Item>
      </Descriptions>
      <div ref={mapContainerRef} style={{ width: '100%', height: '43%', borderRadius: "0px 0px 8px 8px" }}></div>
    </Modal>
  );
};

export default FleetViewModal;