import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import en from '../src/Views/Welcome/assets/i18n/en.json'
import fr from '../src/Views/Welcome/assets/i18n/fr.json'
i18n.use(initReactI18next).init({
  resources: {
    en_US: { ...en },
    fr_FR: { ...fr },
  },
  lng: "en_US",
});

export default i18n;