import { useMutation } from "@apollo/client";
import { CITRIN_LOGLOUT_USER, LogoutUserResponse } from "../GraphQL";
import { useUserState } from "./useUserState";

const useLogout = () => {
    const { setAuth } = useUserState()
    const [logoutMutation] = useMutation<LogoutUserResponse>(CITRIN_LOGLOUT_USER);

    const logout = async () => {
        setAuth(null)
        await logoutMutation();
    }

    return logout;
}

export default useLogout;