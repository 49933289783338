import { createContext, useContext } from 'react';

export type PageState = {
    loading: boolean;
};
export type PageStateActions = {
    setLoading: (loading: boolean) => void;
};

export const PageStateContext = createContext<[PageState, React.Dispatch<React.SetStateAction<PageState>>]>(null);

export const usePageState = (): PageState & PageStateActions => {
    const [state, setState] = useContext(PageStateContext);
    return {
        ...state,
        setLoading: (loading) => setState((curr) => ({ ...curr, loading })),
    };
};
