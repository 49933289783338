export const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 12 },
};

export const layoutEdit = {
    labelCol: { span: 5 },
    wrapperCol: { span: 18 },
};

/* eslint-disable no-template-curly-in-string */
export const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
/* eslint-enable no-template-curly-in-string */

export const handleFormChange = (form): boolean => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    return hasErrors
}

export const maleProfileImg: string = "https://profile-images.xing.com/static/nobody_m.96x96.jpg";
export const femaleProfileImg: string = "https://profile-images.xing.com/static/nobody_f.96x96.jpg";



export const setCookie = (cname, cvalue) => {
    document.cookie = `${cname}=${cvalue};`;
};

export const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

export const cleanAllCookies = () => {
    var Cookies = document.cookie.split(';');          
    // set 1 Jan, 1970 expiry for every cookies          
    for (var i = 0; i < Cookies.length; i++)         
    document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
}

export const couponGenerator = () => {
    var coupon = "";
    var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 7; i++) {
        coupon += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return coupon.toUpperCase();
}

export const stripTypenames = (value) => {
    if (Array.isArray(value)) {
        return value.map(stripTypenames)
    } else if (value !== null && typeof(value) === "object") {
      const newObject = {}
      for (const property in value) {
          if (property !== '__typename') {
            newObject[property] = stripTypenames(value[property])
          }
      }
      return newObject
    } else {
      return value
    }
}
