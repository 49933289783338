import { Button, Col, Row, Spin } from 'antd';
import React, { useEffect, useRef } from "react";
import Title from "antd/es/typography/Title";
import CountersHeader from './Components/CountersHeader';
import RevenueBarChart from './Components/RevenueBarChart';
import TripsLineChart from './Components/TripsLineChart';
import CO2LineChart from './Components/CO2LineChart';
import { useSortedReportsTripsQuery } from './Components/hooks/useSortedReportsTripsQuery';
import { DownloadOutlined } from '@ant-design/icons';
import { ReportsFilter } from './Components/ReportsFilter';
import { useSortedReportsCO2Query } from './Components/hooks/useSortedReportsCO2Query';
import { useSortedReportsCountersQuery } from './Components/hooks/useSortedReportsCountersQuery';
import { useOverviewLinkState } from "./Components/linkstate/ReportsOverview.linkstate";
import { useGetSettingQuery } from '../../GraphQL/hooks/useGetSettingQuery';
import jwt_decode from "jwt-decode"
import { useUserState } from '../../Hooks/useUserState';


const ReportsView = () => {
    const { countersData, refetchCounters, loadingCounters } = useSortedReportsCountersQuery();
    const { tripsData, refetchTrips, loadingTrips } = useSortedReportsTripsQuery();
    const { CO2Data, refetchCO2, loadingCO2 } = useSortedReportsCO2Query();
    const [{ selectedDateRange, quickCalendarFilter }] = useOverviewLinkState();
    const reportsContainerRef = useRef<HTMLDivElement>(null); // Add type declaration
    const { ...state } = useUserState();

    const decoded: { _id: string, sectionRoles: [string] } = state?.accessToken
        ? jwt_decode(state?.accessToken)
        : undefined;
    const { data, refetch, loading } = useGetSettingQuery(decoded?._id);

    useEffect(() => {
        refetchCO2();
        refetchCounters();
        refetchTrips();
    }, [])

    // export pdf 
    const handleExportToPDF = async () => {
        window.print();
    };

    return (
        <>
            <div className='divToShow'>
                <Row style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <Col>
                        <img style={{ width: '350px', height: 'auto' }} src={"./assets/stasia_logo.png"} />
                    </Col>
                    <Col offset={16} span={8} style={{ textAlign: 'left' }} >

                        {data?.operator?.name ?
                            <>
                                <b>Operator:</b> {data?.operator?.name}<br />
                                <b>Address:</b> {data?.operator?.address?.street} <br />
                                <b>Email:</b> {data?.operator?.email} <br />
                                <b>Tel:</b> {data?.operator?.phone} <br />
                                <b>MF. :</b> {data?.operator?.tva}
                            </>
                            :
                            <>
                                <b>STASIA Digital sarl.</b><br />
                                <b>Address:</b> Rue Salah Ben Youssef, Ras Jebel TN<br />
                                <b>Email:</b> contact@stasia-digital.com <br />
                                <b>Tel:</b> +216 25 067 347 <br />
                                <b>MF. :</b> 8754543/H
                            </>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'center', paddingTop: '80px' }}>
                        <Title level={2}>Report & Analyses</Title>                       
                        <span>{`${new Date().toLocaleString()}`}</span>

                    </Col>
                </Row>
            </div>

            <div className='divToHide'>
                <Row style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                    <Col span={12}><Title level={3}>Report & Analyses</Title></Col>
                </Row>

                <Row justify={'end'}>
                    <Button type="primary" shape="round" icon={<DownloadOutlined />} size={'middle'} onClick={handleExportToPDF}>Export</Button>
                </Row>

                <Row gutter={[16, 6]} style={{ paddingBottom: '10px', paddingTop: '20px' }}>
                    <ReportsFilter />
                </Row>
            </div>

            <div style={{ flex: '1 1 auto' }}>
                <Spin spinning={loadingCounters}>
                    <CountersHeader data={countersData} />
                </Spin>
            </div>

            <Row >
                <Col span={14}>
                    <h2>
                        Trips / {selectedDateRange?.fromDate === undefined && quickCalendarFilter === 'Default' ? "past 6 months" : quickCalendarFilter}
                        {selectedDateRange?.fromDate !== undefined ? `${selectedDateRange?.fromDate} to ${selectedDateRange?.toDate}` : ''}
                    </h2>
                    <Spin spinning={loadingTrips}>
                        <TripsLineChart data={tripsData} />
                    </Spin>
                </Col>
            </Row>

            <Row style={{ pageBreakBefore: 'always', paddingTop: '40px' }} >
                <Col span={14}>
                    <h2>
                        Revenue / {selectedDateRange?.fromDate === undefined && quickCalendarFilter === 'Default' ? "past 6 months" : quickCalendarFilter}
                        {selectedDateRange?.fromDate !== undefined ? `${selectedDateRange?.fromDate} to ${selectedDateRange?.toDate}` : ''}
                    </h2>
                    <RevenueBarChart />
                </Col>
            </Row>

            <Row style={{ paddingTop: '50px' }}>
                <Col span={14}>
                    <h2>
                        Saved Emissions CO2(g) / {selectedDateRange?.fromDate === undefined && quickCalendarFilter === 'Default' ? "past 12 months" : quickCalendarFilter}
                        {selectedDateRange?.fromDate !== undefined ? `${selectedDateRange?.fromDate} to ${selectedDateRange?.toDate}` : ''}
                    </h2>
                    <Spin spinning={loadingCO2}>
                        <CO2LineChart data={CO2Data} />
                    </Spin>
                    <h4>Estimation factors:</h4>
                    <h5 style={{ marginTop: "-17px" }}>
                        <p>
                            The average CO2 per km for ICEVs: 115 g CO2/km (<a href='https://theicct.org/wp-content/uploads/2022/08/co2-new-passenger-cars-europe-aug22.pdf' >WLTP 2021</a>)<br />
                            The average CO2 per km for EVs: 35 - 67 g CO2/km (<a href='https://www.cenex.co.uk/app/uploads/2020/08/Maximising-the-benefits-of-e-scooter-deployment-in-cities.pdf' >Cenex 2020</a>)
                        </p>
                    </h5>
                </Col>
            </Row>
        </>
    )
};

export default ReportsView;

