import { useQuery } from '@apollo/client';
import {  CITRIN_GET_OPERATORS, GetOperatorsRequest, GetOperatorsResponse } from '..';

export const useGetAllOperatorsQuery = () => {
    const { data, previousData, ...rest } = useQuery<GetOperatorsResponse, GetOperatorsRequest>(CITRIN_GET_OPERATORS
        // fetchPolicy: 'cache-and-network',
        // onCompleted: (data) => console.log('[MGT] Pass', data),
    );

    return { ...rest, previousData: previousData?.CITRIN_MGT_getOperators, data: data?.CITRIN_MGT_getOperators ?? [] };
};