import { Coupon } from "../../../shared/types";
import React, { createContext, useContext, useState } from "react";

type ContextType<S> = [S, React.Dispatch<React.SetStateAction<S>>] | null;

const initialState = null;
const CouponInfoContext = createContext<ContextType<Coupon>>(null);
CouponInfoContext.displayName = "CouponInfoContext";

function useCurrentCoupon() {
    const context = useContext(CouponInfoContext);
    if (!context) {
        throw new Error(`useCurrentCoupon must be used within a CouponInfoProvider`);
    }

    const [currentCoupon, setCurrentCoupon] = context;

    return {
        currentCoupon,
        setCurrentCoupon
    };
}

function CouponInfoProvider(props) {
    const Coupon = useState(initialState);
    return <CouponInfoContext.Provider value={Coupon} {...props} />;
}

export { CouponInfoProvider, useCurrentCoupon };
