import { useEffect, useMemo } from "react";
import { Operator, OperatorQueryFilter } from "../../../shared/types";
import { chunk, isEmpty } from 'lodash';
import { usePageState } from "../../../Hooks/usePageState";
import { useOverviewLinkState } from "../linkstate/OperatorOverview.linkstate";
import { useGetOperatorsQuery } from "../../../GraphQL/hooks/useGetOperatorsQuery";

const pageSize = 10;
const getPageCount = (operators: Operator[]) => Math.ceil(operators?.length / pageSize);
const getCurrentPage = (requestedPage: number, operators: Operator[]) => {
    const pageCount = getPageCount(operators);
    return requestedPage < 1 ? 1 : requestedPage > pageCount ? pageCount : requestedPage;
};

export const useSortedOperatorQuery = () => {
    const { setLoading } = usePageState();
    const [state, setState] = useOverviewLinkState();
    const { page, search, selectedCities, selectedTypes } = state;

    const request = useMemo(
        () =>
        ({
            search: search ?? '',
            cities: isEmpty(selectedCities) ? undefined : selectedCities,
            types: isEmpty(selectedTypes) ? undefined : selectedTypes,
        } as OperatorQueryFilter),
        [search, selectedCities, selectedTypes]
    );
    const { data: unsortedOperators, refetch, loading } = useGetOperatorsQuery(request);
    useEffect(() => setLoading(loading), [loading]);

    const setPage = (newPage: number) => {
        const pageCount = getPageCount(unsortedOperators);
        // console.log('new page', newPage, pageCount);
        const page = newPage < 1 ? 1 : newPage > pageCount ? pageCount : newPage;
        setState({ ...state, page });
    };

    const { pageCount, totalCount, sortedOperators } = useMemo(() => {
        const totalCount = unsortedOperators?.length ?? 0;
        const pageCount = getPageCount(unsortedOperators);
        const sortedOperators = chunk(unsortedOperators, pageSize);

        return { totalCount, pageCount, sortedOperators };
    }, [unsortedOperators]);

    const currentPage = getCurrentPage(page, unsortedOperators);

    return {
        sortedOperators: sortedOperators?.length > 0 ? sortedOperators[currentPage - 1] : [],
        refetch: () => refetch({ request }),
        page: page ?? 1,
        pageCount,
        totalCount,
        pageSize,
        setPage,
        loading
    };
};
