import React from "react";
import SettingTable from "./SettingsTable";

const RolesManagement = () => {

    return (
        <>
            <SettingTable />
        </>
    );

};

export default RolesManagement;

