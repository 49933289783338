import { Col, Row } from 'antd';
import React from "react";
import Title from "antd/es/typography/Title";
import FleetTable from './Components/FleetTable';
import { VehiculeInfoProvider } from './hooks/useCurrentVehicule';

const FleetView = () => {

      return (
          <>
              <Row style={{paddingBottom: 10, paddingTop: 10}}>
                  <Col span={12}><Title level={3}>Fleet</Title></Col>
              </Row>
  
              <div style={{ flex: '1 1 auto' }}>
                <VehiculeInfoProvider>
                    <FleetTable/>
                </VehiculeInfoProvider>
              </div>
          </>
      )
  };
  


export default FleetView;