import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <div className="flexGrow">
            <Result
                status="403"
                title="Unauthorized 403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={<Button type="primary" onClick={goBack}>Go Back</Button>}
            />
        </div>
    )
}

export default Unauthorized