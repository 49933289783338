import React, { useState } from "react";
import LoginForm from './Components/LoginForm';
import ForgetPasswordView from "./Components/ForgetPasswordView";

const LoginView = () => {
    const [isLoginForm] = useState<boolean>(true);

    return (
        <>
            {isLoginForm ?
                <LoginForm />
                :
                <ForgetPasswordView />
            }
        </>
    )
};

export default LoginView;