import React from "react";
import styled from "styled-components";

export default function AssitantBox({ title, imgSrc, action }) {
  return (
    <WrapperBtn className="animate pointer" onClick={action ? () => action() : null}>
      <Wrapper className="whiteBg radius8 shadow">
        <StyledImg src={imgSrc} alt={title}></StyledImg>
        <div className="content">
          <h3 className="font16 semiBold">{title}</h3>
        </div>
      </Wrapper>
    </WrapperBtn>
  );
}

const Wrapper = styled.div`
  width: 250px; 
  height: 200px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  margin-top: 70px;
`;
const WrapperBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  :hover {
    opacity: 0.5;
  }
`;
const StyledImg = styled.img`
  width: 100px; 
  height: 100px;
  margin-bottom: 10px; 
`; 
