import React from "react";
import { Helmet } from "react-helmet";
import Landing from "./Landing.jsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';

import { useTranslation } from "react-i18next";

export default function WelcomeWiew() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('pageTabtitle')}</title>
        <meta charSet="utf-8" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Landing />
    </>
  );
}

