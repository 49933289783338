import { gql, useQuery } from '@apollo/client';

const gqlQueryFilter = gql`
    query overviewPricingState {
        CITRIN_MGT_overviewPricingState @client
    }
`;
const typeName = 'CITRIN_MGT_overviewPricingState';


export type OverviewState = {
    page?: number;
};

const initialState: OverviewState = {
    page: 1,
};

export const useOverviewLinkState = (): [OverviewState, (newState: OverviewState) => void] => {
    const { data, client } = useQuery(gqlQueryFilter, { fetchPolicy: 'cache-first' });
    const setState = (newState: OverviewState) => {
        client.writeQuery({
            query: gqlQueryFilter,
            data: {
                CITRIN_MGT_overviewPricingState: {
                    value: newState,
                    __typename: typeName,
                },
            },
        });
    };
    return [data?.CITRIN_MGT_overviewPricingState?.value ?? initialState, setState];
};

export default {
    defaults: [
        {
            query: gqlQueryFilter,
            data: {
                CITRIN_MGT_overviewPricingState: {
                    value: initialState,
                    __typename: 'CITRIN_MGT_overviewPricingState',
                },
            },
        },
    ],
};