import React from "react";
import { Descriptions, Image } from 'antd';
import { useCurrentRider } from "../hooks/useCurrentRider";
import moment from "moment";

const DescriptionDetails = () => {
  const { currentRider } = useCurrentRider();

  return (
    <Descriptions title={`Rider details #${currentRider?.logicalId}`} bordered>
      <Descriptions.Item span={3} label="Gender">{currentRider?.gender}</Descriptions.Item>
      <Descriptions.Item span={3} label="Phone number">{currentRider?.phoneNumber}</Descriptions.Item>
      <Descriptions.Item span={3} label="Birthday">{currentRider?.birthday ? moment(Number(currentRider.birthday)).format("DD-MM-YYYY") : ''}</Descriptions.Item>
      <Descriptions.Item span={3} label="RiderSelfie"><Image width={150} src={currentRider?.riderSelfie?.url} /></Descriptions.Item>
      <Descriptions.Item span={3} label="IdentityDoc"><Image width={150} src={currentRider?.idCardPhoto?.url} /></Descriptions.Item>
    </Descriptions>
  );
}

export default DescriptionDetails;
