import { useMemo } from "react";
import { useOverviewLinkState } from "../linkstate/ReportsOverview.linkstate";
import { ReportsQueryFilter } from "../../../../shared/types";
import { useGetReportsCountersQuery } from "../../../../GraphQL/hooks/useGetReportsCountersQuery";
import { useUserState } from "../../../../Hooks/useUserState";


export const useSortedReportsCountersQuery = () => {
    const [state] = useOverviewLinkState();
    const { quickCalendarFilter, selectedDateRange } = state;
    const { ...userState } = useUserState();

    const request = useMemo(
        () =>
        ({
            quicklyCalanderSlot: quickCalendarFilter ?? '',
            dateRange: selectedDateRange,
            operatorId: userState.operatorId,
        } as ReportsQueryFilter),
        [quickCalendarFilter, selectedDateRange]
    );

    const { data: countersData, refetch, loading: loadingCounters } = useGetReportsCountersQuery(request);

    return {
        countersData,
        refetchCounters: () => refetch({ request }),
        loadingCounters,
    };
};
