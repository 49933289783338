import { useUserState } from "./useUserState";
import { useMutation } from "@apollo/client";
import { CITRIN_REFRESH_TOKEN, RefreshTokenResponse } from "../GraphQL";
 
const useRefreshToken = () => {
    const { setAuth } = useUserState();
    const [refreshTokenMutation] = useMutation<RefreshTokenResponse>(CITRIN_REFRESH_TOKEN);

    const refresh = async () => {
       const {data} = await refreshTokenMutation();
       setAuth(data?.CITRIN_MGT_refreshToken?.accessToken);
       return data?.CITRIN_MGT_refreshToken?.accessToken;
    }
    
    return refresh;
}

export default useRefreshToken;