import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { BackTop } from 'antd';
import FacebookImg from "../../assets/img/facebook.png";
import InstagramImg from "../../assets/img/instagram.png";
import LinkedinImg from "../../assets/img/linkedin.png";
import LogoImg from "../../assets/img/CITRIN-Logo.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

export default function Contact() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }
  
  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <LinksWrapper>
              <a className="whiteColor animate pointer font13"  onClick={() => navigate("/privacy-policy")} smooth={true} offset={-80}>
              {t('privacyPolicy')}
              </a>
              <a className="whiteColor animate pointer font13" href="general-terms" smooth={true} offset={-80}>
              {t('termsUse')}
              </a>
              <a className="whiteColor animate pointer font13" href="imprint" smooth={true} offset={-80}>
              {t('imprint')}
              </a>
              <a className="whiteColor animate pointer font13" href="help-center" smooth={true} offset={-80}>
              {t('helpCenter')}
              </a>
            </LinksWrapper>
            <LinksWrapper>
              <h2 className="whiteColor animate pointer font14"> {t('contactUs')}</h2>
              <a className="whiteColor animate pointer font13"  href="mailto: support@citrin-mobility.com">
              support@citrin-mobility.com
              </a>

              <ButtonContainer>
                <a className="pointer flexNullCenter" href="https://www.facebook.com/people/CITRIN/61556454680367/?mibextid=ZbWKwL" smooth={true}>
                  <img src={FacebookImg} alt="facebook-Citrin" width="30" height="30" />
                </a>
                <a className="pointer flexNullCenter" href="https://www.instagram.com/citrin.mobility/" smooth={true}>
                  <img src={InstagramImg} alt="instagram-Citrin" width="30" height="30" />
                </a>
                <a className="pointer flexNullCenter" href="home" smooth={true}>
                  <img src={LinkedinImg} alt="linkdin-Citrin" width="30" height="30" />
                </a>
              </ButtonContainer>
            </LinksWrapper>
            <div style={{ margin: '20px' }} className="flexNullCenter">
              <Link className="pointer flexNullCenter" to="home" smooth={true} >
                <img src={LogoImg} alt="citrin" width="60" height="60" />
              </Link>
              <br/>
              <StyleP className="whiteColor font13" style={{ margin: '20px' }} >
              Copyright © {getCurrentYear()} - {t('allRightsReserved')}
                <br/>
                <a href="https://www.stasia-digital.com/" className="whiteColor font13 semiBold" >STASIA Digital</a>{t('citrinProduct')}<br/>
              </StyleP>
              <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              <BackTop />
              </Link>
            </div>
          </InnerWrapper>
          <WhatsAppWidget
            companyName="CITRIN Support"
            replyTimeText={t('replyTimeTextWhatsapp')}
            message={t('messageWhatsapp')}
            sendButtonText={t('sendButtonTextWhatsapp')}
            inputPlaceHolder={t('inputPlaceHolderWhatsapp')}
            phoneNumber={process.env.REACT_APP_CITRIN_WHATSAPP_NUM} />
        </div>
      </div>
    </Wrapper>
  );
}


const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const LinksWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust the gap between the links */
  @media (max-width: 560px) {
    text-align: center;
    padding-top: 20px;
  }
`;
const StyleP = styled.p`
  @media (max-width: 560px) {
    margin: 20px 0;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 15px; 
  @media (max-width: 350px) {
    justify-content: center;
  }
`;
