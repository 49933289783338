import { Col, Row } from 'antd';
import React, { useState } from "react";
import Title from "antd/es/typography/Title";
import MapContent from './Components/MapContent';
import { MapData } from './Types/types';
import { MapDataContext } from './hooks/useMapData';

const MapView = () => {
  const [mapData, set] = useState<MapData>();

  return (
    <>
      <Row style={{ paddingBottom: 10, paddingTop: 20 }}>
        <Col span={12}><Title level={3}>Maps</Title></Col>
      </Row>
      <div style={{ flex: '1 1 auto' }}>
        <MapDataContext.Provider value={[mapData, set]}>
          <MapContent />
        </MapDataContext.Provider>
      </div>
    </>
  )
};
export default MapView;
