import { Button, Col, Pagination, Row, Space, Spin, Table } from 'antd';
import React, { useState } from "react";
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Operator } from '../../../shared/types';
import PopConfirm from '../../../shared/Components/PopConfirm';
import { useMutation } from '@apollo/client';
import { CITRIN_DELETE_OPERATOR, DeleteOperatorRequest, DeleteOperatorResponse } from '../../../GraphQL';
import OperatorViewModal from './OperatorViewModal';
import OperatorCreateModal from './OperatorCreateModal';
import OperatorEditModal from './OperatorEditModal';
import { OperatorFilter } from './OperatorFilter';
import { useCurrentOperator } from '../hooks/useCurrentOperator';
import { useSortedOperatorQuery } from '../hooks/useSortedOperatorQuery';

const OperatorTable = () => {
    const [isCreateModalVisible, setCreateModalVisible] = useState(false);
    const [isEditModalVisible, setEditModalVisible] = useState(false);
    const [isViewModalVisible, setViewModalVisible] = useState(false);

    const { sortedOperators: operators, refetch, loading, ...pagination } = useSortedOperatorQuery();
    const [deleteMutation] = useMutation<DeleteOperatorResponse, DeleteOperatorRequest>(CITRIN_DELETE_OPERATOR);
    const { setCurrentOperator } = useCurrentOperator();

    const [isLoading, setIsLoading] = useState(false);

    const handleRemoveOperator = (operator: Operator) => {
        setIsLoading(true);
        deleteMutation({ variables: { id: operator._id } })
            .then(() => {
                refetch();
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCreateOperator = () => { setCreateModalVisible(true); };

    const handleViewOperator = (operator) => {
        setCurrentOperator(operator);
        setViewModalVisible(true);
    };

    const handleEditOperator = (operator: Operator) => {
        setCurrentOperator(operator);
        setEditModalVisible(true);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'logicalId',
            key: 'logicalId',
            sorter: (a, b) => a.logicalId - b.logicalId,
            width: 80,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Website',
            dataIndex: 'website',
            key: 'website',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: 150,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 100,
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            render: (text, record: Operator) => { return record?.address?.city },
            width: 80,
        },
        {
            title: "Action",
            key: "action",
            render: (_, Operator) =>
                <Space size="middle">
                    <a href="#" onClick={() => handleViewOperator(Operator)} ><EyeOutlined /></a>
                    <a href="#" onClick={() => handleEditOperator(Operator)} ><EditOutlined style={{ fontSize: '16px', color: 'green' }} /></a>
                    <PopConfirm
                        title={"Delete the Operator"}
                        description={"Are you sure to delete this Operator?"}
                        onConfirmTask={() => handleRemoveOperator(Operator)}
                        popIcon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        actionIcon={<DeleteOutlined style={{ fontSize: '16px', color: 'red' }} />}
                    />
                </Space>
        },

    ];

    const Paginator = () => (
        <Row justify={'center'}>
            <Col>
                <Pagination
                    size="default"
                    total={pagination.totalCount}
                    pageSize={pagination.pageSize}
                    current={pagination.page}
                    showTotal={(total) => `Total Operators ${total}`}
                    onChange={(newPage) => pagination.setPage(newPage)}
                    showQuickJumper={true}
                    showSizeChanger={false}
                    showLessItems={false}
                />
            </Col>
        </Row>
    );

    return (
        <>
            {isViewModalVisible && <OperatorViewModal isOpen={isViewModalVisible} onRequestClose={setViewModalVisible} />}

            {isCreateModalVisible && <OperatorCreateModal updateList={refetch} isOpen={isCreateModalVisible} onRequestClose={setCreateModalVisible} />}

            {isEditModalVisible && <OperatorEditModal updateList={refetch} isOpen={isEditModalVisible} onRequestClose={setEditModalVisible} />}

            <Row justify={'end'}>
                <Button type="primary"
                    shape="round"
                    icon={<PlusCircleOutlined />}
                    size={'middle'}
                    onClick={handleCreateOperator}>Add</Button>
            </Row>

            <Row gutter={[16, 6]} style={{ paddingBottom: 10, paddingTop: 20 }}>
                <OperatorFilter />
            </Row>
            <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Col xs={24}>
                    <Spin spinning={isLoading || loading}>
                        <Table
                            dataSource={operators}
                            columns={columns}
                            pagination={false}
                            rowKey={() => Math.random().toString()}
                            scroll={{ x: 1500, y: 400 }}>
                        </Table>
                    </Spin>
                </Col>
            </Row>
            <Paginator />
        </>
    )
};

export default OperatorTable;
