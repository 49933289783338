import { useEffect, useMemo } from "react";
import { Pass } from "../../../shared/types";
import { chunk } from 'lodash';
import { usePageState } from "../../../Hooks/usePageState";
import { useOverviewLinkState } from "../linkstate/passOverview.linkstate";
import { useGetPassesQuery } from "../../../GraphQL/hooks/useGetPassesQuery";

const pageSize = 10;
const getPageCount = (passes: Pass[]) => Math.ceil(passes?.length / pageSize);
const getCurrentPage = (requestedPage: number, passes: Pass[]) => {
    const pageCount = getPageCount(passes);
    return requestedPage < 1 ? 1 : requestedPage > pageCount ? pageCount : requestedPage;
};

export const useSortedPassQuery = () => {
    const { setLoading } = usePageState();
    const [state, setState] = useOverviewLinkState();
    const { page } = state;

    const { data: unsortedPasses, refetch, loading } = useGetPassesQuery();
    useEffect(() => setLoading(loading), [loading]);

    const setPage = (newPage: number) => {
        const pageCount = getPageCount(unsortedPasses);
        // console.log('new page', newPage, pageCount);
        const page = newPage < 1 ? 1 : newPage > pageCount ? pageCount : newPage;
        setState({ ...state, page });
    };

    const { pageCount, totalCount, sortedPasses } = useMemo(() => {
        const totalCount = unsortedPasses?.length ?? 0;
        const pageCount = getPageCount(unsortedPasses);
        const sortedPasses = chunk(unsortedPasses, pageSize);

        return { totalCount, pageCount, sortedPasses };
    }, [unsortedPasses]);

    const currentPage = getCurrentPage(page, unsortedPasses);

    return {
        sortedPasses: sortedPasses?.length > 0 ? sortedPasses[currentPage - 1] : [],
        refetch: () => refetch(),
        page: page ?? 1,
        pageCount,
        totalCount,
        pageSize,
        setPage,
        loading
    };
};
