import React from "react";
import styled from "styled-components";
import TopNavbar from "../../Nav/TopNavbar";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";  

export default function ImprintView() {

  const { t } = useTranslation();

  return (
    <>
      <TopNavbar />
      <Wrapper id="imprint">
        <Container>
          <Content>
            <div className="whiteBg" style={{ padding: "60px 0" }}>
              <Section className="row flexCenter">
                <Title className="extraBold font60">{t('titleImprint')}</Title>
              </Section>
              <Text>
                <h2>
                  <b>
                    <u>{t('address')}</u>
                  </b>
                </h2>
                <br />
                <p className="font18">STASIA Digital sarl</p>
                <p className="font18">Rue Salah Ben Yousef</p>
                <p className="font18">7070 Ras Jebel</p>
                <p className="font18">Tunisia</p>
                <br />
                <h2>
                  <b>
                    <u>{t('contactImprint')}</u>
                  </b>
                </h2>
                <br />
                <p className="font18">
                  <b>Contact:</b> 
                  <a href="mailto:contact@stasia-digital.com"> contact@stasia-digital.com</a>
                    <br />
                  <b>Support:</b> 
                    <a href="mailto:support@citrin-mobility.com"> support@citrin-mobility.com</a>
                </p>
                <p className="font18">
                  <b>Tel:</b> +216 92 496 876
                  </p>
              </Text>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Container = styled.div`
  padding: 60px 0;
  background-color: #fff;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const Text = styled.div`
  padding: 15px 0 50px 0;
  line-height: 1.7rem;
  @media (max-width: 970px) {
    padding: 15px 0 50px 0;
    max-width: 100%;
  }
`;

const Title = styled.h1`
  margin-top: 23px;
`;
