import React from "react";
import TopNavbar from "./components/Nav/TopNavbar";
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { useTranslation } from "react-i18next";

export default function LiveChatView() {
  const { t } = useTranslation();

  return (
    <>
      <TopNavbar />
      <WhatsAppWidget
            companyName="CITRIN Support"
            open={true}
            replyTimeText={t('replyTimeTextWhatsapp')}
            message={t('messageWhatsapp')}
            sendButtonText={t('sendButtonTextWhatsapp')}
            inputPlaceHolder={t('inputPlaceHolderWhatsapp')}
            phoneNumber={process.env.REACT_APP_CITRIN_WHATSAPP_NUM} />
    </>
  );
}