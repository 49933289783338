import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import PartnerLogo01 from "../../assets/img/Wallys-logo-Black.png";
import PartnerLogo02 from "../../assets/img/black-pixii-logo.png";
import PartnerLogo03 from "../../assets/img/smart-for-green.png";
import PartnerLogo04 from "../../assets/img/innovation-centre-rj.png";
import PartnerLogo05 from "../../assets/img/giz-logo.png";
import PartnerLogo06 from "../../assets/img/EU-logo.png";
import PartnerLogo08 from "../../assets/img/total-energies-startupper.png";
import PartnerLogo09 from "../../assets/img/GSA_NorthernAfricaStartupAwards_Logo.png";


export default function Partners() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div id="partners">

      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <a href="https://smartforgreen.com" target="_blank">
            <img src={PartnerLogo03} alt="Smart for Green logo" />
          </a>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <a href="https://www.facebook.com/InnovationHubRasJebel" target="_blank">
            <img src={PartnerLogo04} alt="Innovation centre - Ras Jebel logo" />
          </a>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <a href="https://startupper.totalenergies.com/" target="_blank">
            <img src={PartnerLogo08} alt="Total Energies Startupper logo" />
          </a>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <a href="https://northern.africanstartupawards.com/" target="_blank">
            <img src={PartnerLogo09} alt="GSA_NorthernAfricaStartupAwards_Logo.png" />
          </a>
        </LogoWrapper>
        
    {/* <LogoWrapper className="flexCenter">
          <a href="https://www.giz.de" target="_blank">
            <img src={PartnerLogo05} alt="giz logo" />
          </a>
        </LogoWrapper> 
        <LogoWrapper className="flexCenter">
          <a href="https://commission.europa.eu/energy-climate-change-environment_en" target="_blank">
            <img src={PartnerLogo06} alt="EU logo" />
          </a>
        </LogoWrapper> */}
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  padding: 0 50px;

  :focus-visible {
    outline: none;
    border: 0px;
  }
  @media (max-width: 460px) {
    padding: 16px 0;
  }
`;
