import { useQuery } from '@apollo/client';
import { CITRIN_GET_CAMPAIGNS, GetCampaignsRequest, GetCampaignsResponse } from '..';

export const useGetCampaignsQuery = () => {
    const { data, previousData, ...rest } = useQuery<GetCampaignsResponse, GetCampaignsRequest>(CITRIN_GET_CAMPAIGNS
        // fetchPolicy: 'cache-and-network',
        // onCompleted: (data) => console.log('[MGT] Pass', data),
    );

    return { ...rest, previousData: previousData?.CITRIN_MGT_getCampaigns, data: data?.CITRIN_MGT_getCampaigns ?? [] };
};