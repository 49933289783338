import React from "react";
import styled from "styled-components";
// Components

import NetZeroImg from "../../assets/img/net-zero.png";
import DockImg from "../../assets/img/dockStation.png";

import { useTranslation } from "react-i18next";  

export default function Sustainablity() {

  const { t } = useTranslation();

  return (
    <Wrapper id="sustainablity">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{t('titleSustainablity')}</h1>
            <Text>
              <p className="font18">
              {t('textSustainablity1')}
              <br />
              {t('textSustainablity2')}
              <br />
              {t('textSustainablity3')}
            </p>
            </Text>
          </HeaderInfo>
          
          <div className="row flexCenter">
          <ImgWrapper1 className="flexCenter">
              <img src={NetZeroImg} alt="netZero" className="radius6" />
            </ImgWrapper1>
          </div>
        </div>
      </div>

      <div className="lightBg">
        <div className="container">
          <Inovation className="flexSpaceCenter">
            <AddLeft>
              <h2 className="font40">  {t('titleSolarEnergy')}</h2>
              <h3 className="semiBold"> {t('titleInnovationenergySupply')}</h3>
              <p className="font18">
              {t('textInnovationenergySupply')} 
              </p>
              <br/>
              <h3 className="semiBold">  {t('titleSharedResponsibility')}</h3>
              <p className="font18">
              {t('textSharedResponsibility')}             
              </p>
            </AddLeft>
            <AddRight>
              <ImgWrapper2 className="flexCenter">
                <img className="radius8" src={DockImg} alt="add" />
              </ImgWrapper2>
            </AddRight>
          </Inovation>
        </div>
      </div>

    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 45px;
  @media (max-width: 970px) {
    text-align: center;

  }
`;

const Inovation = styled.div`
  padding: 100px 0;
  margin: 50px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 40%;
  @media (max-width: 900px) {
    width: 80%;
    order: 5;
  }
`;

const Text = styled.div`
padding: 15px 0 50px 0;
line-height: 1.7rem;
@media (max-width: 970px) {
  padding: 15px 0 50px 0;
  text-align: center;
  max-width: 100%;
}
`;

const ImgWrapper1 = styled.div`
  width: 80%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 460px) {
    padding: 0;
  }
`;

const ImgWrapper2 = styled.div`
  width: 120%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 460px) {
    width: 100%;
    padding: 20px 0%;
  }
`;
