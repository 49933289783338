import { gql, useQuery } from '@apollo/client';
import { OperatedDateRangeValue, BatteryLevelRangeValue } from '../../../shared/types';

const gqlQueryFilter = gql`
    query overviewFleetState {
        CITRIN_MGT_overviewFleetState @client
    }
`;
const typeName = 'CITRIN_MGT_overviewFleetState';


export type OverviewState = {
    search?: string;
    status?: boolean;
    selectedCities?: string[];
    selectedTypes?: string[];
    batteryLevelRange?: BatteryLevelRangeValue;
    selectedDateRange?: OperatedDateRangeValue;
    page?: number;
};

const initialState: OverviewState = {
    search: '',
    status: true,
    selectedCities: [],
    selectedTypes: [],
    batteryLevelRange: {
        minLevel: null,
        maxLevel: null,
    },
    selectedDateRange: {
        fromDate: undefined,
        toDate: undefined,
    },
    page: 1,
};

export const useOverviewLinkState = (): [OverviewState, (newState: OverviewState) => void] => {
    const { data, client } = useQuery(gqlQueryFilter, { fetchPolicy: 'cache-first' });
    const setState = (newState: OverviewState) => {
        client.writeQuery({
            query: gqlQueryFilter,
            data: {
                CITRIN_MGT_overviewFleetState: {
                    value: newState,
                    __typename: typeName,
                },
            },
        });
    };
    return [data?.CITRIN_MGT_overviewFleetState?.value ?? initialState, setState];
};

export default {
    defaults: [
        {
            query: gqlQueryFilter,
            data: {
                CITRIN_MGT_overviewFleetState: {
                    value: initialState,
                    __typename: 'CITRIN_MGT_overviewFleetState',
                },
            },
        },
    ],
};