import React from "react";
// Sections
import TopNavbar from "./components/Nav/TopNavbar";
import Header from "./components/Sections/Header";
import CitrinVision from "./components/Sections/CitrinVision";
import B2B from "./components/Sections/B2B";
import Sustainablity from "./components/Sections/Sustainablity";
import Partners from "./components/Sections/Partners";
import Contact from "./components/Sections/Contact";
import Footer from "./components/Sections/Footer"
 
export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <CitrinVision />
      <B2B />
      <Sustainablity />
      <Partners />
      <Contact />
      <Footer />
    </>
  );
}