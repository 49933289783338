import { VehiculeProblem } from "../../../shared/types";
import React, { createContext, useContext, useState } from "react";

type ContextType<S> = [S, React.Dispatch<React.SetStateAction<S>>] | null;

const initialState = null;
const VehiculeProblemInfoContext = createContext<ContextType<VehiculeProblem>>(null);
VehiculeProblemInfoContext.displayName = "VehiculeProblemInfoContext";

function useCurrentVehiculeProblem() {
    const context = useContext(VehiculeProblemInfoContext);
    if (!context) {
        throw new Error(`useCurrentVehiculeProblem must be used within a VehiculeProblemInfoProvider`);
    }

    const [currentVehiculeProblem, setCurrentVehiculeProblem] = context;

    return {
        currentVehiculeProblem,
        setCurrentVehiculeProblem
    };
}

function VehiculeProblemInfoProvider(props) {
    const vehiculeProblem = useState(initialState);
    return <VehiculeProblemInfoContext.Provider value={vehiculeProblem} {...props} />;
}

export { VehiculeProblemInfoProvider, useCurrentVehiculeProblem };
