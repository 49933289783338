import { useEffect, useMemo } from "react";
import { Coupon, CouponQueryFilter } from "../../../shared/types";
import { chunk, isEmpty } from 'lodash';
import { usePageState } from "../../../Hooks/usePageState";
import { useOverviewLinkState } from "../linkstate/couponOverview.linkstate";
import { useGetCouponsQuery } from "../../../GraphQL/hooks/useGetCouponsQuery";

const pageSize = 10;
const getPageCount = (coupons: Coupon[]) => Math.ceil(coupons?.length / pageSize);
const getCurrentPage = (requestedPage: number, coupons: Coupon[]) => {
    const pageCount = getPageCount(coupons);
    return requestedPage < 1 ? 1 : requestedPage > pageCount ? pageCount : requestedPage;
};

export const useSortedCouponQuery = () => {
    const { setLoading } = usePageState();
    const [state, setState] = useOverviewLinkState();
    const { page, search, selectedDateRange, status, selectedTypes } = state;

    const request = useMemo(
        () =>
        ({
            search: search ?? '',
            types: isEmpty(selectedTypes) ? undefined : selectedTypes,
            status: status,
            dateRange: selectedDateRange,
        } as CouponQueryFilter),
        [search, status, selectedDateRange, selectedTypes]
    );
    const { data: unsortedCoupons, refetch, loading } = useGetCouponsQuery(request);
    useEffect(() => setLoading(loading), [loading]);

    const setPage = (newPage: number) => {
        const pageCount = getPageCount(unsortedCoupons);
        // console.log('new page', newPage, pageCount);
        const page = newPage < 1 ? 1 : newPage > pageCount ? pageCount : newPage;
        setState({ ...state, page });
    };

    const { pageCount, totalCount, sortedCoupons } = useMemo(() => {
        const totalCount = unsortedCoupons?.length ?? 0;
        const pageCount = getPageCount(unsortedCoupons);
        const sortedCoupons = chunk(unsortedCoupons, pageSize);

        return { totalCount, pageCount, sortedCoupons };
    }, [unsortedCoupons]);

    const currentPage = getCurrentPage(page, unsortedCoupons);

    return {
        sortedCoupons: sortedCoupons?.length > 0 ? sortedCoupons[currentPage - 1] : [],
        refetch: () => refetch({ request }),
        page: page ?? 1,
        pageCount,
        totalCount,
        pageSize,
        setPage,
        loading
    };
};
