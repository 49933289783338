import { Rider } from "../../../shared/types";
import React, { createContext, useContext, useState } from "react";

type ContextType<S> = [S, React.Dispatch<React.SetStateAction<S>>] | null;

const initialState = null;
const RiderInfoContext = createContext<ContextType<Rider>>(null);
RiderInfoContext.displayName = "RiderInfoContext";

function useCurrentRider() {
    const context = useContext(RiderInfoContext);
    if (!context) {
        throw new Error(`useCurrentRider must be used within a RiderInfoProvider`);
    }

    const [currentRider, setCurrentRider] = context;

    return {
        currentRider,
        setCurrentRider
    };
}

function RiderInfoProvider(props) {
    const rider = useState(initialState);
    return <RiderInfoContext.Provider value={rider} {...props} />;
}

export { RiderInfoProvider, useCurrentRider };
