import React, { useState } from "react";
import { Input, Form, Modal, Switch, DatePicker, DatePickerProps, Button } from 'antd';
import { Campaign } from "../../../shared/types";
import { handleFormChange, layoutEdit, validateMessages } from "../../../shared/Utils";
import { useCreateCampaignMutation } from "../../../GraphQL/hooks/useCreateCampaignMutation";
import moment from "moment";

const CampaignCreateModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm();
  const [newCampaign, setNewCampaign] = useState<Campaign>();
  const [status, setStatus] = useState<boolean>(false);
  const [formError, setFormError] = useState(false);

  const mutation = useCreateCampaignMutation();

  const handleSwitchChange = (checked: boolean) => {
    setStatus(checked)
  }
 
  const handleSelectStartingDate = (date: any, dateString: any) => {
    setNewCampaign({ ...newCampaign, startingDate: moment(date).format("DD-MM-YYYY") })
  };

  const handleSelectEndDate = (date: any, dateString: any) => {
    setNewCampaign({ ...newCampaign,  endDate: moment(date).format("DD-MM-YYYY")  })
  };

  const handleCommit = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        mutation({ ...newCampaign, status: status })
          .then(() => {
            onRequestClose(false)
            updateList()
          })
          .finally(() => {
            form.resetFields();
            console.log("New Campaign was successfuly added")
          })
          .catch((err) => console.error(err));
      }
    } catch (errorInfo) {
      console.log('Failed: Form is empty');
    }
  };

  const handleCancel = () => {
    onRequestClose(false);
  };

  return (
    <Modal forceRender title="Add Campaign" open={isOpen} onCancel={handleCancel} footer={[
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="create" type="primary" onClick={handleCommit} disabled={formError}>
        Create
      </Button>,
    ]}>
      <Form<Campaign> {...layoutEdit} form={form} name="nest-messages" autoComplete="off" onFieldsChange={() => { setFormError(handleFormChange(form)) }} onValuesChange={(changedValues, allValues) => setNewCampaign(allValues)} validateMessages={validateMessages}>
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input type="textarea" style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ required: true }]}>
          <Input type="textarea" style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item name="startingDate" label="Starting date" rules={[{ required: true }]}>
          <DatePicker format={"YYYY-MM-DD"} onChange={handleSelectStartingDate} />
        </Form.Item>
        <Form.Item name="endDate" label="End date" rules={[{ required: true }]}>
          <DatePicker format={"YYYY-MM-DD"} onChange={handleSelectEndDate} />
        </Form.Item>
        <Form.Item name="status" label="Status" valuePropName="checked" rules={[{ required: false }]}>
          <Switch checkedChildren="Active"
            unCheckedChildren="Inactive"
            checked={false}
            onClick={(checked: boolean) => handleSwitchChange(checked)} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CampaignCreateModal;