import React from "react";
import styled from "styled-components";
// Components
import B2BBox from "../Elements/B2BBox";
// Assets
import HospitalImg1 from "../../assets/img/hospital.jpg";
import HotelImg2 from "../../assets/img/hotels.png";
import TechnopoleImg3 from "../../assets/img/pole-campus.png";
import FullButton from "../Buttons/FullButton";
import { Link } from "react-scroll";

// Assets
import AddImage2 from "../../assets/img/add/screen-app.png";
import { useTranslation } from "react-i18next";


export default function B2B() {
  const { t } = useTranslation();

  return (
    <Wrapper id="b2b">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold"> {t('titleIntegratingSectors1')} </h1>
            <Text>
              <p className="font18 semiBold">
              {t('titleIntegratingSectors2')} 
            </p>
            </Text>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <B2BBox
                img={HospitalImg1}
                title= {t('titlePublicPrivateSectors')} 
                text= {t('textPublicPrivateSectors')} 
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <B2BBox
                img={HotelImg2}
                title= {t('titleHotelsResorts')} 
                text= {t('textHotelsResorts')} 
              
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <B2BBox
                img={TechnopoleImg3}
                title= {t('titleTechnologyHubsCampuses')} 
                text= {t('textTechnologyHubsCampuses')} 
          
              />
            </div>
           
            </div>



            <div className="row flexCenter">




             
            <Link className="pointer flexNullCenter" to="contact" smooth={true}>
                   <button className=" animate radius8 ButtonInput semiBold">
                    {t('getInTouch')}
                  </button >
             </Link>
            </div>

       
        
          </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
          <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h2 className="font28 extraBold">{t('titleSmarterWay')}</h2>
              <Text>
              <p className="font18">
              {t('textSmarterWay1')} <br/> <br/>{t('textSmarterWay2')} <br/> <br/>{t('textSmarterWay3')}
              </p>
              </Text>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 50px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;


const Text = styled.div`
padding: 15px 0 50px 0;
line-height: 1.6rem;
@media (max-width: 970px) {
  padding: 15px 0 50px 0;
  text-align: center;
  max-width: 100%;
}
`;

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

 