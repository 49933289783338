import { useEffect, useMemo } from "react";
import { Rider, RiderQueryFilter } from "../../../shared/types";
import { chunk, isEmpty } from 'lodash';
import { usePageState } from "../../../Hooks/usePageState";
import { useOverviewLinkState } from "../linkstate/riderOverview.linkstate";
import { useGetRidersQuery } from "../../../GraphQL/hooks/useGetRidersQuery";
import { useUserState } from "../../../Hooks/useUserState";

const pageSize = 10;
const getPageCount = (trips: Rider[]) => Math.ceil(trips?.length / pageSize);
const getCurrentPage = (requestedPage: number, trips: Rider[]) => {
    const pageCount = getPageCount(trips);
    return requestedPage < 1 ? 1 : requestedPage > pageCount ? pageCount : requestedPage;
};

export const useSortedRiderQuery = () => {
    const { setLoading } = usePageState();
    const [state, setState] = useOverviewLinkState();
    const { ...userState } = useUserState();
    const { page, search, selectedDateRange, identityCheck, gender } = state;

    const request = useMemo(
        () =>
        ({
            search: search ?? '',
            gender: gender != 'All' ? gender : '',
            identityCheck: identityCheck,
            dateRange: selectedDateRange,
            operatorId: userState.operatorId,
        } as RiderQueryFilter),
        [search, gender, selectedDateRange, identityCheck]
    );
    const { data: unsortedRiders, refetch, loading } = useGetRidersQuery(request);
    useEffect(() => setLoading(loading), [loading]);

    const setPage = (newPage: number) => {
        const pageCount = getPageCount(unsortedRiders);
        // console.log('new page', newPage, pageCount);
        const page = newPage < 1 ? 1 : newPage > pageCount ? pageCount : newPage;
        setState({ ...state, page });
    };

    const { pageCount, totalCount, sortedRiders } = useMemo(() => {
        const totalCount = unsortedRiders?.length ?? 0;
        const pageCount = getPageCount(unsortedRiders);
        const sortedRiders = chunk(unsortedRiders, pageSize);

        return { totalCount, pageCount, sortedRiders };
    }, [unsortedRiders]);

    const currentPage = getCurrentPage(page, unsortedRiders);

    return {
        sortedRiders: sortedRiders?.length > 0 ? sortedRiders[currentPage - 1] : [],
        refetch: () => refetch({ request }),
        page: page ?? 1,
        pageCount,
        totalCount,
        pageSize,
        setPage,
        loading
    };
};