import { gql, useQuery } from '@apollo/client';
import { Calendar, ReportsDateRangeValue } from '../../../../shared/types';

const gqlQueryFilter = gql`
    query overviewState {
        CITRIN_MGT_overviewReportsState @client
    }
`;
const typeName = 'CITRIN_MGT_overviewReportsState';


export type OverviewState = {
    quickCalendarFilter?: Calendar;
    selectedDateRange?: ReportsDateRangeValue;
};
const initialState: OverviewState = {
    quickCalendarFilter: 'Default',
    selectedDateRange: {
        fromDate: undefined,
        toDate: undefined,
    },
};


export const useOverviewLinkState = (): [OverviewState, (newState: OverviewState) => void] => {
    const { data, client } = useQuery(gqlQueryFilter, { fetchPolicy: 'cache-first' });
    const setState = (newState: OverviewState) => {
        client.writeQuery({
            query: gqlQueryFilter,
            data: {
                CITRIN_MGT_overviewReportsState: {
                    value: newState,
                    __typename: typeName,
                },
            },
        });
    };
    return [data?.CITRIN_MGT_overviewReportsState?.value ?? initialState, setState];
};

export default {
    defaults: [
        {
            query: gqlQueryFilter,
            data: {
                CITRIN_MGT_overviewReportsState: {
                    value: initialState,
                    __typename: 'CITRIN_MGT_overviewReportsState',
                },
            },
        },
    ],
};