import { useEffect, useMemo } from "react";
import { Pricing } from "../../../shared/types";
import { chunk } from 'lodash';
import { usePageState } from "../../../Hooks/usePageState";
import { useOverviewLinkState } from "../linkstate/pricingOverview.linkstate";
import { useGetPricingsQuery } from "../../../GraphQL/hooks/useGetPricingsQuery";

const pageSize = 10;
const getPageCount = (pricings: Pricing[]) => Math.ceil(pricings?.length / pageSize);
const getCurrentPage = (requestedPage: number, pricings: Pricing[]) => {
    const pageCount = getPageCount(pricings);
    return requestedPage < 1 ? 1 : requestedPage > pageCount ? pageCount : requestedPage;
};

export const useSortedPricingQuery = () => {
    const { setLoading } = usePageState();
    const [state, setState] = useOverviewLinkState();
    const { page } = state;

    const { data: unsortedPricings, refetch, loading } = useGetPricingsQuery();
    useEffect(() => setLoading(loading), [loading]);

    const setPage = (newPage: number) => {
        const pageCount = getPageCount(unsortedPricings);
        const page = newPage < 1 ? 1 : newPage > pageCount ? pageCount : newPage;
        setState({ ...state, page });
    };

    const { pageCount, totalCount, sortedPricings } = useMemo(() => {
        const totalCount = unsortedPricings?.length ?? 0;
        const pageCount = getPageCount(unsortedPricings);
        const sortedPricings = chunk(unsortedPricings, pageSize);

        return { totalCount, pageCount, sortedPricings };
    }, [unsortedPricings]);

    const currentPage = getCurrentPage(page, unsortedPricings);

    return {
        sortedPricings: sortedPricings?.length > 0 ? sortedPricings[currentPage - 1] : [],
        refetch: () => refetch(),
        page: page ?? 1,
        pageCount,
        totalCount,
        pageSize,
        setPage,
        loading
    };
};
