import { Button, Col, Pagination, Row, Space, Spin, Table } from 'antd';
import React, { useState } from "react";
import { DeleteOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Pass } from '../../../shared/types';
import { useSortedPassQuery } from '../hooks/useSortedPassQuery';
import PopConfirm from '../../../shared/Components/PopConfirm';
import { useMutation } from '@apollo/client';
import { CITRIN_DELETE_PASS, DeletePassRequest, DeletePassResponse } from '../../../GraphQL';
import PassCreateModal from './PassCreateModal';

const PassTable = () => {
  const [isCreateModalVisible, setCreateModalVisible] = useState(false);

  const { sortedPasses: passes, refetch, loading, ...pagination } = useSortedPassQuery();
  const [deleteMutation] = useMutation<DeletePassResponse, DeletePassRequest>(CITRIN_DELETE_PASS);


  const handleRemovePass = (pass: Pass) => {
    setIsLoading(true);
    deleteMutation({ variables: { id: pass._id } })
      .then(() => {
        refetch();
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCreatePass = () => { setCreateModalVisible(true); };
  const [IsLoading, setIsLoading] = useState(false);

  const columns = [

    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Initial price',
      dataIndex: 'initialPrice',
      key: 'initialPrice',
    },
    {
      title: 'Price unit',
      dataIndex: 'priceUnit',
      key: 'priceUnit',
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'Minute price',
      dataIndex: 'minutePrice',
      key: 'minutePrice',
    },
    {
      title: 'Unlock price',
      dataIndex: 'unlockPrice',
      key: 'unlockPrice',
    },
    {
      title: 'Vehicule type',
      dataIndex: 'vehiculeType',
      key: 'vehiculeType',
    },
    {
      title: "Action",
      key: "action",
      render: (_, pass) =>
        <Space size="middle">
          <PopConfirm
            title={"Delete the pass"}
            description={"Are you sure to delete this pass?"}
            onConfirmTask={() => handleRemovePass(pass)}
            popIcon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            actionIcon={<DeleteOutlined style={{ fontSize: '16px', color: 'red' }} />}
          />
        </Space>
    },

  ];

  const Paginator = () => (
    <Row justify={'center'}>
      <Col>
        <Pagination
          size="default"
          total={pagination.totalCount}
          pageSize={pagination.pageSize}
          current={pagination.page}
          showTotal={(total) => `Total Passes ${total}`}
          onChange={(newPage) => pagination.setPage(newPage)}
          showQuickJumper={true}
          showSizeChanger={false}
          showLessItems={false}
        />
      </Col>
    </Row>
  );

  return (
    <>

      {isCreateModalVisible && <PassCreateModal updateList={refetch} isOpen={isCreateModalVisible} onRequestClose={setCreateModalVisible} />}

      <Row justify={'end'}>
        <Button type="primary"
          shape="round"
          icon={<PlusCircleOutlined />}
          size={'middle'}
          onClick={handleCreatePass}>Add</Button>
      </Row>

      <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
        <Col xs={24}>
          <Spin spinning={ IsLoading || loading}>
            <Table
              dataSource={passes}
              columns={columns}
              pagination={false}
              rowKey={() => Math.random().toString()}
              scroll={{ x: 1500, y: 400 }}>
            </Table>
          </Spin>
        </Col>
      </Row>
      <Paginator />
    </>
  )
};

export default PassTable;