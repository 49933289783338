import { Bar } from '@ant-design/plots';
import React from 'react';

const RevenueBarChart = () => {

  const data = [
    {
      label: 'December',
      type: 'E-Scooter',
      value: 2800,
    },
    {
      label: 'December',
      type: 'E-Car',
      value: 2260,
    },
    {
      label: 'December',
      type: 'E-Moped',
      value: 1800,
    },
    {
      label: 'November',
      type: 'E-Scooter',
      value: 1300,
    },
    {
      label: 'November',
      type: 'E-Car',
      value: 950,
    },
    {
      label: 'October',
      type: 'E-Moped',
      value: 900,
    },
    {
      label: 'October',
      type: 'E-Scooter',
      value: 500,
    },
    {
      label: 'September',
      type: 'E-Bike',
      value: 390,
    },
    {
      label: 'September',
      type: 'E-Cargo',
      value: 170,
    },
    {
      label: 'August ',
      type: 'E-Scooter',
      value: 400,
    },
    {
      label: 'July ',
      type: 'E-Scooter',
      value: 200,
    },
  ];
  const config = {
    data,
    isGroup: true,
    xField: 'value',
    yField: 'label',
    seriesField: 'type',
    dodgePadding: 4,
    label: {

      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };
  return (
    <div>
      <Bar {...config} />
    </div>
  );

};

export default RevenueBarChart;