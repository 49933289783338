import { Col, Row } from 'antd';
import React from "react";
import Title from "antd/es/typography/Title";
import TripTable from './Components/TripTable';
import { TripInfoProvider } from './hooks/useCurrentTrip';

const TripsView = () => {

    return (
        <>
            <Row style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Col span={12}><Title level={3}>Trips</Title></Col>
            </Row>
            <div style={{ flex: '1 1 auto' }}>
                <TripInfoProvider>
                    <TripTable />
                </TripInfoProvider>
            </div>

        </>
    )
};
export default TripsView;

