import { createContext, useContext} from 'react';

export type UserState = {
    accessToken?: string,
    refreshToken?: string,
    operatorId?: string
};
export type UserStateActions = {
    setAuth: (accessToken: string, operatorId?: string) => void;
};

export const UserStateContext = createContext<[UserState, React.Dispatch<React.SetStateAction<UserState>>]>(null);

export const useUserState = (): UserState & UserStateActions => {
    const [auth, setAuth] = useContext(UserStateContext);

    return {
        ...auth,
        setAuth:(accessToken, operatorId) => setAuth((curr)   => ({ ...curr, accessToken, operatorId})),
    };
};


