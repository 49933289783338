import { useQuery } from '@apollo/client';
import { CITRIN_GET_ALLCITIES, GetAllCityRequest, GetAllCityResponse } from '..';

export const useGetAllCityQuery = () => {
    const { data, previousData, ...rest } = useQuery<GetAllCityResponse, GetAllCityRequest>(CITRIN_GET_ALLCITIES
        // fetchPolicy: 'cache-and-network',
        // onCompleted: (data) => console.log('[MGT] Pass', data),
    );

    return { ...rest, previousData: previousData?.CITRIN_MGT_getAllCity, data: data?.CITRIN_MGT_getAllCity ?? [] };
};