import { useEffect, useMemo } from "react";
import { VehiculeProblem } from "../../../shared/types";
import { chunk } from 'lodash';
import { usePageState } from "../../../Hooks/usePageState";
import { useOverviewLinkState } from "../linkstate/FleetProblemOverview.linkstate";
import { useGetVehiculeProblemQuery } from "../../../GraphQL/hooks/useGetVehiculeProblemQuery";

const pageSize = 10;
const getPageCount = (vehiculeProblems: VehiculeProblem[]) => Math.ceil(vehiculeProblems?.length / pageSize);
const getCurrentPage = (requestedPage: number, vehiculeProblems: VehiculeProblem[]) => {
    const pageCount = getPageCount(vehiculeProblems);
    return requestedPage < 1 ? 1 : requestedPage > pageCount ? pageCount : requestedPage;
};

export const useSortedVehiculeProblemQuery = () => {
    const { setLoading } = usePageState();
    const [state, setState] = useOverviewLinkState();
    const { page } = state;

    const { data: unsortedVehiculeProblems, refetch, loading } = useGetVehiculeProblemQuery();
    useEffect(() => setLoading(loading), [loading]);

    const setPage = (newPage: number) => {
        const pageCount = getPageCount(unsortedVehiculeProblems);
        // console.log('new page', newPage, pageCount);
        const page = newPage < 1 ? 1 : newPage > pageCount ? pageCount : newPage;
        setState({ ...state, page });
    };

    const { pageCount, totalCount, sortedVehiculeProblems } = useMemo(() => {
        const totalCount = unsortedVehiculeProblems?.length ?? 0;
        const pageCount = getPageCount(unsortedVehiculeProblems);
        const sortedVehiculeProblems = chunk(unsortedVehiculeProblems, pageSize);
        
        return { totalCount, pageCount, sortedVehiculeProblems };
    }, [unsortedVehiculeProblems]);

    const currentPage = getCurrentPage(page, unsortedVehiculeProblems);
  


    return {
        sortedVehiculeProblems: sortedVehiculeProblems?.length > 0 ? sortedVehiculeProblems[currentPage - 1] : [],
        refetch: () => refetch(),
        page: page ?? 1,
        pageCount,
        totalCount,
        pageSize,
        setPage,
        loading
    };
};
