import { Row } from 'antd';
import { CarOutlined, DashboardOutlined, RiseOutlined, TeamOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import "./style.css";
import { ReportsCounters } from '../../../shared/types';

const CountersHeader = ({ data }) => {
  const [countersData, setCountersData] = useState<ReportsCounters>();

  useEffect(() => {
    setCountersData(data);
  }, [data]);

  return (
    <>
      <div>
        <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 30 }}>
          <div className="cards">
            <div className="card">
              <div className="card-content">
                <div className="card-number"> {countersData?.totalTrips} </div>
                <div className="card-name"> Total Trips</div>
              </div>
              <div className="icon-box">
                <i className=" "><CarOutlined /></i>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="card-number"> {countersData?.totalRiders} </div>
                <div className="card-name"> Total Riders</div>
              </div>
              <div className="icon-box">
                <i className=" "><TeamOutlined /></i>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="card-number"> {countersData?.totalMileage} </div>
                <div className="card-name"> Total Mileage</div>
              </div>
              <div className="icon-box">
                <i className=" "><DashboardOutlined /></i>
              </div>
            </div>
            <div className="card">
              <div className="card-content">
                <div className="card-number"> {countersData?.totalRevenue} TND </div>
                <div className="card-name"> Total Earning</div>
              </div>
              <div className="icon-box">
                <i className=" "><RiseOutlined /></i>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </>

  )
}
  ;
export default CountersHeader;