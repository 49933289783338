import React, { useEffect, useState } from "react";
import { Input, Form, Button, Select, Upload, Space, Image } from 'antd';
import { handleFormChange, layout, validateMessages } from "../../../shared/Utils";
import { Operator } from "../../../shared/types";
import { useUpdateOperatorMutation } from "../../../GraphQL/hooks/useUpdateOperatorMutation";
import { EditOutlined, FileImageOutlined, PlusOutlined } from "@ant-design/icons";

const BusinessData = ({ businessData }) => {
  const [form] = Form.useForm();
  const [formError, setFormError] = useState(false);

  const [newOperator, setNewOperator] = useState<Operator>();
  const { mutation, loading: creating } = useUpdateOperatorMutation();

  const [fileList, _setFileList] = useState<File[]>([]);
  const setFileList = (newValue: File[]) => {
    _setFileList(newValue);
  };

  useEffect(() => {
    form.setFieldsValue(newOperator)
  }, [creating])

  useEffect(() => {
    setNewOperator(businessData)
    form.setFieldsValue(businessData)
  }, []);

  useEffect(() => {
    form.setFieldsValue(newOperator)
  }, [form])


  const onFinish = async () => {
    const values = await form.validateFields();
    if (values) {
      mutation({
        ...values,
        _id: businessData._id,
        logo: { name: businessData?.logo.name }
      }, fileList[0])
        .then((newOperator) => {
          setFileList([]);
          setNewOperator(newOperator);
        })
        .catch((err) => console.error(err));
      // TODO save new Setting
    }
  };

  const onReset = () => {
    form.resetFields();
  };


  return (
    <Form<Operator> {...layout} form={form} name="BusinessData" autoComplete="off" onFieldsChange={() => { setFormError(handleFormChange(form)) }} onFinish={onFinish} validateMessages={validateMessages}>
      <Form.Item label="Logo" valuePropName='fileList' getValueFromEvent={(event) => { return event?.fileList }}
        rules={[{ required: false, message: "please upload your Logo." },
        {
          validator(_, fileList) {
            return new Promise((resolve, reject) => {
              if (fileList?.size > 5000000) {
                reject("File size exceeded")
              } else {
                resolve("Successs");
              }
            });
          }
        }
        ]}>
        <Upload
          accept="image/png, image/jpeg"
          maxCount={1}
          fileList={fileList as any}
          beforeUpload={(_, fileList) => {
            setFileList(fileList);
            return false;
          }}
          customRequest={(info) => {
            setFileList([info?.file as File])
          }}
          showUploadList={false}
          listType="picture-card">
          {!newOperator?.logo?.url || fileList[0] ?
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>{fileList[0] ? "Attached image" : "Upload image"}</div>
            </div>
            :
            <div>
              <Image
                preview={{
                  visible: false,
                  mask: (
                    <div>
                      <EditOutlined style={{ marginRight: 8 }} /> Edit
                    </div>
                  ),
                }}
                width={100} src={newOperator?.logo?.url} />
            </div>
          }
        </Upload>
        {fileList[0] ?
          <div>
            {fileList[0] ? <span><FileImageOutlined />{fileList[0]?.name?.substring(0, 9)}</span> : ''}
            {fileList[0]?.name?.length > 9 ? '...' : ''}
          </div>
          : ''}
      </Form.Item>
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input type="text" disabled={true} style={{ width: '60%' }} />
      </Form.Item>
      <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
        <Input type="phone" placeholder={'72 234 567'} style={{ width: '60%' }} />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[{ required: true }]}>
        <Input type="email" style={{ width: '60%' }} />
      </Form.Item>
      <Form.Item name="description" label="Description" rules={[{ required: false }]}>
        <Input.TextArea showCount maxLength={100} style={{ width: '60%' }} />
      </Form.Item>
      <Form.Item name="tva" label="TVA" rules={[{ required: false }]}>
        <Input type="text" disabled={true} style={{ width: '60%' }} />
      </Form.Item>
      <Form.Item name="website" label="Website" rules={[{ required: false }]}>
        <Input type="text" style={{ width: '60%' }} />
      </Form.Item>
      <Form.Item label="Address">
        <Space.Compact style={{ width: '60%' }}>
          <Form.Item
            name={['address', 'street']}
            noStyle
            style={{ width: '70%' }}
            rules={[{ required: false }]}
          >
            <Input type="text" placeholder="Street name" />
          </Form.Item>
          <Form.Item
            name={['address', 'postalCode']}
            noStyle
            style={{ width: '30%' }}
            rules={[{ required: false }]}
          >
            <Input type="number" placeholder="Postal code" />
          </Form.Item>
        </Space.Compact>
      </Form.Item>
      <Form.Item label="Country / City">
        <Space.Compact style={{ width: '60%' }}>
          <Form.Item
            name={['address', 'city']}
            noStyle
            style={{ width: '33.333%' }}
            rules={[{ required: true }]}
          >
            <Select placeholder="Select city" disabled={true}>
              <Select.Option value="Ras Jebel">Ras Jebel</Select.Option>
              <Select.Option value="Raf Raf">Raf Raf</Select.Option>
              <Select.Option value="Arianna">Arianna</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={['address', 'state']}
            noStyle
            style={{ width: '33.333%' }}
            rules={[{ required: true }]}
          >
            <Select placeholder="Select state" disabled={true}>
              <Select.Option value="Bizerte">Bizerte</Select.Option>
              <Select.Option value="Tunis">Tunis</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={['address', 'country']}
            noStyle
            style={{ width: '33.333%' }}
            rules={[{ required: true }]}
          >
            <Select placeholder="Select country" disabled={true}>
              <Select.Option value="Tunisia">Tunisia</Select.Option>
              <Select.Option value="Algeria">Algeria</Select.Option>
              <Select.Option value="Marokko">Marokko</Select.Option>
            </Select>
          </Form.Item>
        </Space.Compact>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" style={{ marginRight: "8px" }} htmlType="submit" disabled={formError}>
          Save
        </Button>
        <Button htmlType="button" onClick={onReset}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );

};


export default BusinessData;

