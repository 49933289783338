import { Col, Pagination, Row, Space, Spin, Table } from 'antd';
import React, { useState } from "react";
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { VehiculeProblem } from '../../../shared/types';
import PopConfirm from '../../../shared/Components/PopConfirm';
import { useMutation } from '@apollo/client';
import { CITRIN_DELETE_VEHICULE_PROBLEM, DeleteVehiculeProblemRequest, DeleteVehiculeResponse } from '../../../GraphQL';
import { useSortedVehiculeProblemQuery } from '../hooks/useSortedVehiculeProblemQuery';
import VehiculeProblemEditModal from './FleetProblemEditModal';
import { useCurrentVehiculeProblem } from '../hooks/useCurrentVehiculeProblem';

const FleetProblemTable = () => {
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const { sortedVehiculeProblems: vehiculeProblems, refetch, loading, ...pagination } = useSortedVehiculeProblemQuery();
  const [deleteMutation] = useMutation<DeleteVehiculeResponse, DeleteVehiculeProblemRequest>(CITRIN_DELETE_VEHICULE_PROBLEM);
  const { setCurrentVehiculeProblem } = useCurrentVehiculeProblem();
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveVehiculeProblem = (vehiculeProblem: VehiculeProblem) => {
    setIsLoading(true);
    deleteMutation({ variables: { id: vehiculeProblem._id } })
      .then(() => {})
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditVehiculeProblem = (vehiculeProblem: VehiculeProblem) => {
    setCurrentVehiculeProblem(vehiculeProblem);
    setEditModalVisible(true);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'logicalId',
      key: 'logicalId',
      sorter: (a, b) => a.logicalId - b.logicalId,
      width: 80,
    },
    {
      title: 'Device ID (qrCode)',
      dataIndex: 'qrCode',
      key: 'qrCode',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const iconMap = {
          Solved: <CheckCircleOutlined style={{ color: 'green' }} />,
          Issue: <WarningOutlined style={{ color: 'red' }} />,
          InProgress: <LoadingOutlined style={{ color: '#f29339' }} />,
        };
  
        const icon = iconMap[status];
  
        return (
          <span style={{ color: icon ? icon.props.style.color : 'inherit' }}>
            {icon} {status}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, vehiculeProblem: VehiculeProblem) => (
        <Space size="middle">
          <a href="#" onClick={() => handleEditVehiculeProblem(vehiculeProblem)}><EditOutlined style={{ fontSize: '16px', color: 'green' }} /></a>
          <PopConfirm
            title={"Delete the problem"}
            description={"Are you sure to delete this vehicule problem?"}
            onConfirmTask={() => handleRemoveVehiculeProblem(vehiculeProblem)}
            popIcon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            actionIcon={<DeleteOutlined style={{ fontSize: '16px', color: 'red' }} />}
          />
        </Space>
      ),
    },
  ];

  const Paginator = () => (
    <Row justify={'center'}>
      <Col>
        <Pagination
          size="default"
          total={pagination.totalCount}
          pageSize={pagination.pageSize}
          current={pagination.page}
          showTotal={(total) => `Total Vehicles Problems ${total}`}
          onChange={(newPage) => pagination.setPage(newPage)}
          showQuickJumper={true}
          showSizeChanger={false}
          showLessItems={false}
        />
      </Col>
    </Row>
  );

  return (
    <>
      {isEditModalVisible && <VehiculeProblemEditModal updateList={refetch} isOpen={isEditModalVisible} onRequestClose={setEditModalVisible} />}

      <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
        <Col xs={24}>
          <Spin spinning={isLoading || loading}>
            <Table
              dataSource={vehiculeProblems}
              columns={columns}
              pagination={false}
              rowKey={() => Math.random().toString()}
              scroll={{ x: 1500, y: 400 }}
            />
          </Spin>
        </Col>
      </Row>
      <Paginator />
    </>
  );
};

export default FleetProblemTable;
