import { useQuery } from '@apollo/client';
import {  CITRIN_GET_VEHICULES, GetVehiculesResponse } from '..';

export const useGetVehiculesMapQuery = () => {
    const { data, previousData, ...rest } = useQuery<GetVehiculesResponse>(CITRIN_GET_VEHICULES, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });
    return { ...rest, previousData: previousData?.CITRIN_MGT_getVehicules, data: data?.CITRIN_MGT_getVehicules ?? [] };
};
