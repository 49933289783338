import { Operator } from "../../../shared/types";
import React, { createContext, useContext, useState } from "react";

type ContextType<S> = [S, React.Dispatch<React.SetStateAction<S>>] | null;

const initialState = null;
const OperatorInfoContext = createContext<ContextType<Operator>>(null);
OperatorInfoContext.displayName = "OperatorInfoContext";

function useCurrentOperator() {
    const context = useContext(OperatorInfoContext);
    if (!context) {
        throw new Error(`useCurrentOperator must be used within a OperatorInfoProvider`);
    }

    const [currentOperator, setCurrentOperator] = context;

    return {
        currentOperator,
        setCurrentOperator
    };
}

function OperatorInfoProvider(props) {
    const Operator = useState(initialState);
    return <OperatorInfoContext.Provider value={Operator} {...props} />;
}

export { OperatorInfoProvider, useCurrentOperator };
