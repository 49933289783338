import { Col, Row } from 'antd';
import React from "react";
import Title from "antd/es/typography/Title";
import CouponTable from './Components/CouponTable';
import { CouponInfoProvider } from './hooks/useCurrentCoupon';

const CouponsView = () => {

    return (
        <>
            <Row style={{ paddingBottom: 10, paddingTop: 20 }}>
                <Col span={12}><Title level={3}>Promo/Coupon</Title></Col>
            </Row>

            <div style={{ flex: '1 1 auto' }}>
                <CouponInfoProvider>
                    <CouponTable />
                </CouponInfoProvider>
            </div>
        </>
    )
};

export default CouponsView;

