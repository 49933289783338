import React, { useMemo } from 'react';
import { Col, DatePicker, Input, Radio, Select, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useOverviewLinkStateActions } from '../hooks/useOverviewLinkStateActions';
import { useOverviewLinkState } from '../linkstate/riderOverview.linkstate';
import { allIdentityCheckStatus } from '../../../shared/types';

const isNotEmpty = (s: string) => s && !isEmpty(s);
export type RiderStatus = 'Active' | 'Archived' | 'Suspended';
export const allRiderStatus: RiderStatus[] = ['Active', 'Archived', 'Suspended'];

export type RiderGender = 'All' | 'Female' | 'Male';

export const allGenderValues: RiderGender[] = ['All', 'Female', 'Male'];


export const RidersFilter = () => {
    const [{ selectedDateRange, search, gender, identityCheck }] = useOverviewLinkState();
    const { patch: patchState } = useOverviewLinkStateActions();

    const rangePickerValue = useMemo(() => {
        return isNotEmpty(selectedDateRange?.fromDate) && isNotEmpty(selectedDateRange?.toDate)
            ? [moment(selectedDateRange?.fromDate), moment(selectedDateRange?.toDate)]
            : [null, null];
    }, [selectedDateRange]);

    const handleRangePickerChanged = (dates: [any, any]) => {
        patchState({
            selectedDateRange:
                dates && dates[0] && dates[1]
                    ? {
                        fromDate: dates[0].format('YYYY-MM-DD'),
                        toDate: dates[1].format('YYYY-MM-DD'),
                    }
                    : undefined,
        });
    };

    return (
        <>
            <Col style={{ width: 350 }}>
                <Input
                    value={search}
                    placeholder={'Search by email, full name, phone, country..'}
                    onChange={(e) => patchState({ search: e.target.value })}
                    addonBefore={<SearchOutlined />}
                />
            </Col>
            <Col>
                <Radio.Group
                    value={gender}
                    buttonStyle="solid"
                    onChange={(e) => patchState({ gender: e.target.value })}
                >
                    {allGenderValues.map((v) => (
                        <Radio.Button key={`genderFilter-${v}`} value={v}>
                            {v}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </Col>
            <Col>
                <Tooltip title={`Filter by Identity Check`}>
                    <Radio.Group
                        value={identityCheck}
                        buttonStyle="solid"
                        style={{ width: '100%' }}
                        onChange={(e) => patchState({ identityCheck: e.target.value })}
                    >
                        {allIdentityCheckStatus.map((v) => (
                            <Radio.Button key={`statusFilter-${v}`} value={v}>
                                {v ? 'Active' : 'Inactive'}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </Tooltip>
            </Col>
            <Col>
                <Tooltip title={`Filter by birthday`}>
                    <DatePicker.RangePicker value={rangePickerValue as any} onChange={handleRangePickerChanged} />
                </Tooltip>
            </Col>
        </>
    );
};

