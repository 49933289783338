import { useQuery } from '@apollo/client';
import { CITRIN_GET_SETTINGS, GetSettingsRequest, GetSettingsResponse } from '..';

export const useGetSettingsQuery = () => {
    const { data, previousData, ...rest } = useQuery<GetSettingsResponse, GetSettingsRequest>(CITRIN_GET_SETTINGS
        // fetchPolicy: 'cache-and-network',
        // onCompleted: (data) => console.log('[MGT] Setting', data),
    );

    return { ...rest, previousData: previousData?.CITRIN_MGT_getSettings, data: data?.CITRIN_MGT_getSettings ?? [] };
};