import { useMutation } from '@apollo/client';
import { keys } from 'lodash';
import { City } from '../../shared/types';
import { CITRIN_CREATE_CITIES, CreateCityRequest, CreateCityResponse } from '..';

type CreateMutationFunction = (request: City) => Promise<City>;
export const useCreateCityMutation = (): CreateMutationFunction => {

    const [mutation, { client }] = useMutation<CreateCityResponse, CreateCityRequest>(CITRIN_CREATE_CITIES);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const created = data?.CITRIN_MGT_createCity;
     //   updateGetCitiesCache(client, updated, ...(keys(request) as any));
        return created;
    };
};
