import { Descriptions } from 'antd';
import React from "react";
import { useCurrentRider } from '../hooks/useCurrentRider';
import moment from 'moment';

const RidePassDetails = () => {
  const { currentRider } = useCurrentRider();
  return (

    <Descriptions title={`Rider details #${currentRider?.logicalId}`} bordered>
      <Descriptions.Item span={3} label="Title">{currentRider?.ridePassId?.name}</Descriptions.Item>
      <Descriptions.Item span={3} label="Description">{currentRider?.ridePassId?.description}</Descriptions.Item>
      <Descriptions.Item span={3} label="Start Date"> {currentRider?.ridePassId?.startDate
        ? moment(Number(currentRider?.ridePassId?.startDate)).format("DD-MM-YYYY")
        : ""}</Descriptions.Item>
      <Descriptions.Item span={3} label="End Date">{currentRider?.ridePassId?.endDate
        ? moment(Number(currentRider?.ridePassId?.endDate)).format("DD-MM-YYYY")
        : ""}</Descriptions.Item>
      <Descriptions.Item span={3} label="Vehicule type">{currentRider?.ridePassId?.vehiculeType}</Descriptions.Item>
      <Descriptions.Item span={3} label="Unlock price">{currentRider?.ridePassId?.unlockPrice}</Descriptions.Item>
      <Descriptions.Item span={3} label="Minute price">{currentRider?.ridePassId?.minutePrice}</Descriptions.Item>
      <Descriptions.Item span={3} label="Initial price">{currentRider?.ridePassId?.initialPrice}</Descriptions.Item>
      <Descriptions.Item span={3} label="Price unit">{currentRider?.ridePassId?.priceUnit}</Descriptions.Item>
      <Descriptions.Item span={3} label="Duration">{currentRider?.ridePassId?.duration}</Descriptions.Item>
      <Descriptions.Item span={3} label="Discount">{currentRider?.ridePassId?.discount}</Descriptions.Item>
    </Descriptions>
  );
};

export default RidePassDetails;