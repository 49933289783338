import { useMutation } from '@apollo/client';
import { VehiculeProblem } from '../../shared/types';
import { CITRIN_UPDATE_VEHICULE_PROBLEM, UpdateVehiculeProblemRequest, UpdateVehiculeProblemResponse} from '..';

type UpdateMutationFunction = (request: VehiculeProblem) => Promise<VehiculeProblem>;
export const useUpdateVehiculeProblemMutation = (): UpdateMutationFunction => {

    const [mutation, { client }] = useMutation<UpdateVehiculeProblemResponse, UpdateVehiculeProblemRequest>(CITRIN_UPDATE_VEHICULE_PROBLEM);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const updated = data?.CITRIN_MGT_updateVehiculeProblem;
     //   updateGetVehiculeCache(client, updated, ...(keys(request) as any));
        return updated;
    };
};
