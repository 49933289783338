import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

console.log("ENV_NODE: ", process.env.NODE_ENV)

const option = {
  uri: process.env.REACT_APP_CITRIN_API_URL,
  credentials: "include"
}

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink(option),
    /*link: new HttpLink({
        uri: "http://localhost:4000/graphql",
        credentials: "include"
    }),*/
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
