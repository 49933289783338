import { gql, useQuery } from '@apollo/client';

const gqlQueryFilter = gql`
    query overviewPassState {
        CITRIN_MGT_overviewCityState @client
    }
`;
const typeName = 'CITRIN_MGT_overviewCityState';


export type OverviewState = {
    page?: number;
};

const initialState: OverviewState = {
    page: 1,
};

export const useOverviewLinkState = (): [OverviewState, (newState: OverviewState) => void] => {
    const { data, client } = useQuery(gqlQueryFilter, { fetchPolicy: 'cache-first' });
    const setState = (newState: OverviewState) => {
        client.writeQuery({
            query: gqlQueryFilter,
            data: {
                CITRIN_MGT_overviewCityState: {
                    value: newState,
                    __typename: typeName,
                },
            },
        });
    };
    return [data?.CITRIN_MGT_overviewCityState?.value ?? initialState, setState];
};

export default {
    defaults: [
        {
            query: gqlQueryFilter,
            data: {
                CITRIN_MGT_overviewCityState: {
                    value: initialState,
                    __typename: 'CITRIN_MGT_overviewCityState',
                },
            },
        },
    ],
};