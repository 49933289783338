import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';


const CO2LineChart = ({ data }) => {
  const [CO2Data, setCO2Data] = useState([]);

  useEffect(() => {
    setCO2Data(data);
  }, [data]);

  const config = {
    data: CO2Data,
    xField: 'month',
    yField: 'estimate',
    label: {},
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };

  return (
    <div>
      <Line {...config} />
    </div>
  );
};

export default CO2LineChart;