import React, { useEffect, useState, useRef } from "react";
import { Form, Modal, Button, Input, Image, Switch, Select } from 'antd';
import { handleFormChange, layoutEdit, validateMessages } from "../../../shared/Utils";
import { VehiculeProblem, allVehiculeStatus, allVehiculesProblemStatus } from "../../../shared/types";
import { useCurrentVehiculeProblem } from "../hooks/useCurrentVehiculeProblem";
import { useUpdateVehiculeProblemMutation } from "../../../GraphQL/hooks/useUpdateVehiculeProblemMutation";

const FleetProblemEditModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm()
  const [formError, setFormError] = useState(false);
  const [newVehiculeProblem, setNewVehiculeProblem] = useState<VehiculeProblem>();
  const { currentVehiculeProblem } = useCurrentVehiculeProblem();

  const mutation = useUpdateVehiculeProblemMutation();

  useEffect(() => {
    form.setFieldsValue(currentVehiculeProblem)
  }, [form, currentVehiculeProblem])

  const handleCommit = async () => {
    try {
      const values = await form.validateFields();
      console.log("values", values)
      if (values) {
        mutation({
          _id: currentVehiculeProblem._id,
          ...values,
        })
          .then(() => {
            onRequestClose(false)
            updateList()
          })
          .finally(() => console.log("The problem was successfuly updated"))
          .catch((err) => console.error(err));
      }
    } catch (errorInfo) {
      console.log('Failed: Form is empty');
    }
  };

  const handleCancel = () => {
    onRequestClose(false);
  };

  
  const [mapInitialized, setMapInitialized] = useState(false);

  // Create a reference to the map container element using useRef
  const mapContainerRef = useRef(null);

  // useEffect hook to initialize the map when the modal is opened and if it's not already initialized
  useEffect(() => {
    if (isOpen && !mapInitialized) {
      // Define a function to initialize the Google Map and marker
      const initializeMap = () => {
        // Define map options such as center and zoom level
        const mapOptions = {
          center: {
            lat: currentVehiculeProblem?.currentLocation?.coordinates.latitude,
            lng: currentVehiculeProblem?.currentLocation?.coordinates.longitude,
          },
          zoom: 12,
        };

        // Create a new Google Map instance passing the map options and the map container reference
        const mapInstance = new window.google.maps.Map(mapContainerRef.current, mapOptions);

        // Create a marker for the vehicle's location
        const markerOptions = {
          position: {
            lat: currentVehiculeProblem?.currentLocation?.coordinates.latitude,
            lng: currentVehiculeProblem?.currentLocation?.coordinates.longitude,
          },
          map: mapInstance,
          title: "Vehicle Location",
        };

        // Add a marker to the map
        const vehicleMarker = new window.google.maps.Marker(markerOptions);

        // Set mapInitialized to true once the map is initialized
        setMapInitialized(true);
      };

      // Check if the Google Maps API is available
      if (window.google) {
        // If available, initialize the map
        initializeMap();
      } else {
        // If not available, dynamically load the Google Maps API script
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.addEventListener("load", initializeMap);
        document.body.appendChild(script);
      }
    }
  }, [isOpen, currentVehiculeProblem, mapInitialized]);

  return (
    <Modal
      title="Edit Vehicle Problem"
      open={isOpen}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="edit" type="primary" onClick={handleCommit} disabled={formError}>
          Edit
        </Button>,
      ]}
    >
      <Form<VehiculeProblem>
        {...layoutEdit}
        form={form}
        name="nest-messages"
        onFieldsChange={() => {
          setFormError(handleFormChange(form));
        }}
        onValuesChange={(changedValues, allValues) => setNewVehiculeProblem(allValues)}
        validateMessages={validateMessages}
      >
        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
        <Select placeholder="Select status vehicle" style={{ width: '60%' }}>
            {(allVehiculesProblemStatus ?? []).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input type="textarea" placeholder="Description of the vehicle status" style={{ width: '70%' }} disabled={true} />
        </Form.Item>
        <Form.Item name="problemPhotos" label="Problem photos" labelCol={{ span: 6 }}  >
          <div style={{ display: "flex" }}>
            {currentVehiculeProblem?.problemPhotos[0] && (
              <div style={{ marginRight: '10px' }}>
                <Image width={70} src={currentVehiculeProblem.problemPhotos[0].url} />
              </div>
            )}
            {currentVehiculeProblem?.problemPhotos[1] && (
              <div style={{ marginRight: '10px' }}>
                <Image width={70} src={currentVehiculeProblem.problemPhotos[1].url} />
              </div>
            )}
            {currentVehiculeProblem?.problemPhotos[2] && (
              <div style={{ marginRight: '10px' }}>
                <Image width={70} src={currentVehiculeProblem.problemPhotos[2].url} />
              </div>
            )}
          </div>
        </Form.Item>
        <Form.Item name="location" label="Vehicle location" labelCol={{ span: 6 }}></Form.Item>
        <div style={{ position: 'relative' }}>
          <div ref={mapContainerRef} style={{ width: '100%', height: '30vh', borderRadius: "0px 0px 8px 8px", marginTop: '-20px' }}></div>
        </div>
      </Form>
    </Modal>
  );

}

export default FleetProblemEditModal;