import { useMutation } from '@apollo/client';
import { SupportInput } from '../../shared/types';
import { CITRIN_CREATE_SUPPORT, CreateSupportRequest, CreateSupportResponse } from '..';

type CreateMutationFunction = (request: SupportInput, file: any) => Promise<String>;
export const useSupportUserMutation = (): CreateMutationFunction => {

    const [mutation, { client }] = useMutation<CreateSupportResponse, CreateSupportRequest>(CITRIN_CREATE_SUPPORT);
    return async (request, file) => {
        const { data } = await mutation({ variables: { request, file } });
        const SupportRequestResponse = data?.CITRIN_MGT_supportUser;
        return SupportRequestResponse;
    };
};
