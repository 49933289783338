import { useMutation } from '@apollo/client';
import { Coupon } from '../../shared/types';
import { CITRIN_CREATE_COUPON, CreateCouponRequest, CreateCouponResponse } from '..';

type CreateMutationFunction = (request: Coupon) => Promise<Coupon>;
export const useCreateCouponMutation = (): CreateMutationFunction => {

    const [mutation, { client }] = useMutation<CreateCouponResponse, CreateCouponRequest>(CITRIN_CREATE_COUPON);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const created = data?.CITRIN_MGT_createCoupon;
        return created;
    };
};
