import React, { useMemo } from 'react';
import { Col, DatePicker, Input, Radio, Select, Slider, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useOverviewLinkStateActions } from '../hooks/useOverviewLinkStateActions';
import { useOverviewLinkState } from '../linkstate/fleetOverview.linkstate';
import { useGetAllCityQuery } from '../../../GraphQL/hooks/useGetAllCityQuery';
import { allVehiculeStatus, allVehiculeTypes } from '../../../shared/types';
import { formatter, isNotEmpty, markSteps } from '../shared';

export const FleetFilter = () => {
    const { data: cities } = useGetAllCityQuery();
    const [{ selectedDateRange, search, selectedCities, status, batteryLevelRange, selectedTypes }] = useOverviewLinkState();
    const { patch: patchState } = useOverviewLinkStateActions();

    const rangePickerValue = useMemo(() => {
        return isNotEmpty(selectedDateRange?.fromDate) && isNotEmpty(selectedDateRange?.toDate)
            ? [moment(selectedDateRange?.fromDate), moment(selectedDateRange?.toDate)]
            : [null, null];
    }, [selectedDateRange]);

    const rangeSliderValue = useMemo(() => {
        return !isEmpty(batteryLevelRange?.minLevel) && !isEmpty(batteryLevelRange?.maxLevel)
            ? [batteryLevelRange?.minLevel, batteryLevelRange?.maxLevel]
            : [null, null];
    }, [batteryLevelRange]);

    const handleRangePickerChanged = (dates: [any, any]) => {
        patchState({
            selectedDateRange:
                dates && dates[0] && dates[1]
                    ? {
                        fromDate: dates[0].format('YYYY-MM-DD'),
                        toDate: dates[1].format('YYYY-MM-DD'),
                    }
                    : undefined,
        });
    };

    const handleRangeSliderChanged = (ranges: number[]) => {
        patchState({
            batteryLevelRange:
                ranges && ranges[0] || ranges[1]
                    ? {
                        minLevel: ranges[0],
                        maxLevel: ranges[1],
                    }
                    : undefined,
        });
    }

    return (
        <>
            <Col md={{ span: 5 }}>
                <Input
                    value={search}
                    style={{ width: '100%' }}
                    placeholder={'Search by qrCode, powerType'}
                    onChange={(e) => patchState({ search: e.target.value })}
                    addonBefore={<SearchOutlined />}
                />
            </Col>
            <Col md={{ span: 3 }}>
                <Tooltip title={`Filter by Type: ${(selectedTypes ?? []).map((vehiculeType) => `#${vehiculeType}`).join(' ')}`}>
                    <Select
                        placeholder="Type of vehicule"
                        mode="multiple"
                        value={selectedTypes ?? []}
                        style={{ width: '100%', height: 32 }}
                        maxTagCount={1}
                        maxTagTextLength={10}
                        onChange={(e) => patchState({ selectedTypes: e })}
                    >
                        {(allVehiculeTypes ?? []).map((vehiculeType) => (
                            <Select.Option key={vehiculeType} value={vehiculeType}>
                                {vehiculeType}
                            </Select.Option>
                        ))}
                    </Select>
                </Tooltip>
            </Col>
            <Col md={{ span: 3 }}>
                <Tooltip title={`Filter by City: ${(selectedCities ?? []).map((city) => `#${city}`).join(' ')}`}>
                    <Select
                        placeholder="Cities"
                        mode="multiple"
                        value={selectedCities ?? []}
                        style={{ width: '100%', height: 32 }}
                        maxTagCount={1}
                        maxTagTextLength={10}
                        onChange={(e) => patchState({ selectedCities: e })}
                    >
                        {(cities ?? []).map((city) => (
                            <Select.Option key={city._id} value={city.cityName}>
                                {city.cityName}
                            </Select.Option>
                        ))}
                    </Select>
                </Tooltip>
            </Col>
            <Col md={{ span: 4 }}>
                <Tooltip title={`Filter by Operated Date`}>
                    <DatePicker.RangePicker style={{ width: '100%' }} value={rangePickerValue as any} onChange={handleRangePickerChanged} />
                </Tooltip>
            </Col>
            <Col>
                <Tooltip title={`Filter by Status`}>
                    <Radio.Group
                        value={status}
                        buttonStyle="solid"
                        style={{ width: '100%' }}
                        onChange={(e) => patchState({ status: e.target.value })}
                    >
                        {allVehiculeStatus.map((v) => (
                            <Radio.Button key={`statusFilter-${v}`} value={v}>
                                {v ? 'Active' : 'Inactive'}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </Tooltip>
            </Col>
            <Col md={{ span: 4 }}>
                <Slider range
                    style={{ width: '100%' }}
                    marks={markSteps}
                    defaultValue={[0, 100]}
                    tooltip={{ formatter }}
                    onChange={handleRangeSliderChanged} />
            </Col>
        </>
    );
};

