import {Col, Row} from 'antd';
import React, {useState} from "react";
import Title from "antd/es/typography/Title";
import { CampaignInfoProvider } from './hooks/useCurrentCampaign';
import CampaignTable from './Components/CampaignTable';


const AdvtCampaignView = () => {

    return (
        <>
            <Row style={{paddingBottom: 10, paddingTop: 20}}>
                <Col span={12}><Title level={3}>Advt. Campaign</Title></Col>
            </Row>

            <div style={{ flex: '1 1 auto' }}>
                <CampaignInfoProvider>
                    <CampaignTable/>
                </CampaignInfoProvider>
              </div>
        </>
    )
};


export default AdvtCampaignView;

