import { Setting } from "../../../shared/types";
import React, { createContext, useContext, useState } from "react";

type ContextType<S> = [S, React.Dispatch<React.SetStateAction<S>>] | null;

const initialState = null;
const SettingInfoContext = createContext<ContextType<Setting>>(null);
SettingInfoContext.displayName = "SettingInfoContext";

function useCurrentSetting() {
    const context = useContext(SettingInfoContext);
    if (!context) {
        throw new Error(`useCurrentSetting must be used within a SettingInfoProvider`);
    }

    const [currentSetting, setCurrentSetting] = context;

    return {
        currentSetting,
        setCurrentSetting
    };
}

function SettingInfoProvider(props) {
    const Settings = useState(initialState);
    return <SettingInfoContext.Provider value={Settings} {...props} />;
}

export { SettingInfoProvider, useCurrentSetting };
