import { Col, Row } from 'antd';
import React from "react";
import Title from "antd/es/typography/Title";
import FleetProblemTable from './Components/FleetProblemTable';
import { VehiculeProblemInfoProvider } from './hooks/useCurrentVehiculeProblem';

const FleetProblemView = () => {

  return (
    <>
      <>
        <Row style={{ paddingBottom: 10, paddingTop: 10 }}>
          <Col span={12}><Title level={3}>Fleet Problems</Title></Col>
        </Row>

        <div style={{ flex: '1 1 auto' }}>
          <VehiculeProblemInfoProvider>
            <FleetProblemTable />
          </VehiculeProblemInfoProvider>
        </div>
      </>
    </>
  )
};

export default FleetProblemView;