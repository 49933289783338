import { Button, Col, Pagination, Row, Space, Spin, Table } from 'antd';
import React, { useState } from "react";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Pricing } from '../../../shared/types';
import { useSortedPricingQuery } from '../hooks/useSortedPricingQuery';
import { useCurrentPricing } from '../hooks/useCurrentPricing';
import PopConfirm from '../../../shared/Components/PopConfirm';
import { useMutation } from '@apollo/client';
import { CITRIN_DELETE_PRICING, DeletePricingRequest, DeletePricingResponse } from '../../../GraphQL';
import PricingCreateModal from './PricingCreateModal';
import PricingEditModal from './PricingEditModal';


const PricingTable = () => {
    const [isCreateModalVisible, setCreateModalVisible] = useState(false);
    const [isEditModalVisible, setEditModalVisible] = useState(false);

    const { sortedPricings: pricings, refetch, loading, ...pagination } = useSortedPricingQuery();
    const [deleteMutation] = useMutation<DeletePricingResponse, DeletePricingRequest>(CITRIN_DELETE_PRICING);
    const { setCurrentPricing } = useCurrentPricing();

    const handleRemovePricing = (pricing: Pricing) => {
        setIsLoading(true);
        deleteMutation({ variables: { id: pricing._id } })
            .then(() => {
                refetch();
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCreatePricing = () => { setCreateModalVisible(true); };

    const handleEditPricing = (pricing: Pricing) => {
        setCurrentPricing(pricing);
        setEditModalVisible(true);
    };

    const [IsLoading, setIsLoading] = useState(false);

    const columns = [

        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'Minute price',
            dataIndex: 'minutePrice',
            key: 'minutePrice',

        },
        {
            title: 'Unlock price',
            dataIndex: 'unlockPrice',
            key: 'unlockPrice',

        },
        {
            title: 'Level',
            dataIndex: 'level',
            key: 'level',
        },

        {
            title: 'Vehicule type',
            dataIndex: 'vehiculeType',
            key: 'vehiculeType',
        },
        {
            title: "Action",
            key: "action",
            render: (_, pricing) =>
                <Space size="middle">
                    <a href="#" onClick={() => handleEditPricing(pricing)} ><EditOutlined style={{ fontSize: '16px', color: 'green' }} /></a>
                    <PopConfirm
                        title={"Delete the price"}
                        description={"Are you sure to delete this price?"}
                        onConfirmTask={() => handleRemovePricing(pricing)}
                        popIcon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        actionIcon={<DeleteOutlined style={{ fontSize: '16px', color: 'red' }} />}
                    />
                </Space>
        },

    ];

    const Paginator = () => (
        <Row justify={'center'}>
            <Col>
                <Pagination
                    size="default"
                    total={pagination.totalCount}
                    pageSize={pagination.pageSize}
                    current={pagination.page}
                    showTotal={(total) => `Total Prices ${total}`}
                    onChange={(newPage) => pagination.setPage(newPage)}
                    showQuickJumper={true}
                    showSizeChanger={false}
                    showLessItems={false}
                />
            </Col>
        </Row>
    );

    return (
        <>

            {isCreateModalVisible && <PricingCreateModal updateList={refetch} isOpen={isCreateModalVisible} onRequestClose={setCreateModalVisible} />}

            {isEditModalVisible && <PricingEditModal updateList={refetch} isOpen={isEditModalVisible} onRequestClose={setEditModalVisible} />}

            <Row justify={'end'}>
                <Button type="primary"
                    shape="round"
                    icon={<PlusCircleOutlined />}
                    size={'middle'}
                    onClick={handleCreatePricing}>Add</Button>
            </Row>


            <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Col xs={24}>
                    <Spin spinning={IsLoading || loading}>
                        <Table
                            dataSource={pricings}
                            columns={columns}
                            pagination={false}
                            rowKey={() => Math.random().toString()}
                            scroll={{ x: 1500, y: 400 }}>
                        </Table>
                    </Spin>
                </Col>
            </Row>
            <Paginator />
        </>
    )
};


export default PricingTable;