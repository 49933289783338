import { useEffect, useState } from 'react';
import { useMapData } from './useMapData';
import { Coord, Zone } from '../../../shared/types';

export const useZoneManagement = () => {
  const [{ selectedCity: currentSelectedCity, ...mapData }, setMapData] = useMapData();
  const [selectedCityZones, setselectedCityZones] = useState<Zone[]>(currentSelectedCity?.zones ?? undefined);

  useEffect(() => {
    setselectedCityZones(currentSelectedCity?.zones)
  }, [currentSelectedCity])

  useEffect(() => {
    const newSelectedCity = { ...currentSelectedCity, zones: selectedCityZones }
    setMapData({
      ...mapData,
      selectedCity: newSelectedCity,
      allZones: selectedCityZones ? [...new Set([...mapData.allZones, ...selectedCityZones])] : mapData.allZones
    });
  }, [selectedCityZones])

  const handleAddZone = (id_zone, coord) => {
    setselectedCityZones((curr) => [...curr, { id_zone: id_zone, name_zone: '', coord: coord }])
  };

  const handleRemoveZone = (id_zone: string) => {
    setselectedCityZones((curr) => curr.filter((zone) => zone.id_zone !== id_zone));
  };

  const handleEditZoneName = (id_zone: string, value: string) => {
    setselectedCityZones((curr) => curr.map((zone) => zone.id_zone === id_zone ? { ...zone, name_zone: value } : zone));
  }

  const handleEditZoneCoord = (id_zone: string, values: Coord[]) => {
    setselectedCityZones((curr) => curr.map((zone) => zone.id_zone === id_zone ? { ...zone, coord: values } : zone));
  };

  return { handleAddZone, handleEditZoneName, handleRemoveZone, handleEditZoneCoord };
}
