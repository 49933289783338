import { Pricing } from "../../../shared/types";
import React, { createContext, useContext, useState } from "react";

type ContextType<S> = [S, React.Dispatch<React.SetStateAction<S>>] | null;

const initialState = null;
const PricingInfoContext = createContext<ContextType<Pricing>>(null);
PricingInfoContext.displayName = "PricingInfoContext";

function useCurrentPricing() {
    const context = useContext(PricingInfoContext);
    if (!context) {
        throw new Error(`useCurrentPricing must be used within a PricingInfoProvider`);
    }

    const [currentPricing, setCurrentPricing] = context;

    return {
        currentPricing,
        setCurrentPricing
    };
}

function PricingInfoProvider(props) {
    const pricing = useState(initialState);
    return <PricingInfoContext.Provider value={pricing} {...props} />;
}

export { PricingInfoProvider, useCurrentPricing };
