import { useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import {ReportsQueryFilter} from '../../shared/types';
import { CITRIN_GET_REPORTS_CO2, GetReportsRequest, GetReportsCO2Response } from '..';

export const useGetReportsCO2Query = (request?: ReportsQueryFilter, debounceTime?: number) => {
    const [internalRequest, setInternalRequest] = useState(() => request);
    const debounced = useRef(
        debounce(
            (newRequest:ReportsQueryFilter) => {
                setInternalRequest(newRequest);
            },
            debounceTime ?? 100, 
            { leading: false, trailing: true }
        )
    ).current;
    useEffect(() => {
        return debounced(request);
    }, [request]);

    const { data, previousData, ...rest } = useQuery<GetReportsCO2Response, GetReportsRequest>(CITRIN_GET_REPORTS_CO2, {
        variables: { request: internalRequest },
        // fetchPolicy: 'cache-and-network',
        // onCompleted: (data) => console.log('[MGT] Users', data),
    });

    return { ...rest, previousData: previousData?.CITRIN_MGT_getReportsCO2, data: data?.CITRIN_MGT_getReportsCO2 ?? [] };
};