import { useQuery } from '@apollo/client';
import { CITRIN_GET_INVOICE_BY_TRIP_ID, GetInvoiceByTripIdResponse, GetInvoiceByTripIdRequest } from  '..';
 
export const useGetInvoiceByTripIdQuery = (id: string) => {

    const { data, previousData, ...rest } = useQuery<GetInvoiceByTripIdResponse, GetInvoiceByTripIdRequest>(CITRIN_GET_INVOICE_BY_TRIP_ID, {
        variables: { id },
         //fetchPolicy: 'cache-and-network',
         //onCompleted: (data) => console.log('[MGT] Riders', data),
        
    });
    return { ...rest, previousData: previousData?.CITRIN_MGT_getInvoiceByTripId, data: data?.CITRIN_MGT_getInvoiceByTripId ?? [] };
};