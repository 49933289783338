import {Col, Row} from 'antd';
import React from "react";
import Title from "antd/es/typography/Title";
import SupportModalView from './Components/SupportModalView';

const SupportView = () => {

    return (
        <>
            <Row style={{paddingBottom: 10, paddingTop: 20}}>
                <Col span={12}><Title level={3}>Support</Title></Col>
            </Row>
            <div style={{ flex: '1 1 auto' }}>
                    <SupportModalView />
            </div>
        </>
    )
};

export default SupportView;

