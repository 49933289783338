import React, { useState } from "react";
import { Input, Form, InputNumber, Modal, Button } from 'antd';
import { City } from "../../../shared/types";
import { handleFormChange, layoutEdit, validateMessages } from "../../../shared/Utils";
import { useCreateCityMutation } from "../../../GraphQL/hooks/useCreateCityMutation";

const CityCreateModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm();
  const [formError, setFormError] = useState(false);

  const mutation = useCreateCityMutation();


  const handleCommit = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        mutation({ ...values })
          .then(() => {
            onRequestClose(false)
            updateList()
          })
          .finally(() => {
            form.resetFields();
            console.log("New City was successfuly added")
          })
          .catch((err) => console.error(err));
      }
    } catch (errorInfo) {
      console.log('Failed: Form is empty');
    }
  };

  const handleCancel = () => {
    onRequestClose(false);
  };

  return (
    <Modal title="Add City" open={isOpen} visible={isOpen} onCancel={handleCancel} footer={[
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="add" type="primary" onClick={handleCommit} disabled={formError}>
        Add
      </Button>,
    ]}>
      <Form<City> {...layoutEdit} form={form} name="CityCreate" onFieldsChange={() => { setFormError(handleFormChange(form)) }} validateMessages={validateMessages}>
        <Form.Item name="cityName" label="Cityname" rules={[{ required: true }]}>
          <Input type="textarea" style={{ width: '40%' }} />
        </Form.Item>
        <Form.Item name="latitude" label="Latitude" rules={[{ required: true }]}>
          <InputNumber style={{ width: '40%' }} />
        </Form.Item>
        <Form.Item name="longitude" label="Longitude" rules={[{ required: true }]}>
          <InputNumber style={{ width: '40%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CityCreateModal;