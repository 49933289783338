import React from "react";
import { Descriptions, Modal } from 'antd';
import { useCurrentOperator } from "../hooks/useCurrentOperator";
import { Image } from 'antd';

const OperatorViewModal = ({ isOpen, onRequestClose }) => {
  const { currentOperator } = useCurrentOperator();
  const handleCancel = () => {
    onRequestClose(false);
  };
  return (
    <Modal width={600} open={isOpen} onCancel={handleCancel} footer={null} >
      <Descriptions title={`Operator details #${currentOperator?.logicalId}`} bordered>
        <Descriptions.Item span={3} label="Description">{currentOperator?.description}</Descriptions.Item>
        <Descriptions.Item span={3} label="TVA">{currentOperator?.tva}</Descriptions.Item>
        <Descriptions.Item span={3} label="Address">
          Country: {currentOperator?.address?.country}
          <br />
          Postal code:{currentOperator?.address?.postalCode}
          <br />
          State: {currentOperator?.address?.state}
          <br />
          Street: {currentOperator?.address?.street}
          <br />
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Logo">
          <Image width={150} src={currentOperator?.logo?.url} />
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
}
export default OperatorViewModal;