import { useEffect, useMemo } from "react";
import { Setting } from "../../../shared/types";
import { chunk } from 'lodash';
import { usePageState } from "../../../Hooks/usePageState";
import { useGetSettingsQuery } from "../../../GraphQL/hooks/useGetSettingsQuery";
import { useOverviewLinkState } from "../linkstate/userOverview.linkstate";

const pageSize = 10;
const getPageCount = (Settings: Setting[]) => Math.ceil(Settings?.length / pageSize);
const getCurrentPage = (requestedPage: number, trips: Setting[]) => {
    const pageCount = getPageCount(trips);
    return requestedPage < 1 ? 1 : requestedPage > pageCount ? pageCount : requestedPage;
};

export const useSortedSettingsQuery = () => {
    const { setLoading } = usePageState();
    const [state, setState] = useOverviewLinkState();
    const { page } = state;


    const { data: unsortedSettings, refetch, loading} = useGetSettingsQuery();
    useEffect(() => setLoading(loading), [loading]);

    const setPage = (newPage: number) => {
        const pageCount = getPageCount(unsortedSettings);
        // console.log('new page', newPage, pageCount);
        const page = newPage < 1 ? 1 : newPage > pageCount ? pageCount : newPage;
        setState({ ...state, page });
    };

    const { pageCount, totalCount, sortedSettings } = useMemo(() => {
        const totalCount = unsortedSettings?.length ?? 0;
        const pageCount = getPageCount(unsortedSettings);
        const sortedSettings = chunk(unsortedSettings, pageSize);

        return { totalCount, pageCount, sortedSettings };
    }, [unsortedSettings]);

    const currentPage = getCurrentPage(page, unsortedSettings);

    return {
        sortedSettings: sortedSettings?.length > 0 ? sortedSettings[currentPage - 1] : [],
        refetch: () => refetch(),
        page: page ?? 1,
        pageCount,
        totalCount,
        pageSize,
        setPage,
        loading
    };
};