import { useMutation } from '@apollo/client';
import { Pricing } from '../../shared/types';
import { CITRIN_CREATE_PRICING, CreatePricingRequest, CreatePricingResponse } from '..';

type CreateMutationFunction = (request: Pricing) => Promise<Pricing>;
export const useCreatePricingMutation = (): CreateMutationFunction => {

    const [mutation, { client }] = useMutation<CreatePricingResponse, CreatePricingRequest>(CITRIN_CREATE_PRICING);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const created = data?.CITRIN_MGT_createPricing;
     //   updateGetPricingCache(client, updated, ...(keys(request) as any));
        return created;
    };
};
