import { useMutation } from '@apollo/client';
import { Setting} from '../../shared/types';
import {CITRIN_CREATE_SETTING, CreateSettingResponse, CreateSettingRequest } from '..';

type CreateMutationFunction = (request: Setting) => Promise<Setting>;
export const useCreateSettingMutation = (): CreateMutationFunction => {

    const [mutation, { client }] = useMutation<CreateSettingResponse, CreateSettingRequest>(CITRIN_CREATE_SETTING);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const created = data?.CITRIN_MGT_createSetting;
     //   updateGetSettingCache(client, updated, ...(keys(request) as any));
        return created;
    };
};
