import React, { useEffect, useState } from "react";
import { Input, Form, Slider, Switch, InputNumber, Select, Modal, Space, DatePicker, Tooltip, Button } from 'antd';
import { handleFormChange, layoutEdit, validateMessages } from "../../../shared/Utils";
import { Vehicule, allPowerTypes, allVehiculeTypes } from "../../../shared/types";
import { useCurrentVehicule } from "../hooks/useCurrentVehicule";
import { useGetAllCityQuery } from "../../../GraphQL/hooks/useGetAllCityQuery";
import { useUpdateVehiculeMutation } from "../../../GraphQL/hooks/useUpdateVehiculeMutation";
import { formatter } from "../shared";
import dayjs from "dayjs";
import moment from "moment";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useGetAllOperatorsQuery } from "../../../GraphQL/hooks/useGetAllOperatorsQuery";
import { useGetPricingsQuery } from "../../../GraphQL/hooks/useGetPricingsQuery";

const FleetEditModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm()
  const [formError, setFormError] = useState(false);
  const [newVehicule, setNewVehicule] = useState<Vehicule>();
  const { currentVehicule } = useCurrentVehicule();

  const { data: operators } = useGetAllOperatorsQuery();

  const { data: pricings } = useGetPricingsQuery();

  const { data: cities } = useGetAllCityQuery();
  const mutation = useUpdateVehiculeMutation();

  const handleSwitchChange = (checked: boolean) => {
    setNewVehicule({ ...newVehicule, status: checked })
  }

  const handleSelectDate = (date: any, dateString: any) => {
    setNewVehicule({ ...newVehicule, operatedDate: moment(date).format("DD-MM-YYYY")  })
  };

  useEffect(() => {
    form.setFieldsValue(currentVehicule)
  }, [form, currentVehicule])

  const handleCommit = async () => {
    try {
      const values = await form.validateFields();
      console.log("_id. ", values?.pricingId)
      if (values) {
        mutation({ 
          _id: currentVehicule._id, 
          ...values,
          pricingId: values?.pricingId,
          location: {
            type: "Point",
            coordinates: {
              latitude: values?.location?.coordinates.latitude, 
              longitude: values?.location?.coordinates.longitude, 
            }
          } 
        })
          .then(() => {
            onRequestClose(false)
            updateList()
          })
          .finally(() => console.log("The Fleet was successfuly updated"))
          .catch((err) => console.error(err));
      }
    } catch (errorInfo) {
      console.log('Failed: Form is empty');
    }
  };

  const handleCancel = () => {
    onRequestClose(false);
  };

  return (
    <Modal title="Edit fleet" open={isOpen} destroyOnClose={true} visible={isOpen} onCancel={handleCancel} footer={[
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="edit" type="primary" onClick={handleCommit} disabled={formError}>
        Edit
      </Button>,
    ]}>
      <Form<Vehicule> {...layoutEdit} form={form} name="nest-messages" onFieldsChange={() => { setFormError(handleFormChange(form)) }} onValuesChange={(changedValues, allValues) => setNewVehicule(allValues)} validateMessages={validateMessages}>
        <Form.Item name="vehiculeType" label="Vehicule type" rules={[{ required: true }]}>
          <Select placeholder="Select type of vehicule" style={{ width: '60%' }}>
            {(allVehiculeTypes ?? []).map((vehiculeType) => (
              <Select.Option key={vehiculeType} value={vehiculeType}>
                {vehiculeType}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="qrcode" label="Device ID" rules={[{ required: true }]}>
          <Input style={{ width: '60%', marginRight: '10px' }} suffix={
            <Tooltip title="This is the device ID relative to the vehicle" placement="rightTop">
              <InfoCircleOutlined style={{ color: '#1677ff', fontSize: '20px' }} />
            </Tooltip>
          } />
        </Form.Item>
        <Form.Item name="mileage" label="Mileage" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="status" label="Status" valuePropName="checked" rules={[{ required: false }]}>
          <Switch checkedChildren="Active"
            unCheckedChildren="Inactive"
            checked={currentVehicule?.status}
            onClick={(checked: boolean) => handleSwitchChange(checked)} />
        </Form.Item>
        <Form.Item name="powerType" label="Power Type" rules={[{ required: true }]}>
          <Select placeholder="Select power type" style={{ width: '60%' }}>
            {(allPowerTypes ?? []).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="batteryLevel" label="Battery Level" rules={[{ required: true }]}>
          <Slider tooltip={{ formatter }} />
        </Form.Item>
        <Form.Item name="city" label="City" rules={[{ required: true }]}>
          <Select placeholder="Select City" style={{ width: '60%' }} >
            {(cities ?? []).map((city) => (
              <Select.Option key={city._id} value={city.cityName}>
                {city.cityName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Operated on" rules={[{ required: true }]}>
          <DatePicker format={"YYYY-MM-DD"}
            value={dayjs(moment(Number(currentVehicule?.operatedDate)).format("YYYY-MM-DD"))}
            onChange={handleSelectDate} />
        </Form.Item>
        <Form.Item name="operatorId" label="Operated by" rules={[{ required: true }]}>
          <Select placeholder="Select Operator" style={{ width: '60%' }} >
            {(operators ?? []).map((operator) => (
              <Select.Option key={operator._id} value={operator._id} >
                {operator.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Location" rules={[{ required: false }]}>
          <Space.Compact>
            <Form.Item name={["location", "coordinates", "latitude"]} noStyle style={{ width: '50%' }}>
              <InputNumber placeholder="Latitude" min={-90} max={90} step={0.0001} />
            </Form.Item>
            <Form.Item name={["location", "coordinates", "longitude"]} noStyle style={{ width: '50%' }}>
              <InputNumber placeholder="Longitude" min={-90} max={90} step={0.0001} />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Form.Item name="pricingId" label="Pricing" rules={[{ required: true }]} >
          <Select placeholder="Select pricing model"  style={{ width: '80%' }} >
            {(pricings ?? []).map((pricing) => (
              <Select.Option key={pricing._id} value={pricing._id}>
                {pricing.vehiculeType} - Minute: {pricing.minutePrice} Unlock: {pricing.unlockPrice}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );

}

export default FleetEditModal;