import React, { useEffect, useState } from "react";
import { Form, Modal, Button, Switch } from 'antd';
import { handleFormChange, layoutEdit, validateMessages } from "../../../shared/Utils";
import { Rider } from "../../../shared/types";
import { useCurrentRider } from "../hooks/useCurrentRider";
import { useUpdateRiderMutation } from "../../../GraphQL/hooks/useUpdateRiderMutation";

const RiderEditModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm()
  const [formError, setFormError] = useState(false);
  const [newRider, setNewRider] = useState<Rider>();
  const { currentRider } = useCurrentRider();


  const handleSwitchChange = (checked: boolean) => {
    setNewRider({ ...newRider, identityCheck: checked })
  }

  const mutation = useUpdateRiderMutation();

  useEffect(() => {
    form.setFieldsValue(currentRider)
  }, [form, currentRider])

  const handleCommit = async () => {
    try {
      const values = await form.validateFields();
      console.log("values", values)
      if (values) {
        mutation({
          _id: currentRider._id,
          ...values,
        })
          .then(() => {
            onRequestClose(false)
            updateList()
          })
          .finally(() => console.log("The rider identity was successfuly checked"))
          .catch((err) => console.error(err));
      }
    } catch (errorInfo) {
      console.log('Failed: Form is empty');
    }
  };

  const handleCancel = () => {
    onRequestClose(false);
  };

  return (
    <Modal
      title="Check rider identity"
      open={isOpen}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="edit" type="primary" onClick={handleCommit} disabled={formError}>
          Edit
        </Button>,
      ]}
    >
      <Form<Rider>
        {...layoutEdit}
        form={form}
        name="nest-messages"
        onFieldsChange={() => {
          setFormError(handleFormChange(form));
        }}
        onValuesChange={(changedValues, allValues) => setNewRider(allValues)}
        validateMessages={validateMessages}
      >
        <Form.Item name="identityCheck" label="status" valuePropName="Active" rules={[{ required: true }]}>
          <Switch 
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            checked={currentRider?.identityCheck}
            onChange={handleSwitchChange}/>
        </Form.Item>
      </Form>
    </Modal>
  );  

}

export default RiderEditModal;