import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';


const TripsLineChart = ({ data }) => {
  const [tripsData, setTripsData] = useState([]);

  useEffect(() => {
    setTripsData(data);
  }, [data]);

  const config = {
    data: tripsData,
    xField: 'year',
    yField: 'value',
    seriesField: 'category',
    yAxis: {
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    smooth: false,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };


  return (
    <div>
      <Line {...config} />
    </div>
  );
};

export default TripsLineChart;