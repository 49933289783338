import React from "react";
import styled from "styled-components";
import TopNavbar from "../../Nav/TopNavbar";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";

export default function PrivacyPolicyView() {

  const { t } = useTranslation();

  return (
    <>
      <TopNavbar />
      <Wrapper id="privacyPolicy">
        <Container>
          <Content>
            <div className="whiteBg" style={{ padding: "60px 0" }}>
              <div className="whiteBg">
                <Section className="row flexCenter"><Title className="extraBold font60">{t('titlePrivacyPolicy')}</Title></Section>
                               
                <p style={{textAlign: "center"}}>{t('lastUpdate')}</p>
                <br/>
                <Section>
                  <h2>{t('introduction')}</h2>
                  <Text>
                    <p className="font18">
                      {t('introductionParapgraph1')}
                    </p>
                    <br />
                    <p className="font18">
                      {t('introductionParapgraph2')}
                    </p>
                    <br />
                    <p className="font18"> {t('introductionParapgraph3')}</p>
                    <p className="font18"> {t('introductionParapgraph4')}</p>
                    <p className="font18"> {t('introductionParapgraph5')}</p>
                    <br/>
                    <p className="font18">{t('introductionParapgraph6')}</p>
                  </Text>
                </Section>
                <Section>
                  <h2> {t('titleCollectionPersonalData')}</h2>
                  <br />
                  <SubSection>
                    <h3> {t('registration')}</h3>
                    <Text>
                      <p className="font18">
                        {t('registrationParapgraph1')}
                      </p>
                      <ul>
                        <li className="font18"> {t('registrationParapgraph2')}</li>
                        <br />
                        <li className="font18"> {t('registrationParapgraph3')}</li>
                        <br />
                        <li className="font18"> {t('registrationParapgraph4')}</li>
                      </ul>
                      <br />
                      <p className="font18">
                        {t('registrationParapgraph5')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('registrationParapgraph6')}
                      </p>
                    </Text>
                  </SubSection>
                  <SubSection>
                    <h3> {t('dataCollection')}</h3>
                    <Text>
                      <p className="font18">
                        {t('dataCollectionParapgraph')}
                      </p>
                      <br />
                      <h3> {t('appPermissions')}</h3>
                      <br />
                      <p className="font18">
                        {t('appPermissionsParapgraph1')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('appPermissionsParapgraph2')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('appPermissionsParapgraph3')}
                      </p>
                      <br />
                      <br />
                      <h3> {t('appAnalysis')}</h3>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph1')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph2')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph3')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph4')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph5')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph6')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph7')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph8')}v
                      </p>
                      <br />
                      <p className="font18"> {t('appAnalysisParapgraph9')}</p>
                      <br />
                      <p className="font18"> {t('appAnalysisParapgraph10')}</p>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph11')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph12')}
                      </p>  <br />

                      <p className="font18">
                        {t('appAnalysisParapgraph13')}
                      </p>
                      <br />
                      <p className="font18"> {t('appAnalysisParapgraph14')}</p>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph15')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph16')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph17')}
                      </p>
                      <br />
                      <p className="font18" >
                        {t('appAnalysisParapgraph18')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('appAnalysisParapgraph19')}
                      </p>
                      <br />

                      <h3>{t('googleMaps')}</h3>
                      <br />
                      <p className="font18">
                        {t('googleMapsParapgraph1')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('googleMapsParapgraph2')}
                      </p>
                    </Text>
                  </SubSection>

                  <SubSection>
                    <Text>
                      <h3>{t('titleDataCollection')}</h3>
                      <br />

                      <p className="font18">
                        {t('textDataCollection')}<br />
                      </p>
                      <br />
                      <h3>{t('titleDataCollection1')}</h3>
                      <br />
                      <p className="font18">
                        {t('textDataCollection1')}
                      </p>
                      <br />
                      <h3>{t('titleDataCollectionVehicles')}</h3>
                      <br />
                      <p className="font18">
                        {t('textDataCollectionVehicles1')}
                      </p>
                      <br />

                      <p className="font18">
                        {t('textDataCollectionVehicles2')}
                      </p>
                      <br />

                      <p className="font18">
                        {t('textDataCollectionVehicles3')}
                      </p>
                      <br />

                      <p className="font18">
                        {t('textDataCollectionVehicles4')}
                      </p>
                      <br />

                      <p className="font18">
                        {t('textDataCollectionVehicles5')}
                      </p>
                      <br />

                      <p className="font18">{t('textDataCollectionVehicles6')}</p>
                      <br />

                      <p className="font18">
                        {t('textDataCollectionVehicles7')}
                      </p>

                      <p className="font18">
                        {t('textDataCollectionVehicles8')}
                      </p>

                    </Text>

                  </SubSection>

                  <SubSection>
                    <Text>
                      <h3>
                        {t('titleDataCollectionContact')}
                      </h3>
                      <br />
                      <h3>  {t('titleDataCollectionContact1')}</h3>
                      <br />
                      <p className="font18">
                        {t('textDataCollectionContact1')}
                      </p>

                      <p className="font18">
                        {t('textDataCollectionContact2')}
                      </p>

                      <br />
                      <h3>  {t('titleContractualCommunication')}</h3>
                      <br />
                      <p className="font18">
                        {t('textContractualCommunication1')}
                      </p>

                      <p className="font18">
                        {t('textContractualCommunication2')}
                      </p>

                      <br />
                      <h3> {t('titleSociaMedia')}</h3>
                      <br />
                      <p className="font18">
                        {t('textSociaMedia1')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textSociaMedia2')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textSociaMedia3')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textSociaMedia4')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textSociaMedia5')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textSociaMedia6')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textSociaMedia7')}
                      </p>
                    </Text>
                  </SubSection>

                  <SubSection>
                    <Text>
                      <h3>{t('titlePaymentProcessing')}</h3>
                      <br />
                      <p className="font18">
                        {t('textPaymentProcessing1')}
                      </p>
                    </Text>
                  </SubSection>

                  <SubSection>
                    <Text>
                      <h3> {t('titleDamageAccidents')}</h3>
                      <br />
                      <p className="font18">
                        {t('textDamageAccidents1')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textDamageAccidents2')}
                      </p>
                    </Text>
                  </SubSection>

                  <SubSection>
                    <Text>
                      <h3>
                        {t('titleDisclosureData')}
                      </h3>
                      <br />
                      <p className="font18">
                        {t('textDisclosureData1')}</p>
                      <br />
                      <p className="font18">
                        {t('textDisclosureData2')}
                      </p>

                      <p className="font18">
                        {t('textDisclosureData3')}
                      </p>
                      <p>
                        <br />
                      </p>

                    </Text>
                  </SubSection>

                  <SubSection>
                    <Text>
                      <h3> {t('titleVerificationIdentityAge')}</h3>
                      <br />
                      <p className="font18">
                        {t('textVerificationIdentityAge')}
                      </p>
                    </Text>
                  </SubSection>
                </Section>

                <Section>
                  <h2> {t('titleTransferPersonalData')}</h2>
                  <SubSection>
                    <Text>
                      <p className="font18">
                        {t('textTransferPersonalData')}
                      </p>
                      <br />
                      <p className="font18">
                        <b> {t('titleCitiesGovernment')}</b> {t('textCitiesGovernment')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textCitiesGovernment1')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textCitiesGovernment2')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textCitiesGovernment3')}
                      </p>
                      <br />
                      <p className="font18">
                        <b> {t('titleThird-partyResearchers')} </b> {t('textThird-partyResearchers')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textThird-partyResearchers1')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textThird-partyResearchers2')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textThird-partyResearchers3')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textThird-partyResearchers4')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textThird-partyResearchers5')}
                      </p>
                      <br />
                      <p className="font18">
                        {t('textThird-partyResearchers6')}
                      </p>
                      <br />
                      <p className="font18">
                        <b> {t('titleITService')}</b> {t('textITService')}
                      </p>
                      <br />
                      <p className="font18">
                        <b> {t('titleMarketingPartners')}</b>  {t('textMarketingPartners')}
                      </p>
                      <br />
                      <p className="font18">
                        <b> {t('titleInsuranceCompanies')}</b>  {t('textInsuranceCompanies')}
                      </p>
                      <br />
                      <p className="font18">
                        <b> {t('titleRegulators')}</b>  {t('textVerificationIdentityAge')}
                      </p>
                      <br />
                      <p className="font18">
                        <b> {t('titleSolicitors')}</b>  {t('textSolicitors')}
                      </p>
                      <br />
                      <p className="font18">
                        <b> {t('titlePolice')}</b> {t('textPolice')}
                      </p>
                      <br />
                      <p className="font18">
                        <b> {t('titlePotentialBuyers')} </b> {t('textPotentialBuyers')}
                      </p>
                    </Text>
                  </SubSection>
                </Section>

                <Section>
                  <SubSection>
                    <Text>
                      <h2>{t('titleStoragePersonalData')}</h2>
                      <br />
                      <p className="font18">
                        {t('textStoragePersonalData')}<br />
                      </p>
                    </Text>
                  </SubSection>
                </Section>

                <Section>
                  <SubSection>
                    <Text>
                      <h2>{t('titleAdvertising')}</h2>
                      <br />
                      <p className="font18">
                        {t('textAdvertising')}
                      </p>

                      <br />
                      <h3>{t('titleNewsletter')}</h3>
                      <br />
                      <p className="font18">
                        {t('textNewsletter1')}
                      </p>

                      <p className="font18">
                        {t('textNewsletter2')}
                      </p>
                      <br />
                      <h3>{t('titleEmailsPushMessages')}</h3>
                      <br />
                      <p className="font18">
                        {t('textEmailsPushMessages1')}
                      </p>

                      <p className="font18">
                        {t('textEmailsPushMessages2')}
                      </p>
                      <br />

                      <h3>{t('titleOnlineAdvertisements')}</h3>
                      <br />
                      <p className="font18">
                        {t('textOnlineAdvertisements')}
                      </p>
                    </Text>
                  </SubSection>
                </Section>

                <Section>
                  <SubSection>
                    <Text>
                      <h2>{t('titleSecurity')}</h2>
                      <br />
                      <p className="font18">
                        {t('textSecurity')}<br />
                      </p>
                    </Text>
                  </SubSection>
                </Section>

                <Section>
                  <SubSection>
                    <Text>
                      <h2>{t('titleDataProtectionDeclaration')}</h2>
                      <br />
                      <p className="font18">
                        {t('textDataProtectionDeclaration')}<br />
                      </p>
                    </Text>
                  </SubSection>
                </Section>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Container = styled.div`
  padding: 60px 0;
  background-color: #fff;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const SubSection = styled.div`
  margin-bottom: 20px;
`;

const Text = styled.div`
  padding: 15px 0 50px 0;
  line-height: 1.7rem;
  @media (max-width: 9700px) {
    padding: 15px 0 50px 0;
    max-width: 100%;
  }
`;
const Title = styled.h1`
  margin-top: 23px;
`;

