import React, { useEffect, useRef } from "react";
import { Descriptions, Modal } from 'antd';
import { useCurrentTrip } from "../hooks/useCurrentTrip";
import { Image } from 'antd';

const TripsViewModal = ({ isOpen, onRequestClose }) => {
  const { currentTrip } = useCurrentTrip();
  const mapContainerRef = useRef(null);

  const handleCancel = () => {
    onRequestClose(false);
  };

  useEffect(() => {
    if (isOpen) {
      // Initialize the Google Map
      const initializeMap = () => {
        const mapOptions = {
          center: {
            lat: currentTrip.startPoint.latitude,
            lng: currentTrip.startPoint.longitude
          },
          zoom: 12
        };

        const mapInstance = new window.google.maps.Map(mapContainerRef.current, mapOptions);

        // Display a route between startPoint and endPoint
        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer({ map: mapInstance });

        const start = new window.google.maps.LatLng(currentTrip.startPoint.latitude, currentTrip.startPoint.longitude);
        const end = new window.google.maps.LatLng(currentTrip.endPoint.latitude, currentTrip.endPoint.longitude);

        const request = {
          origin: start,
          destination: end,
          travelMode: window.google.maps.TravelMode.DRIVING
        };

        directionsService.route(request, (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          }
        });
      }

      // Check if the Google Maps API is available
      if (window.google) {
        initializeMap();
      } else {
        // Load the Google Maps API dynamically
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.addEventListener("load", initializeMap);
        document.body.appendChild(script);
      }
    }
  }, [isOpen, currentTrip]);



  return (
    <Modal width={800} bodyStyle={{ height: 632 }} open={isOpen} onCancel={handleCancel} footer={null}>
      <Descriptions title={`Trip details #${currentTrip?.logicalId}`} bordered style={{borderRadius: "8px 8px 0px 0px"}}>
        <Descriptions.Item span={3} label="Fullname Rider"> 
        
      {currentTrip?.riderId?.gender === "Male" ? "Mr. " : "Mme. "}
        
        
        {currentTrip?.riderId?.fullName}</Descriptions.Item>
        <Descriptions.Item span={3} label="Rider Email">{currentTrip?.riderId?.email}</Descriptions.Item>
        <Descriptions.Item span={3} label="QrCode Vehicule">{currentTrip?.vehiculeId?.qrcode}</Descriptions.Item>
        <Descriptions.Item span={3} label="Vehicule-Dropphoto">
          <Image width={150} src={currentTrip?.dropPhoto.url} />
        </Descriptions.Item>
      </Descriptions>
      <div ref={mapContainerRef} style={{ width: '100%', height: '55%', borderRadius: "0px 0px 8px 8px" }}></div>
    </Modal>
  );
}

export default TripsViewModal;