import React, { useState } from "react";
import { Input, Form, Switch, DatePicker, InputNumber, Select, Modal, DatePickerProps, Tooltip, Button } from 'antd';
import { Coupon, allCouponTypes } from "../../../shared/types";
import { couponGenerator, handleFormChange, layoutEdit, validateMessages } from "../../../shared/Utils";
import { useCreateCouponMutation } from "../../../GraphQL/hooks/useCreateCouponMutation";
import { RedoOutlined } from "@ant-design/icons";
import moment from "moment";
const { TextArea } = Input;

const CouponCreateModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm();
  const [newCoupon, setNewCoupon] = useState<Coupon>();
  const [status, setStatus] = useState<boolean>();
  const [formError, setFormError] = useState(false);
  const mutation = useCreateCouponMutation();

  const handleSwitchChange = (checked: boolean) => {
    setStatus(checked)
  }

  const handleSelectStartingDate = (date: any, dateString: any) => {
    setNewCoupon({ ...newCoupon, startingDate: moment(date).format("DD-MM-YYYY") })
  };

  const handleSelectExpirationDate = (date: any, dateString: any) => {
    setNewCoupon({ ...newCoupon,  expirationDate: moment(date).format("DD-MM-YYYY") })
  };

  const handleCommit = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        mutation({ ...newCoupon, status: status })
          .then(() => {
            onRequestClose(false)
            updateList()
          })
          .finally(() => {
            form.resetFields();
            console.log("New Coupon was successfuly added")
          })
          .catch((err) => console.error(err));
      }
    } catch (errorInfo) {
      console.log('Failed: Form is empty');
    }
  };

  const handleCancel = () => {
    onRequestClose(false);
  };

  const handleCouponGenerate = () => {
    let couponCode = couponGenerator();
    form.setFieldValue("code", couponCode);
  }


  return (
    <Modal title="Add coupon" open={isOpen} onCancel={handleCancel} footer={[
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="create" type="primary" onClick={handleCommit} disabled={formError}>
        Create
      </Button>,
    ]}>

      <Form<Coupon> {...layoutEdit} form={form} name="nest-messages" autoComplete="off" onFieldsChange={() => { setFormError(handleFormChange(form)) }} onValuesChange={(changedValues, allValues) => setNewCoupon(allValues)} validateMessages={validateMessages}>
        <Form.Item name="type" label="Coupon Type" rules={[{ required: true }]}>
          <Select placeholder="Select coupon type" style={{ width: '50%' }}>
            {(allCouponTypes ?? []).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ required: true }]}>
          <TextArea rows={2} style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item name="amount" label="Amount" rules={[{ required: allCouponTypes.slice(0, 2).includes(newCoupon?.type) }]}>
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item name="reduction" label="Reduction" rules={[{ required: newCoupon?.type === allCouponTypes[2] }]}>
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item name="code" label="Code" rules={[{ required: true }]}>
          <Input style={{ width: '50%' }} addonAfter={
            <Tooltip title="Generate new Coupon" color={'#108ee9'}>
              <RedoOutlined style={{ color: '#1677ff' }} onClick={handleCouponGenerate} />
            </Tooltip>
          } />

        </Form.Item>
        <Form.Item name="startingDate" label="Start Date" rules={[{ required: true }]}>
          <DatePicker format={"YYYY-MM-DD"} onChange={handleSelectStartingDate} />
        </Form.Item>
        <Form.Item name="expirationDate" label="Expire Date" rules={[{ required: true }]}>
          <DatePicker format={"YYYY-MM-DD"} onChange={handleSelectExpirationDate} />
        </Form.Item>
        <Form.Item name="status" label="Status" valuePropName="checked" rules={[{ required: false }]}>
          <Switch checkedChildren="Active"
            unCheckedChildren="Inactive"
            checked={false}
            onClick={(checked: boolean) => handleSwitchChange(checked)} />
        </Form.Item>
      </Form>
    </Modal>

  );
}
export default CouponCreateModal;