import { useQuery } from '@apollo/client';
import { CITRIN_GET_PASSES, GetPassesRequest, GetPassesResponse } from '..';

export const useGetPassesQuery = () => {
    const { data, previousData, ...rest } = useQuery<GetPassesResponse, GetPassesRequest>(CITRIN_GET_PASSES
        // fetchPolicy: 'cache-and-network',
        // onCompleted: (data) => console.log('[MGT] Pass', data),
    );

    return { ...rest, previousData: previousData?.CITRIN_MGT_getPasses, data: data?.CITRIN_MGT_getPasses ?? [] };
};