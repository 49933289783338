/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import './Layout.css';
import {
  AimOutlined,
  AlertOutlined,
  CarOutlined,
  DollarCircleOutlined,
  GiftOutlined,
  LoginOutlined,
  NodeIndexOutlined,
  PieChartOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShopOutlined,
  SoundOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { MenuProps, Row, Col, Dropdown, Avatar, Badge, Image } from 'antd';
import { Breadcrumb, Layout, Menu } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useUserState } from '../Hooks/useUserState';
import { cleanAllCookies } from '../shared/Utils';
import useLogout from '../Hooks/useLogout';
import jwt_decode from "jwt-decode"
import { useGetSettingQuery } from '../GraphQL/hooks/useGetSettingQuery';

const { Header, Content, Footer, Sider } = Layout;

const layout = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [initRoot, setDefaultRoot] = useState<string>('/')
    const { ...state  } = useUserState();
    const location = useLocation();

    const decoded: {_id: string, sectionRoles: [string]}  = state?.accessToken
    ? jwt_decode(state?.accessToken)
    : undefined;
    const { data, refetch, loading} = useGetSettingQuery(decoded?._id);

    const navigate = useNavigate();
    
    const logout = useLogout();

    const handelSignOut = async () => {
      await logout()
      localStorage.clear();
      cleanAllCookies()
      navigate('/login')
    } 
    
    
    const items: MenuProps['items'] = [
      {
        key: '0',
        label:(<p onClick={() =>navigate('/settings')}>Welcome <strong>{data?.fullname}</strong></p>),
        icon: <UserOutlined />
      },
      {
        key: '1',
        label: (
          <a rel="noopener noreferrer" onClick={() => handelSignOut()}>
            Log out
          </a>
        ),
        icon: <LoginOutlined />
      }
    ];

    const menuItems = []

    if (decoded?.sectionRoles?.indexOf('Reports') > -1)   menuItems.push({ key: 'reports', icon: <PieChartOutlined />, label: <a onClick={() => handleMenuItemClick('reports')}>Reports</a> });
    if (decoded?.sectionRoles?.indexOf('Map') > -1)       menuItems.push({ key: 'map', icon: <AimOutlined />, label: <a onClick={() => handleMenuItemClick('map')}>Map areas</a> });
    if (decoded?.sectionRoles?.indexOf('Fleet') > -1)     menuItems.push({ key: 'fleet', icon: <CarOutlined />, label: <a onClick={() => handleMenuItemClick('fleet')}>Fleet</a> });
    if (decoded?.sectionRoles?.indexOf('Riders') > -1)    menuItems.push({ key: 'riders', icon: <TeamOutlined />, label: <a onClick={() => handleMenuItemClick('riders')}>Riders</a> });
    if (decoded?.sectionRoles?.indexOf('Trips') > -1)     menuItems.push({ key: 'trips', icon: <NodeIndexOutlined />, label: <a onClick={() => handleMenuItemClick('trips')}>Trips</a> });
    if (decoded?.sectionRoles?.indexOf('Promos') > -1)    menuItems.push({ key: 'promos', icon: <GiftOutlined />, label: <a onClick={() => handleMenuItemClick('promos')}>Promo/Coupon</a> });
    if (decoded?.sectionRoles?.indexOf('Campaign') > -1)  menuItems.push({ key: 'campaign', icon: <SoundOutlined />, label: <a onClick={() => handleMenuItemClick('campaign')}>Advt. campaign</a> });
    if (decoded?.sectionRoles?.indexOf('Pricing') > -1)   menuItems.push({ key: 'pricing', icon: <DollarCircleOutlined />, label: <a onClick={() => handleMenuItemClick('pricing')}>Ride pricing</a> });
    if (decoded?.sectionRoles?.indexOf('Passes') > -1)    menuItems.push({ key: 'passes', icon: <LoginOutlined />, label: <a onClick={() => handleMenuItemClick('passes')}>Passes</a> });
    if (decoded?.sectionRoles?.indexOf('Operators') > -1) menuItems.push({ key: 'operators', icon: <ShopOutlined />, label: <a onClick={() => handleMenuItemClick('operators')}>Operators</a> });
                                                          menuItems.push({ key: 'vehiculeProblem', icon: <AlertOutlined />, label: <a onClick={() => handleMenuItemClick('vehiculeProblem')}>Fleet Problems</a> });
                                                          menuItems.push({ key: 'settings', icon: <SettingOutlined />, label: <a onClick={() => handleMenuItemClick('settings')}>Settings</a> });
                                                          menuItems.push({ key: 'support', icon: <QuestionCircleOutlined />, label: <a onClick={() => handleMenuItemClick('support')}>Support</a> });


    const handleMenuItemClick = (keyRoot: string) => {
      setDefaultRoot(keyRoot)
      navigate(`/${keyRoot}`)
    };

    const handelDefaultRoot = () => {
      let initRoot;
      if(location?.pathname == '/'){
        initRoot = menuItems[0].key;
      } else {
        initRoot = location.pathname?.slice(1);
      }
      setDefaultRoot(initRoot)
      navigate(`/${initRoot}`);
    }

    useEffect(() => {
      handelDefaultRoot();
    }, []);
    
    return (
        <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)} className='divToHide'>
        <div className="logo">
          <Avatar src={"./assets/citrin_logo.png"}/>
          <span className="citrin-text">CITRIN</span>
          <span className="insights-text">Insights</span>
        </div>
        <Menu theme="dark" mode="inline" selectedKeys={[initRoot]} items={menuItems}></Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background divToHide" style={{ padding: 0 }} >
            <Row>
              <Col span={12}></Col>
              <Col span={1} offset={11}>
                <Dropdown menu={{ items }} trigger={['click']}>
                  <a onClick={e => e.preventDefault()}>
                  <Badge count={1}>
                        { data?.operator?.logo?.url ?
                          <Avatar size={32} src={<img src={data?.operator?.logo?.url} />} />
                          :
                          <Avatar size={32} icon={<UserOutlined />} />
                        }
                      </Badge>
                  </a>
                </Dropdown>
               </Col>
            </Row>
          </Header>
          <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }} className='divToHide'></Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Outlet />
            </div>
          </Content>
          <Footer className='divToHide' style={{ textAlign: 'center' }}>Citrin Dashboard ©2023 Created by <a href="https://www.stasia-digtal.com" target="_new">STASIA Digital</a></Footer>
        </Layout>
      </Layout>         
    );

};

export default layout;
