import { useMutation } from '@apollo/client';
import { ContactInput } from '../../shared/types';
import { CITRIN_CREATE_CONTACT, CreateContactRequest, CreateContactResponse } from '..';

type CreateMutationFunction = (request: ContactInput) => Promise<String>;
export const useContactWelcomeMutation = (): CreateMutationFunction => {

    const [mutation, { client }] = useMutation<CreateContactResponse, CreateContactRequest>(CITRIN_CREATE_CONTACT);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const ContactRequestResponse = data?.CITRIN_MGT_contact;
        return ContactRequestResponse;
    };
};
