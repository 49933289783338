import { useMutation } from '@apollo/client';
import { keys } from 'lodash';
import { Campaign } from '../../shared/types';
import { CITRIN_CREATE_CAMPAIGN, CreateCampaignRequest, CreateCampaignResponse } from '..';

type CreateMutationFunction = (request: Campaign) => Promise<Campaign>;
export const useCreateCampaignMutation = (): CreateMutationFunction => {

    const [mutation, { client }] = useMutation<CreateCampaignResponse, CreateCampaignRequest>(CITRIN_CREATE_CAMPAIGN);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const created = data?.CITRIN_MGT_createCampaign;
        return created;
    };
};
