import { Col, Row } from 'antd';
import React from "react";
import Title from "antd/es/typography/Title";
import RiderTable from './Components/RiderTable';
import { RiderInfoProvider } from './hooks/useCurrentRider';

const RidersView = () => {

    return (
        <>
            <Row style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Col span={12}><Title level={3}>Riders</Title></Col>
            </Row>
            <div style={{ flex: '1 1 auto' }}>
                <RiderInfoProvider>
                    <RiderTable />
                </RiderInfoProvider>
            </div>
        </>
    )
};


export default RidersView;

