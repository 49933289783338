import { useMutation } from '@apollo/client';
import { LoginInput, TokenResponse } from '../../shared/types';
import { CITRIN_LOGIN_USER, CreateLoginUserResponse, CreateLoginUserRequest } from '..';

type CreateMutationFunction = (request: LoginInput) => Promise<TokenResponse>;
export const useLoginUserMutation = (): CreateMutationFunction => {

    const [mutation, { client }] = useMutation<CreateLoginUserResponse, CreateLoginUserRequest>(CITRIN_LOGIN_USER);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const tokenResponse = data?.CITRIN_MGT_loginUser;
        return tokenResponse;
    };
};
