import { Button, Col, Pagination, Row, Space, Spin, Table, Tag } from 'antd';
import React, { useState } from "react";
import { DeleteOutlined, PlusCircleOutlined, QuestionCircleOutlined, } from '@ant-design/icons';
import { useSortedSettingsQuery } from '../hooks/useSortedSettingQuery';
import PopConfirm from '../../../shared/Components/PopConfirm';
import { Setting } from '../../../shared/types';
import { DeleteSettingResponse, DeleteSettingRequest, CITRIN_DELETE_SETTING } from '../../../GraphQL';
import { useMutation } from '@apollo/client';
import SettingCreateModal from './SettingCreateModal';

const SettingsTable = () => {
    const [isCreateModalVisible, setCreateModalVisible] = useState(false);
    const { sortedSettings: settings, refetch, loading, ...pagination } = useSortedSettingsQuery();
    const [deleteMutation] = useMutation<DeleteSettingResponse, DeleteSettingRequest>(CITRIN_DELETE_SETTING);
    const [isLoading, setIsLoading] = useState(false);

    const handleRemoveSetting = (setting: Setting) => {
            setIsLoading(true)
            deleteMutation({ variables: { id: setting._id } })
                .then(() => {
                    refetch();
                })
                .catch((err) => console.error(err))
                .finally(() => {
                    setIsLoading(false)
                });
    };


    const handleCreateSetting = () => { setCreateModalVisible(true); };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'logicalId',
            key: 'logicalId',
            sorter: (a, b) => a.logicalId - b.logicalId,
            width: 80,
        },
        {
            title: 'Fullname',
            dataIndex: 'fullname',
            key: 'fullname',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Section roles',
            dataIndex: 'sectionRoles',
            key: 'sectionRoles',
            render: (text, record: Setting) => { return record.sectionRoles.map((tag) => <Tag key={tag}>{tag}</Tag>) }
        },
        {
            title: "Action",
            key: "action",
            render: (_, setting) =>
                <Space size="middle">
                    <PopConfirm
                        title={"Delete this Setting"}
                        description={"Are you sure to delete this user Setting?"}
                        onConfirmTask={() => handleRemoveSetting(setting)}
                        popIcon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        actionIcon={<DeleteOutlined style={{ fontSize: '16px', color: 'red' }} />}
                    />
                </Space>
        },

    ];

    const Paginator = () => (
        <Row justify={'center'}>
            <Col>
                <Pagination
                    size="default"
                    total={pagination.totalCount}
                    pageSize={pagination.pageSize}
                    current={pagination.page}
                    showTotal={(total) => `Total Settings ${total}`}
                    onChange={(newPage) => pagination.setPage(newPage)}
                    showQuickJumper={true}
                    showSizeChanger={false}
                    showLessItems={false}
                />
            </Col>
        </Row>
    );


    return (
        <>
            {isCreateModalVisible && <SettingCreateModal updateList={refetch} isOpen={isCreateModalVisible} onRequestClose={setCreateModalVisible} />}

            <Row justify={'end'}>
                <Button type="primary"
                    shape="round"
                    icon={<PlusCircleOutlined />}
                    size={'middle'}
                    onClick={handleCreateSetting}>Add</Button>
            </Row>

            <div style={{ flex: '1 1 auto' }}>
                <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
                    <Col xs={24}>
                        <Spin spinning={isLoading || loading}>
                            <Table
                                dataSource={settings}
                                columns={columns}
                                pagination={false}
                                rowKey={() => Math.random().toString()}
                                scroll={{ x: 1500, y: 400 }}>
                            </Table>
                        </Spin>
                    </Col>
                </Row>
                <Paginator />
            </div>
        </>
    )
};



export default SettingsTable;