import { useMutation } from '@apollo/client';
import { Operator } from '../../shared/types';
import { CITRIN_UPDATE_OPERATOR, UpdateOperatorRequest, UpdateOperatorResponse,  } from '..';
 
type UpdateMutationFunction = (request: Operator, file?: any) => Promise<Operator>;
export const useUpdateOperatorMutation = (): { mutation: UpdateMutationFunction; loading: boolean } => {

    const [mutation, { loading }] = useMutation<UpdateOperatorResponse, UpdateOperatorRequest>(CITRIN_UPDATE_OPERATOR);

    const promiseMutation = async (request, file) => {
        const result = await mutation({
            variables: { request, file }
        });
        return result.data.CITRIN_MGT_updateOperator as Operator;
    };
    return {
        mutation: promiseMutation,
        loading,
    };

};
