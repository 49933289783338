import { useEffect, useMemo } from "react";
import { Pass } from "../../../shared/types";
import { chunk, isEmpty } from 'lodash';
import { usePageState } from "../../../Hooks/usePageState";
import { useOverviewLinkState } from "../linkstate/campaignOverview.linkstate";
import { useGetCampaignsQuery } from "../../../GraphQL/hooks/useGetCampaignsQuery";

const pageSize = 10;
const getPageCount = (passes: Pass[]) => Math.ceil(passes?.length / pageSize);
const getCurrentPage = (requestedPage: number, passes: Pass[]) => {
    const pageCount = getPageCount(passes);
    return requestedPage < 1 ? 1 : requestedPage > pageCount ? pageCount : requestedPage;
};

export const useSortedCampaignQuery = () => {
    const { setLoading } = usePageState();
    const [state, setState] = useOverviewLinkState();
    const { page } = state;

    const { data: unsortedCampaigns, refetch, loading } = useGetCampaignsQuery();
    useEffect(() => setLoading(loading), [loading]);
    
    const setPage = (newPage: number) => {
        const pageCount = getPageCount(unsortedCampaigns);
        // console.log('new page', newPage, pageCount);
        const page = newPage < 1 ? 1 : newPage > pageCount ? pageCount : newPage;
        setState({ ...state, page });
    };

    const { pageCount, totalCount, sortedCampaigns } = useMemo(() => {
        const totalCount = unsortedCampaigns?.length ?? 0;
        const pageCount = getPageCount(unsortedCampaigns);
        const sortedCampaigns = chunk(unsortedCampaigns, pageSize);
        
        return { totalCount, pageCount, sortedCampaigns };
    }, [unsortedCampaigns]);

    const currentPage = getCurrentPage(page, unsortedCampaigns);
    
    return {
        sortedCampaigns: sortedCampaigns?.length > 0 ? sortedCampaigns[currentPage - 1] : [],
        refetch: () => refetch(),
        page: page ?? 1,
        pageCount,
        totalCount,
        pageSize,
        setPage,
        loading
    };
};
