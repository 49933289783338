import React, { useState } from "react";
import { Button, Card, Form, Input, Result, Row, notification } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import "./styles.css";
import { useNavigate } from "react-router-dom"
import { useResetPasswordRequestMutation } from "../../../GraphQL/hooks/useResetPasswordRequestMutation";
import Meta from "antd/es/card/Meta";

const ForgetPasswordView = () => {
  const mutation = useResetPasswordRequestMutation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [requestStatus, setRequestStatus] = useState(false);

  const onFinish = async () => {
    const values = await form.validateFields();

    if (values) {
      mutation({ email: values.email }).then((result: String) => {
        setRequestStatus(true)
      }).catch((err) => {
        notification.error({
          message: "Reset request failed",
          description: "Please verify your email and try later!",
        });
      });
    }
  };

  return (
    <div style={{ background: '#f0f2f5', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Card style={{ width: '500px', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      {requestStatus ? (
        <Result
          status="success"
          title="Email was Successfully sent!"
          subTitle="Please check your email-box, you will receive a reset link!"
          extra={<Button type="primary" onClick={() => navigate('/login')}>Go to login</Button>}
        />
      ) : (
        <div>
          <div style={{ marginBottom: '20px', textAlign: 'center' }}>
            <Meta
              title={<span style={{ fontSize: '28px' }}>Forget your password!</span>}
              description="Please enter the Email you use to sign in to CITRIN Insights!"
            />
          </div>
          <Form form={form} name="normal_login" className="login-form" style={{ textAlign: 'center', width: '100%' }} autoComplete="off" onFinish={onFinish}>
            <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
              <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Enter your Email" autoComplete="off"/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Send password reset link
              </Button>
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
              <a className="login-form" onClick={() => navigate('/login')}>
                Back to Sign in
              </a>
            </Form.Item>
          </Form>
        </div>
      )}
    </Card>
  </div>
  
  );
};

export default ForgetPasswordView;
