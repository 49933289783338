import { Button, Col, Pagination, Row, Space, Spin, Table } from 'antd';
import React, { useState } from "react";
import { CheckCircleOutlined, CopyOutlined, DeleteOutlined, PauseCircleOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Coupon } from '../../../shared/types';
import { CouponFilter } from './CouponFilter';
import { useSortedCouponQuery } from '../hooks/useSortedCouponQuery';
import PopConfirm from '../../../shared/Components/PopConfirm';
import { useMutation } from '@apollo/client';
import { CITRIN_DELETE_COUPON, DeleteCouponRequest, DeleteCouponResponse } from '../../../GraphQL';
import CouponCreateModal from './CouponCreateModal';
import moment from 'moment';

const CouponTable = () => {
  const [isCreateModalVisible, setCreateModalVisible] = useState(false);

  const { sortedCoupons: coupons, refetch, loading, ...pagination } = useSortedCouponQuery();
  const [deleteMutation] = useMutation<DeleteCouponResponse, DeleteCouponRequest>(CITRIN_DELETE_COUPON);

  const handleRemoveCoupon = (coupon: Coupon) => {
    setIsLoading(true);
    deleteMutation({ variables: { id: coupon._id } })
      .then(() => {
        refetch();
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCreateCoupon = () => { setCreateModalVisible(true); };


  const copyCodeToClipBoard = async (code: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(code);
    } else {
      return document.execCommand('copy', true, code);
    }
  }

  const [IsLoading, setIsLoading] = useState(false);

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Reduction',
      dataIndex: 'reduction',
      key: 'reduction',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => { return <span style={{ cursor: 'pointer' }}><CopyOutlined onClick={() => copyCodeToClipBoard(record.code)} /> {text} </span> }
    },
    {
      title: 'Starting date',
      dataIndex: 'startingDate',
      key: 'startingDate',
      sorter: (a, b) => a.startingDate - b.startingDate,
      render: (text, record) => { return moment(Number(record.startingDate)).format("DD-MM-YYYY"); }
    },
    {
      title: 'Expiration date',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      sorter: (a, b) => a.expirationDate - b.expirationDate,
      render: (text, record) => { return moment(Number(record.expirationDate)).format("DD-MM-YYYY"); }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record: Coupon) => { return record.status ? <CheckCircleOutlined title='Active' style={{ fontSize: '16px', color: 'green' }} /> : <PauseCircleOutlined title='Inactive' style={{ fontSize: '16px', color: 'red' }} /> }
    },
    {
      title: 'Coupon type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: "Action",
      key: "action",
      render: (_, coupon) =>
        <Space size="middle">

          <PopConfirm
            title={"Delete this coupon"}
            description={"Are you sure to delete this coupon?"}
            onConfirmTask={() => handleRemoveCoupon(coupon)}
            popIcon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            actionIcon={<DeleteOutlined style={{ fontSize: '16px', color: 'red' }} />}
          />
        </Space>
    },

  ];

  const Paginator = () => (
    <Row justify={'center'}>
      <Col>
        <Pagination
          size="default"
          total={pagination.totalCount}
          pageSize={pagination.pageSize}
          current={pagination.page}
          showTotal={(total) => `Total Coupons/Promos ${total}`}
          onChange={(newPage) => pagination.setPage(newPage)}
          showQuickJumper={true}
          showSizeChanger={false}
          showLessItems={false}
        />
      </Col>
    </Row>
  );

  return (
    <>

      <CouponCreateModal updateList={refetch}
        isOpen={isCreateModalVisible}
        onRequestClose={setCreateModalVisible} />

      <Row justify={'end'}>
        <Button type="primary"
          shape="round"
          icon={<PlusCircleOutlined />}
          size={'middle'}
          onClick={handleCreateCoupon}>Add</Button>
      </Row>

      <Row gutter={[16, 6]} style={{ paddingBottom: 10, paddingTop: 20 }}>
        <CouponFilter />
      </Row>
      <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
        <Col xs={24}>
          <Spin spinning={ IsLoading || loading}>
            <Table
              dataSource={coupons}
              columns={columns}
              pagination={false}
              rowKey={() => Math.random().toString()}
              scroll={{ x: 1500, y: 400 }}>
            </Table>
          </Spin>
        </Col>
      </Row>
      <Paginator />
    </>
  )
};

export default CouponTable;