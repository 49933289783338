import React, { useState } from "react";
import { Input, Form, InputNumber, Select, Modal, Button } from 'antd';
import { Pricing, allLevelTypes, allVehiculeTypes } from "../../../shared/types";
import { handleFormChange, layoutEdit, validateMessages } from "../../../shared/Utils";
import { useCreatePricingMutation } from "../../../GraphQL/hooks/useCreatePricingMutation";

const PricingCreateModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm();
  const [formError, setFormError] = useState(false);

  const mutation = useCreatePricingMutation();

  const handleCommit = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        mutation({ ...values })
          .then(() => {
            onRequestClose(false)
            updateList()
          })
          .finally(() => {
            form.resetFields();
          })
          .catch((err) => console.error(err));
      }
    } catch (errorInfo) {
      console.log('Failed: Form is empty');
    }
  };

  const handleCancel = () => {
    onRequestClose(false);
  };

  return (
    <Modal forceRender title="Add Price" open={isOpen} visible={isOpen} onCancel={handleCancel} footer={[
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="create" type="primary" onClick={handleCommit} disabled={formError}>
        Create
      </Button>,
    ]}>
      <Form<Pricing> {...layoutEdit} form={form} autoComplete="off" name="nest-messages" onFieldsChange={() => { setFormError(handleFormChange(form)) }} validateMessages={validateMessages}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input type="textarea" placeholder="Name of the price" style={{ width: '60%' }} />
        </Form.Item>
        <Form.Item name="note" label="Note" rules={[{ required: true }]}>
          <Input type="textarea" style={{ width: '60%' }} />
        </Form.Item>
        <Form.Item name="minutePrice" label="Minute price" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="unlockPrice" label="Unlock price" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="vehiculeType" label="Vehicule type" rules={[{ required: true }]}>
          <Select placeholder="Select type of vehicule" style={{ width: '60%' }}>
            {(allVehiculeTypes ?? []).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="level" label="Level" rules={[{ required: true }]}>
          <Select placeholder="Select type of level" style={{ width: '60%' }}>
            {(allLevelTypes ?? []).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>

  );

}

export default PricingCreateModal;