import { useMutation } from '@apollo/client';
import { Pricing } from '../../shared/types';
import { CITRIN_UPDATE_PRICING, UpdatePricingRequest, UpdatePricingResponse } from '..';

type UpdateMutationFunction = (request: Pricing) => Promise<Pricing>;
export const useUpdatePricingMutation = (): UpdateMutationFunction => {

    const [mutation, { client }] = useMutation<UpdatePricingResponse, UpdatePricingRequest>(CITRIN_UPDATE_PRICING);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const updated = data?.CITRIN_MGT_updatePricing;
     //   updateGetPricingCache(client, updated, ...(keys(request) as any));
        return updated;
    };
};
