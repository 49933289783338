import { Col, Row } from 'antd';
import React from "react";
import Title from "antd/es/typography/Title";
import PassTable from './Components/PassTable';
import { PassInfoProvider } from './hooks/useCurrentPass';

const PassView = () => {

  return (
    <>
      <>
        <Row style={{ paddingBottom: 10, paddingTop: 10 }}>
          <Col span={12}><Title level={3}>Passes</Title></Col>
        </Row>

        <div style={{ flex: '1 1 auto' }}>
          <PassInfoProvider>
            <PassTable />
          </PassInfoProvider>
        </div>
      </>
    </>
  )
};

export default PassView;