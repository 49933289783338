import { Tabs } from 'antd';
import React, { useEffect, useState } from "react";
import BusinessData from '../Components/BusinessData';
import AccountData from '../Components/AccountData';
import RolesManagement from '../Components/RolesManagement';
import { AlignLeftOutlined, ShopOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { omit } from 'lodash';
import { useCurrentSetting } from '../hooks/useCurrentSetting';
import { Operator } from '../../../shared/types';
import CitiesTable from './CitiesTable';


const SettingsTabs = ({ settingsData }) => {
    const { currentSetting, setCurrentSetting } = useCurrentSetting();
    const [currentTab] = useState('0')

    const accountTabInputs = omit(currentSetting, ['operator', 'password'])
    const operatorTabInputs: Operator = currentSetting?.operator;

    useEffect(() => {
        setCurrentSetting(settingsData);
    }, [])

    const items = [
        {
            label: <span><UserOutlined />Account</span>,
            children: <AccountData accountData={accountTabInputs} />,
            key: '0',
            disabled: false
        },
        {
            label: <span><ShopOutlined />Business</span>,
            children: <BusinessData businessData={operatorTabInputs} />,
            key: '1',
            disabled: !currentSetting?.operator?.logicalId
        },
        {
            label: <span><UsergroupAddOutlined />Roles Management</span>,
            children: <RolesManagement />,
            key: '2',
            disabled: !currentSetting?.sectionRoles?.includes("Operators")
        },
        {
            label: <span><AlignLeftOutlined />List of Cities</span>,
            children: <CitiesTable />,
            key: '3',
        }
    ];

    return (
        <>
            <Tabs defaultActiveKey={currentTab} items={items} />
        </>

    )
};


export default SettingsTabs;