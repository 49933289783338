import React, { useState } from "react";
import { Input, Form, Switch, DatePicker, InputNumber, Select, Modal, Slider, Space, DatePickerProps, Tooltip, Button } from 'antd';
import { VehiculeInput, allPowerTypes, allVehiculeTypes } from "../../../shared/types";
import { handleFormChange, layoutEdit, validateMessages } from "../../../shared/Utils";
import { useGetAllCityQuery } from "../../../GraphQL/hooks/useGetAllCityQuery";
import { useCreateVehiculeMutation } from "../../../GraphQL/hooks/useCreateVehiculeMutation";
import { formatter } from "../shared";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useGetAllOperatorsQuery } from "../../../GraphQL/hooks/useGetAllOperatorsQuery";
import { useGetPricingsQuery } from "../../../GraphQL/hooks/useGetPricingsQuery";
import moment from "moment";

const FleetCreateModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm();
  const [newVehicule, setNewVehicule] = useState<VehiculeInput>();
  const [status, setStatus] = useState<boolean>(false);
  const [formError, setFormError] = useState(false);

  const { data: operators } = useGetAllOperatorsQuery();

  const { data: pricings } = useGetPricingsQuery();

  const { data: cities } = useGetAllCityQuery();
  
  const mutation = useCreateVehiculeMutation();

  const handleSwitchChange = (checked: boolean) => {
    setStatus(checked)
  }

  const handleSelectDate = (date: any, dateString: any) => {
    setNewVehicule(prev => ({...prev, operatedDate: moment(date).format("DD-MM-YYYY")  }))
  };


  const handleCommit = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        mutation({
          ...newVehicule,
          location: {
            type: "Point",
            coordinates: {
              latitude: values.location.latitude,
              longitude: values.location.longitude
            }
          },
          status: status
        }).then(() => {
          onRequestClose(false);
          updateList();
        }).finally(() => {
          form.resetFields();
          console.log("New Fleet was successfully added");
        }).catch((err) => console.error(err));
      }
    } catch (errorInfo) {
      console.log('Failed: Form is empty');
    }
  };

  const handleCancel = () => {
    onRequestClose(false);
  };

  return (
    <Modal title="Add fleet" visible={isOpen} onCancel={handleCancel} footer={[
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="create" type="primary" onClick={handleCommit} disabled={formError}>
        Create
      </Button>,
    ]}>
      <Form<VehiculeInput> {...layoutEdit} form={form} autoComplete="off" name="nest-messages" onFieldsChange={() => { setFormError(handleFormChange(form)) }} onValuesChange={(changedValues, allValues) => setNewVehicule(allValues)} validateMessages={validateMessages}>
        <Form.Item name="vehiculeType" label="Vehicule type" rules={[{ required: true }]} >
          <Select placeholder="Select type of vehicule" style={{ width: '60%' }} >
            {(allVehiculeTypes ?? []).map((vehiculeType) => (
              <Select.Option key={vehiculeType} value={vehiculeType}>
                {vehiculeType}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="qrcode" label="Device ID" rules={[{ required: true }]}>
          <Input type="number" style={{ width: '60%', marginRight: '10px' }} suffix={
            <Tooltip title="This is the device ID relative to the vehicle" placement="rightTop">
              <InfoCircleOutlined style={{ color: '#1677ff', fontSize: '20px' }} />
            </Tooltip>
          } />
        </Form.Item>
        <Form.Item name="mileage" label="Mileage" rules={[{ required: true }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="status" label="Status" valuePropName="checked" rules={[{ required: false }]}>
          <Switch checkedChildren="Active"
            unCheckedChildren="Inactive"
            checked={false}
            onClick={(checked: boolean) => handleSwitchChange(checked)} />
        </Form.Item>
        <Form.Item name="powerType" label="Power Type" rules={[{ required: true }]} >
          <Select placeholder="Select power type" style={{ width: '60%' }} >
            {(allPowerTypes ?? []).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="batteryLevel" label="Battery Level" rules={[{ required: true }]}>
          <Slider tooltip={{ formatter }} />
        </Form.Item>
        <Form.Item name="city" label="City" rules={[{ required: true }]} >
          <Select placeholder="Select City" style={{ width: '60%' }} >
            {(cities ?? []).map((city) => (
              <Select.Option key={city._id} value={city.cityName}>
                {city.cityName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="operatedDate" label="Operated on" rules={[{ required: true }]}>
          <DatePicker format={"YYYY-MM-DD"} onChange={handleSelectDate} />
        </Form.Item>
        <Form.Item name="operatorId" label="Operated by" rules={[{ required: true }]} >
          <Select placeholder="Select Operator" style={{ width: '60%' }} >
            {(operators ?? []).map((operator) => (
              <Select.Option key={operator._id} value={operator._id} >
                {operator.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Location" rules={[{ required: false }]}>
          <Space.Compact>
            <Form.Item name={["location", "latitude"]} noStyle style={{ width: '50%' }}>
              <InputNumber placeholder="Latitude" min={-90} max={90} step={0.0001} />
            </Form.Item>
            <Form.Item name={["location", "longitude"]} noStyle style={{ width: '50%' }}>
              <InputNumber placeholder="Longitude" min={-90} max={90} step={0.0001} />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Form.Item name="pricingId" label="Vehicule price" rules={[{ required: true }]} >
          <Select placeholder="Select vehicule price" style={{ width: '80%' }} >
            {(pricings ?? []).map((pricing) => (
              <Select.Option key={pricing._id} value={pricing._id} >
                {pricing.vehiculeType}-Minute Price: {pricing.minutePrice} Unlock Price: {pricing.unlockPrice}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

    </Modal>
  );
}

export default FleetCreateModal;