import React, { useEffect, useState } from "react";
import { Input, Form, Button } from 'antd';
import { handleFormChange, layout, validateMessages } from "../../../shared/Utils";
import { Setting } from "../../../shared/types";
import { useUpdateSettingMutation } from "../../../GraphQL/hooks/useUpdateSettingMutation";

const AccountData = ({ accountData }) => {
  const [form] = Form.useForm();
  const [formError, setFormError] = useState(false);
  const mutation = useUpdateSettingMutation();

  useEffect(() => {
    form.setFieldsValue(accountData)
  }, [form, accountData])

  const onFinish = async (values) => {
    mutation({
      _id: accountData._id, ...values,
    })
      .catch((err) => console.error(err));
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Form<Setting> {...layout} form={form} name="AccountData" autoComplete="off" onFieldsChange={() => { setFormError(handleFormChange(form)) }} onFinish={onFinish} validateMessages={validateMessages}>
      <Form.Item name="fullname" label="Full name" rules={[{ required: true }]}>
        <Input type="text" placeholder={'Your full name'} style={{ width: '60%' }} />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[{ required: true }]}>
        <Input type="email" placeholder={'example@gmail.com'} style={{ width: '60%' }} />
      </Form.Item>
      <Form.Item name="password" label="New Password" rules={[{ required: false }]}>
        <Input.Password autoComplete="new-password" type="password" placeholder={'Enter your new password'} style={{ width: '60%' }} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" style={{ marginRight: "8px" }} htmlType="submit" disabled={formError}>
          Save
        </Button>
        <Button htmlType="button" onClick={onReset}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );

};

export default AccountData;

