import { Col, Row } from 'antd';
import React from "react";
import Title from "antd/es/typography/Title";
import PricingTable from './Components/PricingTable';
import { PricingInfoProvider } from './hooks/useCurrentPricing';

const PricingView = () => {

    return (
        <>
            <>
                <Row style={{ paddingBottom: 10, paddingTop: 10 }}>
                    <Col span={12}><Title level={3}>Pricing</Title></Col>
                </Row>

                <div style={{ flex: '1 1 auto' }}>
                    <PricingInfoProvider>
                        <PricingTable />
                    </PricingInfoProvider>
                </div>
            </>
        </>
    )
};

export default PricingView;