import { useMutation } from '@apollo/client';
import { Operator, OperatorInput} from '../../shared/types';
import {CITRIN_CREATE_OPERATOR, CreateOperatorRequest, CreateOperatorResponse, } from '..';

type CreateMutationFunction = (request: Partial<OperatorInput>, file: any) => Promise<Operator>;
export const useCreateOperatorMutation = (): { mutation: CreateMutationFunction; loading: boolean } => {
    const [mutation, { loading }] = useMutation<CreateOperatorResponse, CreateOperatorRequest>(CITRIN_CREATE_OPERATOR);
    
    const promiseMutation = async (request, file) => {
        const result = await mutation({
            variables: { request, file }
        });
        return result.data.CITRIN_MGT_createOperator as Operator;
    };
    return {
        mutation: promiseMutation,
        loading,
    };
    
    
    /*return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const created = data?.CITRIN_MGT_createOperator;
     //   updateGetOperatorCache(client, updated, ...(keys(request) as any));
        return created;
    };*/
};
