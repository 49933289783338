import React, { useState } from "react";
import { Input, Form, Row, Col, Checkbox, Modal, Button } from 'antd';
import { Setting } from "../../../shared/types";
import { handleFormChange, validateMessages } from "../../../shared/Utils";
import { useCreateSettingMutation } from "../../../GraphQL/hooks/useCreateSettingMutation";

const SettingCreateModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm();
  const [formError, setFormError] = useState(false);
  const mutation = useCreateSettingMutation();

  const handleCommit = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        mutation({ ...values })
          .then(() => {
            onRequestClose(false)
            updateList()
          })
          .finally(() => {
            form.resetFields();
            console.log("New User Setting was successfuly added")
          })
          .catch((err) => console.error(err));
      }
    } catch (errorInfo) {
      console.log('Failed: Form is empty');
    }
  };

  const handleCancel = () => {
    onRequestClose(false);
    form.resetFields();
  };


  return (
    <Modal title="Add user setting" width={750} open={isOpen} visible={isOpen} onCancel={handleCancel} footer={[
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="create" type="primary" onClick={handleCommit} disabled={formError}>
        Create
      </Button>,
    ]}>
      <Form<Setting> labelCol={{ span: 8 }} wrapperCol={{ span: 24 }} form={form} name="SettingCreate" onFieldsChange={() => { setFormError(handleFormChange(form)) }} validateMessages={validateMessages}>
        <Row gutter={[0, 8]}>
          <Col span={12} >
            <Form.Item name={['fullname']} label="Fullname" rules={[{ required: true }]} style={{ display: 'inline-block', width: '90%' }}>
              <Input type="text" placeholder={'User fullname'} />
            </Form.Item>
          </Col>

          <Col span={12} >
            <Form.Item name={['email']} label="Email" rules={[{ required: true }]} style={{ display: 'inline-block', width: '90%' }}>
              <Input type="email" autoComplete="new-email" placeholder={'Email user'}/>
            </Form.Item>
          </Col>

          <Col span={12} >
            <Form.Item name={['password']} label="Password" rules={[{ required: true }]} style={{ display: 'inline-block', width: '90%' }}>
              <Input.Password autoComplete="new-password" placeholder={'Enter user password'}/>
            </Form.Item>
          </Col>

          <Col span={11} >
            <Form.Item label="Roles" name={['sectionRoles']} rules={[{ required: true }]} style={{ display: 'inline-block', width: '100%' }}>
              <Checkbox.Group>
              <Row gutter={[16, 8]}>
                  <Col span={8}>
                    <Checkbox value="Reports">Reports</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Map">Map</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Fleet">Fleet</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Riders">Riders</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Trips">Trips</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Operators">Operators</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Settings">Settings</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Support">Support</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default SettingCreateModal;