import {Campaign} from "../../../shared/types";
import React, {createContext, useContext, useState} from "react";

type ContextType<S> = [S, React.Dispatch<React.SetStateAction<S>>] | null;

const initialState = null;
const CampaignInfoContext = createContext<ContextType<Campaign>>(null);
CampaignInfoContext.displayName = "CampaignInfoContext";

function useCurrentCampaign() {
    const context = useContext(CampaignInfoContext);
    if (!context) {
        throw new Error(`useCurrentCampaign must be used within a CampaignInfoProvider`);
    }

    const [currentCampaign, setCurrentCampaign] = context;

    return {
        currentCampaign,
        setCurrentCampaign
    };
}

function CampaignInfoProvider(props) {
    const campaign = useState(initialState);
    return <CampaignInfoContext.Provider value={campaign} {...props} />;
}

export { CampaignInfoProvider, useCurrentCampaign };
