import { gql, useQuery } from '@apollo/client';

const gqlQueryFilter = gql`
    query overviewOperatorState {
        CITRIN_MGT_overviewOperatorState @client
    }
`;
const typeName = 'CITRIN_MGT_overviewOperatorState';


export type OverviewState = {
    search?: string;
    selectedCities?: string[];
    selectedTypes?: string[];
    page?: number;
};

const initialState: OverviewState = {
    search: '',
    selectedCities: [],
    selectedTypes: [],
    page: 1,
};

export const useOverviewLinkState = (): [OverviewState, (newState: OverviewState) => void] => {
    const { data, client } = useQuery(gqlQueryFilter, { fetchPolicy: 'cache-first' });
    const setState = (newState: OverviewState) => {
        client.writeQuery({
            query: gqlQueryFilter,
            data: {
                CITRIN_MGT_overviewOperatorState: {
                    value: newState,
                    __typename: typeName,
                },
            },
        });
    };
    return [data?.CITRIN_MGT_overviewOperatorState?.value ?? initialState, setState];
};

export default {
    defaults: [
        {
            query: gqlQueryFilter,
            data: {
                CITRIN_MGT_overviewOperatorState: {
                    value: initialState,
                    __typename: 'CITRIN_MGT_overviewOperatorState',
                },
            },
        },
    ],
};