import { useQuery } from '@apollo/client';
import { CITRIN_GET_AllCitiesMap, GetAllCitiesMapResponse } from '..';

export const useGetCitiesMapQuery = () => {
    const { data, previousData, ...rest } = useQuery<GetAllCitiesMapResponse>(CITRIN_GET_AllCitiesMap, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });
    return { ...rest, previousData: previousData?.CITRIN_MGT_getAllCitiesMap, data: data?.CITRIN_MGT_getAllCitiesMap ?? [] };
};
