import { Col, Row } from 'antd';
import React from "react";
import Title from "antd/es/typography/Title";
import { OperatorInfoProvider } from './hooks/useCurrentOperator';
import OperatorTable from './Components/OperatorTable';

const OperatorsView = () => {

    return (
        <>
            <Row style={{ paddingBottom: 10, paddingTop: 20 }}>
                <Col span={12}><Title level={3}>Operators</Title></Col>
            </Row>

            <div style={{ flex: '1 1 auto' }}>
                <OperatorInfoProvider>
                    <OperatorTable />
                </OperatorInfoProvider>
            </div>
        </>
    )
};

export default OperatorsView;

