// Components
import React from "react";
import styled from "styled-components";

// Assets
import AddImage1 from "../../assets/img/add/conjestion-trafic.png";
import AddImage2 from "../../assets/img/add/scan-unlock.png";
import AddImage3 from "../../assets/img/add/co2.png";
import AddImage4 from "../../assets/img/add/foot-print.png";
import { useTranslation } from "react-i18next";

export default function CitrinVision() {

  const { t } = useTranslation();
  
  return (
    <Wrapper id="citrinVision">
      <div className="lightBg" >
        <div className="container" >
        <Vision className="row flexCenter">
           <CenteredTitle className="font40 extraBold">  {t('titleEffortless')}
           <Text>
              <p className="font20 semiBold">
              {t('textEffortless')}
             </p>
              </Text>
              </CenteredTitle>
            </Vision>
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>         
        <div className="lightBg">
          <div className="container">
            <Vision className="flexSpaceCenter">
              <AddLeft>
                <h2 className="font40"> {t('titleCITRINProblemSolve')}</h2>
                <h3 className="semiBold"> {t('titleReducingTraffic')} </h3>
                <p className="font18">
                {t('textReducingTraffic1')}
                <br/>
                {t('textReducingTraffic2')}               
                 </p>
                <br/>
                <h3 className="semiBold"> {t('titleReducingCO2Emissions')}</h3>
                <p className="font18">
                {t('textReducingCO2Emissions')}                
                </p>
                <br/>
                <h3 className="semiBold"> {t('titleLastMileConnectivity')}</h3>
                <p className="font18">
                {t('textLastMileConnectivity')}
                </p>
              </AddLeft>
              <AddRight>
              <AddRightInner>
                  <div className="flexNullCenter ">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Vision>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const Vision = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
 

  @media (max-width: 560px) {
    flex-direction: column;
    padding: 50px 0;
    margin: 80px 0 0px 0;
  }
 `;

const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;


const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 34%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 48%;
  margin-left: 0 6% 11px 9%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 33%;
  margin: 0 5% 13% 8%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

const Text = styled.div`
  padding: 15px 0 50px 0;
  line-height: 1.7rem;
  @media (max-width: 9700px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;

const CenteredTitle = styled.h2`
  text-align: center;
`;