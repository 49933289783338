import { Button, Col, Pagination, Progress, Row, Space, Spin, Table } from 'antd';
import React, { useState } from "react";
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PauseCircleOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Vehicule } from '../../../shared/types';
import { FleetFilter } from './FleetFilter';
import { useSortedVehiculeQuery } from '../hooks/useSortedVehiculeQuery';
import { useCurrentVehicule } from '../hooks/useCurrentVehicule';
import FleetEditModal from './FleetEditModal';
import FleetCreateModal from './FleetCreateModal';
import PopConfirm from '../../../shared/Components/PopConfirm';
import { useMutation } from '@apollo/client';
import { CITRIN_DELETE_VEHICULE, DeleteVehiculeRequest, DeleteVehiculeResponse } from '../../../GraphQL';
import FleetViewModal from './FleetViewModal';

const FleetTable = () => {
    const [isCreateModalVisible, setCreateModalVisible] = useState(false);
    const [isEditModalVisible, setEditModalVisible] = useState(false);
    const [isViewModalVisible, setViewModalVisible] = useState(false);

    const { sortedVehicules: vehiclues, refetch, loading, ...pagination } = useSortedVehiculeQuery();
    const [deleteMutation] = useMutation<DeleteVehiculeResponse, DeleteVehiculeRequest>(CITRIN_DELETE_VEHICULE);
    const { setCurrentVehicule } = useCurrentVehicule();

    const handleRemoveVehicule = (vehicule: Vehicule) => {
        setIsLoading(true);
        deleteMutation({ variables: { id: vehicule._id } })
            .then(() => {
                refetch();
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCreateVehicule = () => { setCreateModalVisible(true); };
    const handleViewVehicule = (vehicule) => {
        setCurrentVehicule(vehicule);
        setViewModalVisible(true);
    };
    const handleEditVehicule = (vehicule: Vehicule) => {
        setCurrentVehicule(vehicule);
        setEditModalVisible(true);
    };
    const [IsLoading, setIsLoading] = useState(false);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'logicalId',
            key: 'logicalId',
            sorter: (a, b) => a.logicalId - b.logicalId,
            width: 80,
        },
        {
            title: 'Vehicule type',
            dataIndex: 'vehiculeType',
            key: 'vehiculeType',
        },
        {
            title: 'Device ID (qrCode)',
            dataIndex: 'qrcode',
            key: 'qrcode',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            render: (text, record: Vehicule) => { return record.status ? <CheckCircleOutlined title='Active' style={{ fontSize: '16px', color: 'green' }} /> : <PauseCircleOutlined title='Inactive' style={{ fontSize: '16px', color: 'red' }} /> }
        },
        {
            title: 'Battery level',
            dataIndex: 'batteryLevel',
            key: 'batteryLevel',
            render: (text, record: Vehicule) => { return <Progress strokeLinecap="butt" percent={record.batteryLevel} /> }
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
        },
        {
            title: "Action",
            key: "action",
            render: (_, vehicule) =>
                <Space size="middle">
                    <a href="#" onClick={() => handleViewVehicule(vehicule)} ><EyeOutlined /></a>
                    <a href="#" onClick={() => handleEditVehicule(vehicule)} ><EditOutlined style={{ fontSize: '16px', color: 'green' }} /></a>
                    <PopConfirm
                        title={"Delete the fleet"}
                        description={"Are you sure to delete this fleet?"}
                        onConfirmTask={() => handleRemoveVehicule(vehicule)}
                        popIcon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        actionIcon={<DeleteOutlined style={{ fontSize: '16px', color: 'red' }} />}
                    />
                </Space>
        },

    ];

    const Paginator = () => (
        <Row justify={'center'}>
            <Col>
                <Pagination
                    size="default"
                    total={pagination.totalCount}
                    pageSize={pagination.pageSize}
                    current={pagination.page}
                    showTotal={(total) => `Total Vehicules ${total}`}
                    onChange={(newPage) => pagination.setPage(newPage)}
                    showQuickJumper={true}
                    showSizeChanger={false}
                    showLessItems={false}
                />
            </Col>
        </Row>
    );

    return (
        <>
            {isViewModalVisible && <FleetViewModal isOpen={isViewModalVisible} onRequestClose={setViewModalVisible} />}

            {isCreateModalVisible && <FleetCreateModal updateList={refetch} isOpen={isCreateModalVisible} onRequestClose={setCreateModalVisible} />}

            {isEditModalVisible && <FleetEditModal updateList={refetch} isOpen={isEditModalVisible} onRequestClose={setEditModalVisible} />}

            <Row justify={'end'}>
                <Button type="primary"
                    shape="round"
                    icon={<PlusCircleOutlined />}
                    size={'middle'}
                    onClick={handleCreateVehicule}>Add</Button>
            </Row>

            <Row gutter={[16, 6]} style={{ paddingBottom: 10, paddingTop: 20 }}>
                <FleetFilter />
            </Row>
            <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
                <Col xs={24}>
                    <Spin spinning={IsLoading || loading}>
                        <Table
                            dataSource={vehiclues}
                            columns={columns}
                            pagination={false}
                            rowKey={() => Math.random().toString()}
                            scroll={{ x: 1500, y: 400 }}>
                        </Table>
                    </Spin>
                </Col>
            </Row>
            <Paginator />
        </>
    )
};

export default FleetTable;