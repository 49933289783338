import { Col, Row } from 'antd';
import React from "react";
import Title from "antd/es/typography/Title";
import { useGetSettingQuery } from '../../GraphQL/hooks/useGetSettingQuery';
import jwt_decode from "jwt-decode"
import { useUserState } from '../../Hooks/useUserState';
import SettingsTabs from './Components/SettingsTabs';
import { SettingInfoProvider } from './hooks/useCurrentSetting';


const SettingsView = () => {
    const { setAuth, ...state } = useUserState();

    const decoded: { _id: string, sectionRoles: [string] } = state?.accessToken
        ? jwt_decode(state?.accessToken)
        : undefined;

    const { data, refetch, loading } = useGetSettingQuery(decoded?._id);

    return (
        <>
            <Row style={{ paddingBottom: 10, paddingTop: 20 }}>
                <Col span={12}><Title level={3}>Settings</Title></Col>
            </Row>

            <Row>
                <Col span={24}>
                    <SettingInfoProvider>
                        {!loading ? <SettingsTabs settingsData={data} /> : ''}
                    </SettingInfoProvider>
                </Col>
            </Row>
        </>

    )
};


export default SettingsView;