import { useMutation } from '@apollo/client';
import { CityMap } from '../../shared/types';
import { CITRIN_UPDATE_CityMap, UpdateCityMapRequest, UpdateCityMapResponse } from '..';

type UpdateMutationFunction = (request: CityMap) => Promise<CityMap>;
export const useUpdateCityMapMutation = (): UpdateMutationFunction => {

    const [mutation, { client }] = useMutation< UpdateCityMapResponse, UpdateCityMapRequest>(CITRIN_UPDATE_CityMap);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const updated = data?.CITRIN_MGT_updateCityMap;
        return updated;
    };
};
