import React from "react";
import "./styles.css";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom"

const NotFound = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div className="flexGrow">
      <Result
        status="404"
        title="Not found 404"
        subTitle="Sorry, there's nothing here!"
        extra={<Button type="primary" onClick={goBack}>Go Back</Button>}
      />
    </div>
  );
};

export default NotFound;