import { Popconfirm } from "antd";
import { useState } from "react";

const PopConfirm = ({ title, description, onConfirmTask,  okText='Confirm', popIcon, actionIcon }) => {
  const [open, setOpen] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Popconfirm
      title={title}
      description={description}
      onConfirm={onConfirmTask}
      okText={okText}
      icon={popIcon}
      onCancel={handleCancel}>
        <a href="#" onClick={showPopconfirm}> {actionIcon} </a>
    </Popconfirm>
  );
};

export default PopConfirm;
