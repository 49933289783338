import { Button, Col, Pagination, Row, Space, Spin, Table } from 'antd';
import React, { useState } from "react";
import { DeleteOutlined, PlusCircleOutlined, QuestionCircleOutlined, } from '@ant-design/icons';
import PopConfirm from '../../../shared/Components/PopConfirm';
import { City } from '../../../shared/types';
import { useSortedCityQuery } from '../hooks/useSortedCityQuery';
import { CITRIN_DELETE_CITIES, DeleteCityRequest, DeleteCityResponse } from '../../../GraphQL';
import { useMutation } from '@apollo/client';
import CityCreateModal from './CityCreateModal';

const CitiesTable = () => {
    const [isCreateModalVisible, setCreateModalVisible] = useState(false);
    const { sortedCities: cities, refetch, loading, ...pagination } = useSortedCityQuery();
    const [deleteMutation] = useMutation<DeleteCityResponse, DeleteCityRequest>(CITRIN_DELETE_CITIES);

    const handleRemoveCity = (city: City) => {
        setIsLoading(true);
        deleteMutation({ variables: { id: city._id } })
            .then(() => {
                refetch();
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setIsLoading(false);
            });
    };


    const handleCreateCity = () => { setCreateModalVisible(true); };
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'logicalId',
            key: 'logicalId',
            sorter: (a, b) => a.logicalId - b.logicalId,
            width: 80,
        },
        {
            title: 'Cityname',
            dataIndex: 'cityName',
            key: 'cityName',
        },
        {
            title: 'Latitude',
            dataIndex: 'latitude',
            key: 'latitude',
        },
        {
            title: 'Longitude',
            dataIndex: 'longitude',
            key: 'longitude',
        },
        {
            title: "Action",
            key: "action",
            render: (_, cities) =>
                <Space size="middle">
                    <PopConfirm
                        title={"Delete this City"}
                        description={"Are you sure to delete this city?"}
                        onConfirmTask={() => handleRemoveCity(cities)}
                        popIcon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        actionIcon={<DeleteOutlined style={{ fontSize: '16px', color: 'red' }} />}
                    />
                </Space>
        },

    ];

    const Paginator = () => (
        <Row justify={'center'}>
            <Col>
                <Pagination
                    size="default"
                    total={pagination.totalCount}
                    pageSize={pagination.pageSize}
                    current={pagination.page}
                    showTotal={(total) => `Total Cities ${total}`}
                    onChange={(newPage) => pagination.setPage(newPage)}
                    showQuickJumper={true}
                    showSizeChanger={false}
                    showLessItems={false}
                />
            </Col>
        </Row>
    );


    return (
        <>
            {isCreateModalVisible && <CityCreateModal updateList={refetch} isOpen={isCreateModalVisible} onRequestClose={setCreateModalVisible} />}

            <Row justify={'end'}>
                <Button type="primary"
                    shape="round"
                    icon={<PlusCircleOutlined />}
                    size={'middle'}
                    onClick={handleCreateCity}>Add</Button>
            </Row>

            <div style={{ flex: '1 1 auto' }}>
                <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
                    <Col xs={24}>
                        <Spin spinning={isLoading || loading}>
                            <Table
                                dataSource={cities}
                                columns={columns}
                                pagination={false}
                                rowKey={() => Math.random().toString()}
                                scroll={{ x: 1500, y: 400 }}>
                            </Table>
                        </Spin>
                    </Col>
                </Row>
                <Paginator />
            </div>
        </>
    )
};

export default CitiesTable;