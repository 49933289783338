import { Trip } from "../../../shared/types";
import React, { createContext, useContext, useState } from "react";

type ContextType<S> = [S, React.Dispatch<React.SetStateAction<S>>] | null;

const initialState = null;
const TripInfoContext = createContext<ContextType<Trip>>(null);
TripInfoContext.displayName = "TripInfoContext";

function useCurrentTrip() {
    const context = useContext(TripInfoContext);
    if (!context) {
        throw new Error(`useCurrentTrip must be used within a TripInfoProvider`);
    }

    const [currentTrip, setCurrentTrip] = context;

    return {
        currentTrip,
        setCurrentTrip
    };
}

function TripInfoProvider(props) {
    const trip = useState(initialState);
    return <TripInfoContext.Provider value={trip} {...props} />;
}

export { TripInfoProvider, useCurrentTrip };
