import { useEffect, useMemo } from "react";
import { Vehicule, VehiculeQueryFilter } from "../../../shared/types";
import { chunk, isEmpty } from 'lodash';
import { usePageState } from "../../../Hooks/usePageState";
import { useOverviewLinkState } from "../linkstate/fleetOverview.linkstate";
import { useGetVehiculesQuery } from "../../../GraphQL/hooks/useGetVehiculesQuery";
import { useUserState } from "../../../Hooks/useUserState";

const pageSize = 10;
const getPageCount = (vehicules: Vehicule[]) => Math.ceil(vehicules?.length / pageSize);
const getCurrentPage = (requestedPage: number, vehicules: Vehicule[]) => {
    const pageCount = getPageCount(vehicules);
    return requestedPage < 1 ? 1 : requestedPage > pageCount ? pageCount : requestedPage;
};

export const useSortedVehiculeQuery = () => {
    const { setLoading } = usePageState();
    const [state, setState] = useOverviewLinkState();
    const { ...userState } = useUserState();
    const { page, search, selectedDateRange, selectedCities, status, batteryLevelRange, selectedTypes } = state;

    const request = useMemo(
        () =>
        ({
            search: search ?? '',
            cities: isEmpty(selectedCities) ? undefined : selectedCities,
            types: isEmpty(selectedTypes) ? undefined : selectedTypes,
            status: status,
            batteryLevelRange: batteryLevelRange,
            dateRange: selectedDateRange,
            operatorId: userState.operatorId,
        } as VehiculeQueryFilter),
        [search, status, selectedDateRange, selectedCities, batteryLevelRange, selectedTypes]
    );
    const { data: unsortedVehicules, refetch, loading } = useGetVehiculesQuery(request);
    useEffect(() => setLoading(loading), [loading]);

    const setPage = (newPage: number) => {
        const pageCount = getPageCount(unsortedVehicules);
        // console.log('new page', newPage, pageCount);
        const page = newPage < 1 ? 1 : newPage > pageCount ? pageCount : newPage;
        setState({ ...state, page });
    };

    const { pageCount, totalCount, sortedVehicules } = useMemo(() => {
        const totalCount = unsortedVehicules?.length ?? 0;
        const pageCount = getPageCount(unsortedVehicules);
        const sortedVehicules = chunk(unsortedVehicules, pageSize);

        return { totalCount, pageCount, sortedVehicules };
    }, [unsortedVehicules]);

    const currentPage = getCurrentPage(page, unsortedVehicules);

    return {
        sortedVehicules: sortedVehicules?.length > 0 ? sortedVehicules[currentPage - 1] : [],
        refetch: () => refetch({ request }),
        page: page ?? 1,
        pageCount,
        totalCount,
        pageSize,
        setPage,
        loading
    };
};
