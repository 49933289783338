import { Pass } from "../../../shared/types";
import React, { createContext, useContext, useState } from "react";

type ContextType<S> = [S, React.Dispatch<React.SetStateAction<S>>] | null;

const initialState = null;
const PassInfoContext = createContext<ContextType<Pass>>(null);
PassInfoContext.displayName = "PassInfoContext";

function useCurrentPass() {
    const context = useContext(PassInfoContext);
    if (!context) {
        throw new Error(`useCurrentPass must be used within a PassInfoProvider`);
    }

    const [currentPass, setCurrentPass] = context;

    return {
        currentPass,
        setCurrentPass
    };
}

function PassInfoProvider(props) {
    const pass = useState(initialState);
    return <PassInfoContext.Provider value={pass} {...props} />;
}

export { PassInfoProvider, useCurrentPass };
