import React, { useState } from "react";
import { Input, Form, Select, Modal, Space, Upload, Row, Col, Tabs, Checkbox, InputNumber, Button, Tooltip } from 'antd';
import { OperatorInput, allOperatorTypes } from "../../../shared/types";
import { handleFormChange, validateMessages } from "../../../shared/Utils";
import { useCreateOperatorMutation } from "../../../GraphQL/hooks/useCreateOperatorMutation";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useGetAllCityQuery } from '../../../GraphQL/hooks/useGetAllCityQuery';

const OperatorCreateModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm();
  const [formError, setFormError] = useState(false);
  const [newOperator, setNewOperator] = useState<OperatorInput>();
  const [prefix, setPrefix] = useState<string>('+216');
  const { mutation, loading: creating } = useCreateOperatorMutation();
  const { data: cities } = useGetAllCityQuery();

  const [activeTab, setActiveTab] = useState('operator-data');

  const [fileList, _setFileList] = useState<File[]>([]);
  const setFileList = (newValue: File[]) => {
    _setFileList(newValue);
  };

  const prefixSelector = (
    <Form.Item noStyle>
      <Select style={{ width: 80 }} defaultValue={'+216'} onChange={(prefix) => setPrefix(prefix)}>
        <Select.Option value="+216">+216</Select.Option>
        <Select.Option value="+218">+218</Select.Option>
        <Select.Option value="+213">+213</Select.Option>
        <Select.Option value="+212">+212</Select.Option>
      </Select>
    </Form.Item>
  );


  const handleCommit = async () => {
    try {
      const values = await form.validateFields();

      if (values) {
        mutation({
          ...newOperator,
          phone: prefix + newOperator?.phone
        }, fileList[0])
          .then(() => {
            onRequestClose(false)
            updateList()
          })
          .finally(() => {
            form.resetFields();
            console.log("New Operator was successfuly added")
          })
          .catch((err) => console.log
          );
      }
    } catch (errorInfo) {
      console.log('Failed: Form is empty', errorInfo);
    }
  };


  const handleCancel = () => {
    onRequestClose(false);
  };

  const tabItems = [
    {
      key: 'operator-data',
      label: 'Business Info',
      children: (
        <Row gutter={[0, 8]}>
          <Col span={12} >
            <Form.Item name="name" label="Name" rules={[{ required: true }]} style={{ display: 'inline-block', width: '90%' }}>
              <Input type="text" />
            </Form.Item>
          </Col>
          <Col span={12} >
            <Form.Item name="phone" label="Phone" rules={[{ required: true }]} style={{ display: 'inline-block', width: '90%' }}>
              <Input type="phone" addonBefore={prefixSelector} placeholder={'72 234 567'} />
            </Form.Item>
          </Col>
          <Col span={12} >
            <Form.Item name="email" label="Email" rules={[{ type: 'email', message: 'Please provide a valid email!', }, { required: true, message: 'Please enter your E-mail!', }]} style={{ display: 'inline-block', width: '90%' }}>
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col span={12} >
            <Form.Item name="website" label="Website" rules={[{ required: false }]} style={{ display: 'inline-block', width: '90%' }}>
              <Input type="url" />
            </Form.Item>
          </Col>
          <Col span={12} >
            <Form.Item name="type" label="Type" rules={[{ required: true }]} style={{ display: 'inline-block', width: '90%' }}>
              <Select placeholder="Select type of operators" >
                {(allOperatorTypes ?? []).map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} >
            <Form.Item name="tva" label="MF" rules={[{ required: false }]} style={{ display: 'inline-block', width: '90%' }}>
              <Input type="text" suffix={
            <Tooltip title="This is your Tax ID Number" placement="rightTop">
              <InfoCircleOutlined style={{ color: '#1677ff', fontSize: '20px' }} />
            </Tooltip>
          } />
            </Form.Item>
          </Col>
          <Col span={12} >
            <Form.Item label="Address" style={{ display: 'inline-block', width: '90%', marginBottom: '0px' }}>
              <Space.Compact >
                <Form.Item
                  name={['address', 'street']}
                  rules={[{ required: false }]}
                  style={{ width: '60%' }}
                >
                  <Input type="text" placeholder="Street name" />
                </Form.Item>
                <Form.Item
                  name={['address', 'postalCode']}
                  style={{ width: '40%' }}
                  rules={[{ required: false }]}
                >
                  <InputNumber min={1} max={99999} placeholder="Postal code" />
                </Form.Item>
              </Space.Compact>
            </Form.Item>
          </Col>
          <Col span={12} >
            <Form.Item
              name={['address', 'city']}
              label="City"
              style={{ display: 'inline-block', width: '90%' }}
              rules={[{ required: true }]}
            >
              <Select placeholder="Select City" style={{ width: '90%' }} >
                {(cities ?? []).map((city) => (
                  <Select.Option key={city._id} value={city.cityName}>
                    {city.cityName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} >
            <Form.Item
              name={['address', 'state']}
              label="State"
              style={{ display: 'inline-block', width: '90%' }}
              rules={[{ required: true }]}
            >
              <Select placeholder="Select state">
                <Select.Option value="Bizerte">Bizerte</Select.Option>
                <Select.Option value="Sfax">Sfax</Select.Option>
                <Select.Option value="Tunis">Tunis</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} >
            <Form.Item
              name={['address', 'country']}
              label="Country"
              style={{ display: 'inline-block', width: '90%' }}
              rules={[{ required: true }]}
            >
              <Select placeholder="Select country">
                <Select.Option value="Tunisia">Tunisia</Select.Option>
                <Select.Option value="Algeria">Algeria</Select.Option>
                <Select.Option value="Marokko">Marokko</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={5} offset={2}>
            <Form.Item name={"attachmentsFiles"} label="Logo" valuePropName='fileList' getValueFromEvent={(event) => { return event?.fileList }}
              rules={[{ required: false, message: "please upload your Logo." },
              {
                validator(_, fileList) {
                  return new Promise((resolve, reject) => {
                    if (fileList?.size > 5000000) {
                      reject("File size exceeded")
                    } else {
                      resolve("Successs");
                    }
                  });
                }
              }
              ]}
              style={{ display: 'inline-block' }}>
              <Upload
                accept="image/png, image/jpeg"
                maxCount={1}
                fileList={fileList as any}
                beforeUpload={(_, fileList) => {
                  setFileList(fileList);
                  return false;
                }}
                customRequest={(info) => {
                  setFileList([info?.file as File])
                }}
                showUploadList={false}
                listType="picture-card">
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload image</div>
                  {fileList[0]?.name}
                </div>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={17}>
            <Form.Item name="description" label="Description" rules={[{ required: false }]} style={{ display: 'inline-block', width: '93%' }}>
              <Input.TextArea showCount maxLength={100} rows={4} />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      key: 'settings-data',
      label: 'Settings',
      children: (
        <Row gutter={[0, 8]}>
          <Col span={12} >
            <Form.Item name={['settingId', 'fullname']} label="Fullname" rules={[{ required: true }]} style={{ display: 'inline-block', width: '90%' }}>
              <Input type="text" placeholder={'User fullname'} />
            </Form.Item>
          </Col>

          <Col span={12} >
            <Form.Item name={['settingId', 'email']} label="Email" rules={[{ type: 'email', message: 'Please provide a valid email!', }, { required: true, message: 'Please enter your E-mail!', }]} style={{ display: 'inline-block', width: '90%' }}>
              <Input type="email" autoComplete="new-email" placeholder={'Email user'}/>
            </Form.Item>
          </Col>

          <Col span={12} >
            <Form.Item name={['settingId', 'password']} label="Password" rules={[{ required: true }]} style={{ display: 'inline-block', width: '90%' }}>
              <Input.Password autoComplete="new-password" placeholder={'Enter user password'}/>
            </Form.Item>
          </Col>

          <Col span={11} >
            <Form.Item label="Roles" name={['settingId', 'sectionRoles']} rules={[{ required: false }]} style={{ display: 'inline-block', width: '100%' }}>
              <Checkbox.Group>
              <Row gutter={[16, 8]}>
                  <Col span={8}>
                    <Checkbox value="Reports">Reports</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Map">Map</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Fleet">Fleet</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Riders">Riders</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Trips">Trips</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Operators">Operators</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Settings">Settings</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="Support">Support</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];
  return (
    <Modal forceRender title="Add operator" width={750} open={isOpen} visible={isOpen} onCancel={handleCancel} footer={[
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="create" type="primary" onClick={handleCommit} disabled={formError}>
        Create
      </Button>,
    ]}>
      <Form<OperatorInput> labelCol={{ span: 8 }} wrapperCol={{ span: 24 }} form={form} autoComplete="off" name="nest-messages" onValuesChange={(changedValues, allValues) => { setNewOperator(allValues); }} onFieldsChange={() => { setFormError(handleFormChange(form)) }} validateMessages={validateMessages}>
        <Tabs defaultActiveKey="operator-data" items={tabItems} />
      </Form>
    </Modal>
  );

}

export default OperatorCreateModal;