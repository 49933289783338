import { useMutation } from '@apollo/client';
import { ResetPasswordInput } from '../../shared/types';
import { CreateResetPasswordResponse, CreateResetPasswordRequest, CITRIN_RESET_PASSWORD } from '..';

type CreateMutationFunction = (request: ResetPasswordInput) => Promise<String>;
export const useResetPasswordMutation = (): CreateMutationFunction => {

    const [mutation, { client }] = useMutation<CreateResetPasswordResponse, CreateResetPasswordRequest>(CITRIN_RESET_PASSWORD);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const ResetResponse = data?.CITRIN_MGT_resetPassword;
        return ResetResponse;
    };
};
