import React, { useState } from "react";
import { Button, Card, Col, Form, Input, notification } from 'antd';
import "./styles.css";
import { useNavigate, useSearchParams } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha";
import { useResetPasswordMutation } from "../../../GraphQL/hooks/useResetPasswordMutation";
import { ResetPasswordInput } from "../../../shared/types";
import Meta from "antd/es/card/Meta";
import { handleFormChange } from "../../../shared/Utils";

const ResetPasswordView = () => {
    const mutation = useResetPasswordMutation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [form] = Form.useForm();
    const [formError, setFormError] = useState(false);
    const [requestStatus, setRequestStatus] = useState(true);
    const [reCAPTCHAStatus, setReCAPTCHAStatus] = useState(false);


    const handelSuccessReCAPTCHA = () => {
        setReCAPTCHAStatus(true)
    }

    const handelErrorReCAPTCHA = () => {
        setReCAPTCHAStatus(false)
    }

    const onFinish = async () => {
        const values = await form.validateFields(["email", "password"]);

        if (values) {
            const resetToken = searchParams.get('token');

            console.log("Form values:", values);
            mutation({
                email: values.email,
                newPassword: values.password,
                resetToken: resetToken,
            } as ResetPasswordInput)
                .then(() => {
                    notification.success({
                        message: "Password reset successful",
                        description: "You can now log in with your new password.",
                    });
                    navigate('/');
                    setRequestStatus(true)
                })
                .catch((err) => {
                    notification.error({
                        message: "Reset request failed",
                        description: "Please enter a valid email and password!",
                    });
                });
        }
    };

    const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 8,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 16,
          },
        },
      };

    return (
        <div style={{ background: '#f0f2f5', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card style={{ width: '500px', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                    <Meta
                        title={<span style={{ fontSize: '28px' }}>Reset your password</span>}
                        description="Please enter your Email and the new password"
                    />
                </div>

                <Form<ResetPasswordInput>
                    form={form}
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: false }}
                    style={{ textAlign: 'center', width: '100%' }}
                    onFinish={onFinish}
                    onFieldsChange={() => { setFormError(handleFormChange(form)) }} 
                    {...formItemLayout}
                    autoComplete="off"
                >
                    <Form.Item name="email" label="E-mail"
                        rules={[{ type: 'email', message: 'Please provide a valid email!' }, { required: true, message: 'Please input your email!' }]}
                    >
                        <Input  placeholder="Email address" />
                    </Form.Item>

                    <Form.Item name="password" label="New Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder="Create new password" />
                    </Form.Item>

                    <Form.Item name="confirm" label="Confirm Password" dependencies={['password']} hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Confirm your password"/>
                    </Form.Item>
                    <Form.Item
                    style={{ display: 'flex', justifyContent: 'space-around' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please complete the reCAPTCHA.',
                            },
                        ]}
                    >
                        <ReCAPTCHA 
                        
                        sitekey="6LcnoispAAAAAMziizHmY_xMSGLJU6vQcGB51Fkd"
                        onChange={handelSuccessReCAPTCHA}
                        onErrored={handelErrorReCAPTCHA} />
                    </Form.Item>
                    <Form.Item  noStyle>
                        <Button type="primary" htmlType="submit" className="login-form-button" disabled={formError || !reCAPTCHAStatus}>
                            Reset password
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default ResetPasswordView;

