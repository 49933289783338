import { Button, Col, Pagination, Row, Space, Spin, Table } from 'antd';
import React, { useState } from "react";
import { CheckCircleOutlined, DeleteOutlined, NotificationOutlined, PauseCircleOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Campaign } from '../../../shared/types';
import { useSortedCampaignQuery } from '../hooks/useSortedCampaignQuery';
import PopConfirm from '../../../shared/Components/PopConfirm';
import { useMutation } from '@apollo/client';
import { CITRIN_DELETE_CAMPAIGN, CITRIN_SEND_PUSHNOTIFICATION_CAMPAIGN, DeleteCampaignRequest, DeleteCampaignResponse, PushNotificationCampaignRequest, PushNotificationCampaignResponse } from '../../../GraphQL';
import moment from 'moment';
import CampaignCreateModal from './CampaignCreateModal';

const CampaignTable = () => {
  const [isCreateModalVisible, setCreateModalVisible] = useState(false);

  const { sortedCampaigns: campaigns, refetch, loading, ...pagination } = useSortedCampaignQuery();
  const [deleteMutation] = useMutation<DeleteCampaignResponse, DeleteCampaignRequest>(CITRIN_DELETE_CAMPAIGN);
  const [pushNotificationCampaignMutation] = useMutation<PushNotificationCampaignResponse, PushNotificationCampaignRequest>(CITRIN_SEND_PUSHNOTIFICATION_CAMPAIGN);

  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveCampaign = (campaign: Campaign) => {
    setIsLoading(true)
    deleteMutation({ variables: { id: campaign._id } })
      .then(() => {
        refetch();
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false)
      });
  };

  const handleSetPushNotificationCampaign = (campaign: Campaign) => {
    setIsLoading(true)
    pushNotificationCampaignMutation({ variables: { id: campaign._id } })
      .then(() => {
        refetch();
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoading(false)
      });
  };

  const handleCreateCampaign = () => { setCreateModalVisible(true); };

  const columns = [

    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Starting date',
      dataIndex: 'startingDate',
      key: 'startingDate',
      sorter: (a, b) => a.startingDate - b.startingDate,
      render: (text, record) => { return moment(Number(record.startingDate)).format("DD-MM-YYYY"); }
    },
    {
      title: 'End date',
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: (a, b) => a.endDate - b.endDate,
      render: (text, record) => { return moment(Number(record.endDate)).format("DD-MM-YYYY"); }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record: Campaign) => { return record.status ? <CheckCircleOutlined title='Active' style={{ fontSize: '16px', color: 'green' }} /> : <PauseCircleOutlined title='Inactive' style={{ fontSize: '16px', color: 'red' }} /> }
    },
    {
      title: "Action",
      key: "action",
      render: (_, campaign) =>
        <Space size="middle">
          <PopConfirm
            title={"Delete the campaign"}
            description={"Are you sure to delete this campaign?"}
            onConfirmTask={() => handleRemoveCampaign(campaign)}
            popIcon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            actionIcon={<DeleteOutlined style={{ fontSize: '16px', color: 'red' }} />}
          />

          <Button  
            icon={<NotificationOutlined title='Brodcast' style={{ fontSize: '16px', color: 'green' }}  />}
            size={'middle'}
            onClick={() => handleSetPushNotificationCampaign(campaign)}>
          </Button>
        </Space>
    },

  ];

  const Paginator = () => (
    <Row justify={'center'}>
      <Col>
        <Pagination
          size="default"
          total={pagination.totalCount}
          pageSize={pagination.pageSize}
          current={pagination.page}
          showTotal={(total) => `Total Campaigns ${total}`}
          onChange={(newPage) => pagination.setPage(newPage)}
          showQuickJumper={true}
          showSizeChanger={false}
          showLessItems={false}
        />
      </Col>
    </Row>
  );

  return (
    <>

      <CampaignCreateModal updateList={refetch}
        isOpen={isCreateModalVisible}
        onRequestClose={setCreateModalVisible} />

      <Row justify={'end'}>
        <Button type="primary"
          shape="round"
          icon={<PlusCircleOutlined />}
          size={'middle'}
          onClick={handleCreateCampaign}>Add</Button>
      </Row>


      <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
        <Col xs={24}>
          <Spin spinning={isLoading || loading}>
            <Table
              dataSource={campaigns}
              columns={columns}
              pagination={false}
              rowKey={() => Math.random().toString()}
              scroll={{ x: 1500, y: 400 }}>
            </Table>
          </Spin>
        </Col>
      </Row>
      <Paginator />
    </>
  )
};

export default CampaignTable;