import { useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { RiderQueryFilter } from '../../shared/types';
import { CITRIN_GET_RIDERS, GetRidersRequest, GetRidersResponse } from '..';

export const useGetRidersQuery = (request: RiderQueryFilter, debounceTime?: number) => {
    const [internalRequest, setInternalRequest] = useState(() => request);
    const debounced = useRef(
        debounce(
            (newRequest:RiderQueryFilter) => {
                setInternalRequest(newRequest);
            },
            debounceTime ?? 100,
            { leading: false, trailing: true }
        ) 
    ).current;
    useEffect(() => {
        return debounced(request);
    }, [request]);

    const { data, previousData, ...rest } = useQuery<GetRidersResponse, GetRidersRequest>(CITRIN_GET_RIDERS, {
        variables: { request: internalRequest },
        // fetchPolicy: 'cache-and-network',
        // onCompleted: (data) => console.log('[MGT] Riders', data),
    });

    return { ...rest, previousData: previousData?.CITRIN_MGT_getRiders, data: data?.CITRIN_MGT_getRiders ?? [] };
};