import { useMemo } from "react";
import { useOverviewLinkState } from "../linkstate/ReportsOverview.linkstate";
import { ReportsQueryFilter } from "../../../../shared/types";
import { useGetReportsCO2Query } from "../../../../GraphQL/hooks/useGetReportsCO2Query";
import { useUserState } from "../../../../Hooks/useUserState";


export const useSortedReportsCO2Query = () => {
    const [state] = useOverviewLinkState();
    const { quickCalendarFilter, selectedDateRange } = state;
    const { ...userState } = useUserState();

    const request = useMemo(
        () =>
        ({
            quicklyCalanderSlot: quickCalendarFilter ?? '',
            dateRange: selectedDateRange,
            operatorId: userState.operatorId,
        } as ReportsQueryFilter),
        [quickCalendarFilter, selectedDateRange]
    );

    const { data: CO2Data, refetch, loading: loadingCO2 } = useGetReportsCO2Query(request);

    return {
        CO2Data,
        refetchCO2: () => refetch({ request }),
        loadingCO2,
    };
};
