import React, { useMemo } from 'react';
import { Col, DatePicker, Form, Radio } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useOverviewLinkStateActions } from './hooks/useOverviewLinkStateActions';
import { useOverviewLinkState } from './linkstate/ReportsOverview.linkstate';
import { allCalendarsTypes } from '../../../shared/types';

const isNotEmpty = (s: string) => s && !isEmpty(s);

export const ReportsFilter = () => {
    const [form] = Form.useForm();
    const [{ selectedDateRange, quickCalendarFilter }] = useOverviewLinkState();
    const { patch: patchState } = useOverviewLinkStateActions();

    const rangePickerValue = useMemo(() => {
        return isNotEmpty(selectedDateRange?.fromDate) && isNotEmpty(selectedDateRange?.toDate)
            ? [moment(selectedDateRange?.fromDate), moment(selectedDateRange?.toDate)]
            : [null, null];
    }, [selectedDateRange]);


    const handleQuickCalanderChanged = (e) => {
        patchState({
            selectedDateRange: { fromDate: undefined, toDate: undefined },
            quickCalendarFilter: e.target.value
        });
        handleReset();
    }

    const handleRangePickerChanged = (dates: [any, any]) => {
        patchState({
            selectedDateRange: dates && dates[0] && dates[1]
                ? {
                    fromDate: dates[0].format('YYYY-MM-DD'),
                    toDate: dates[1].format('YYYY-MM-DD'),
                }
                : undefined,
            quickCalendarFilter: undefined
        });
    };

    const handleReset = () => {
        form.resetFields(['dateRange']);
    };

    return (
        <>

            <Col>
                <Radio.Group
                    value={quickCalendarFilter}
                    buttonStyle="solid"
                    onChange={handleQuickCalanderChanged}
                >
                    {allCalendarsTypes.map((v) => (
                        <Radio.Button key={`quickCalendarFilter-${v}`} value={v}>
                            {v}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </Col>
            <Col>
                <Form form={form}>
                    <Form.Item name="dateRange">
                        <DatePicker.RangePicker value={rangePickerValue as any} onChange={handleRangePickerChanged} />
                    </Form.Item>
                </Form>
            </Col>

        </>
    );
};

