import { useQuery } from '@apollo/client';
import { CITRIN_GET_SETTING, GetSettingRequest, GetSettingResponse } from '..';

export const useGetSettingQuery = (id: string) => {
    const { data, previousData, ...rest } = useQuery<GetSettingResponse, GetSettingRequest>(CITRIN_GET_SETTING, {
            variables: { id }, 
           // fetchPolicy: 'cache-and-network'
            // onCompleted: (data) => console.log('[MGT] Users', data),
    
        // fetchPolicy: 'cache-and-network',
        // onCompleted: (data) => console.log('[MGT] Setting', data),
    });

    return { ...rest, previousData: previousData?.CITRIN_MGT_getSetting, data: data?.CITRIN_MGT_getSetting ?? undefined };
};