import { useMutation } from '@apollo/client';
import { Pass } from '../../shared/types';
import { CITRIN_CREATE_PASS, CreatePassRequest, CreatePassResponse } from '..';

type CreateMutationFunction = (request: Pass) => Promise<Pass>;
export const useCreatePassMutation = (): CreateMutationFunction => {

    const [mutation, { client }] = useMutation<CreatePassResponse, CreatePassRequest>(CITRIN_CREATE_PASS);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const created = data?.CITRIN_MGT_createPass;
     //   updateGetPassCache(client, updated, ...(keys(request) as any));
        return created;
    };
};
