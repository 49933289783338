import React, { useState } from "react";
import { Button, Card, Checkbox, Form, Input } from 'antd';
import "./styles.css";
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons';
import { useLoginUserMutation } from "../../../GraphQL/hooks/useLoginUserMutation";
import { LoginInput, TokenResponse } from "../../../shared/types";
import { useNavigate } from 'react-router-dom';
import { useUserState } from "../../../Hooks/useUserState";
import { handleFormChange } from "../../../shared/Utils";
import useToggle from "../../../Hooks/useToggle";
import { notification } from 'antd';
import Meta from "antd/es/card/Meta";

function LoginForm() { 
  const [form] = Form.useForm();
  //const [loginCredentials, setLoginCredentials] = useState<LoginInput>();
  const [formError, setFormError] = useState(false);

  const { setAuth, ...state } = useUserState();

  const mutation = useLoginUserMutation();
  const navigate = useNavigate()
  const [check, toggleCheck] = useToggle('persist', false)
  const onFinish = async () => {
    const values = await form.validateFields();

    if (values) {
      mutation({ email: values.email, password: values.password }).then((tokenResponse: TokenResponse) => {
        setAuth(tokenResponse.accessToken, tokenResponse?.operatorId);
        navigate('/');
      })
        .catch((err) => {
          notification.error({
            message: "Login failed",
            description: "Please enter a valid email and password",
          });
        });
    }
  };

  return (
    <div style={{ background: '#f0f2f5', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Card style={{ width: '500px', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
    <div style={{ marginBottom: '20px', textAlign: 'center' }}>
          <Meta
            title={<span style={{ fontSize: '28px' }}>Sign In</span>}
            description="Please enter your Email and password"
          />
        </div>
      <Form<LoginInput>
        form={form}
        name="normal_login"
        className="login-form"
        initialValues={{ remember: false }}
        style={{ textAlign: 'center', width: '100%' }}      
        onFinish={onFinish}
        onFieldsChange={() => { setFormError(handleFormChange(form)) }}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email"  />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" disabled={formError}>
            Log in
          </Button>
        </Form.Item>
        <Form.Item>
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <Form.Item noStyle>
      <Checkbox onChange={toggleCheck} checked={check}>
        Remember me
      </Checkbox>
    </Form.Item>
    <a className="login-form-forgot" onClick={() => navigate('/forget')}>
      Forgot password
    </a>
  </div>
</Form.Item>
      </Form>
    </Card>
    </div>
  );
};

export default LoginForm;