import React, { useMemo } from 'react';
import { Col, DatePicker, Radio } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useOverviewLinkStateActions } from '../hooks/useOverviewLinkStateActions';
import { useOverviewLinkState } from '../linkstate/tripOverview.linkstate';

const isNotEmpty = (s: string) => s && !isEmpty(s);
export type TripStatus = 'Done' | 'Running' | 'Suspended';
export const allTripStatus: TripStatus[] = ['Done', 'Running', 'Suspended'];

export const TripsFilter = () => {


    const [{ selectedDateRange, quickStatusFilter }] = useOverviewLinkState();
    const { patch: patchState } = useOverviewLinkStateActions();

    const rangePickerValue = useMemo(() => {
        return isNotEmpty(selectedDateRange?.fromDate) && isNotEmpty(selectedDateRange?.toDate)
            ? [moment(selectedDateRange?.fromDate), moment(selectedDateRange?.toDate)]
            : [null, null];
    }, [selectedDateRange]);

    const handleRangePickerChanged = (dates: [any, any]) => {
        patchState({
            selectedDateRange:
                dates && dates[0] && dates[1]
                    ? {
                        fromDate: dates[0].format('YYYY-MM-DD'),
                        toDate: dates[1].format('YYYY-MM-DD'),
                    }
                    : undefined,
        });
    };

    return (
        <>
            <Col>
                <Radio.Group
                    value={quickStatusFilter}
                    buttonStyle="solid"
                    onChange={(e) => patchState({ quickStatusFilter: e.target.value })}
                >
                    {allTripStatus.map((v) => (
                        <Radio.Button key={`quickStatusFilter-${v}`} value={v}>
                            {v}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </Col>
            <Col>
                <DatePicker.RangePicker value={rangePickerValue as any} onChange={handleRangePickerChanged} />
            </Col>
        </>
    );
};

