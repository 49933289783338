import React, { useState } from "react";
import styled from "styled-components";
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";
import { useContactWelcomeMutation } from "../../../../GraphQL/hooks/useContactWelcomeMutation";
import { useTranslation } from "react-i18next";
import { notification } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";

export default function Contact() {
  const { t } = useTranslation();

  const mutation = useContactWelcomeMutation();

  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    subject: '',
    description: ''
  });

  const [formError, setFormError] = useState({});
  const [recaptchaState, setRecaptchaState] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateForm();
  };

  const validateForm = () => {
    const errors = {
      firstName: !formData.firstName,
      email: !formData.email || !/\S+@\S+\.\S+/.test(formData.email),
      subject: !formData.subject,
      description: !formData.description
    };
    setFormError(errors);
    return !Object.values(errors).some(Boolean);
  };

 
  const onFinish = async () => {
    if (validateForm()) {
      mutation({
        firstName: formData.firstName,
        email: formData.email,
        subject: formData.subject,
        description: formData.description,
      })
        .then(() => {
          setFormData({
            firstName: '',
            email: '',
            subject: '',
            description: ''
          });
          setRecaptchaState(false)

          notification.success({
            message: "Your message was sent successfully.",
            description: "Our support team will contact you soon!",
          });
        })
        .catch(() => {
          notification.error({
            message: "Your message was not sent.",
            description: "Please try later!",
          });
        });
    }
  };

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{t('getInTouch')}</h1>
            <p className="font18">
              {t('textgetInTouch1')} <br />
              {t('textgetInTouch2')}
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form onSubmit={e => { e.preventDefault(); onFinish(); }}>
              
                <label className="font15">{t('firstName')}</label>
                <br/>
                {formError?.firstName && <span style={{ color: 'red' }}>Please input your first name!</span>}

                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
               
                />
                 
 
                <label className="font15">{t('email')}</label>
                <br/>
                {formError?.email && <span style={{ color: 'red' }}>Please provide a valid email!</span>}

                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                 />


                <label className="font15">{t('subject')}</label>
                <br/>
                {formError?.subject && <span style={{ color: 'red' }}>Please input your subject!</span>}
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                 />


                <label className="font15">{t('description')}</label>
                <br/>
                {formError?.description && <span style={{ color: 'red' }}>Please input your description message!</span>}
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                   rows="4" cols="50"
                />

                  <RecaptchaWrapper>
                    <ReCAPTCHA 
                      onChange={() => setRecaptchaState(true)}
                      onExpired={() =>setRecaptchaState(false)}
                      onErrored={() =>setRecaptchaState(false)}
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    />
                  </RecaptchaWrapper>
                  <div className="row">
                    <div  className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <br/>
                      <ButtonWrapper>
                        <button className="animate radius8 ButtonInput semiBold" htmlType="submit" disabled={!recaptchaState && Object.values(formError).some(Boolean)}>
                          {t('sendMessage')}
                        </button >
                      </ButtonWrapper>
                    </div>
                  </div>
              </Form>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }} className="contactImage">
                <ImgContact>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </ImgContact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
  @media (max-width: 350px) {
    img {
      width: 100%;
      height: auto;
    }
  }
`;


const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: '#fff';
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 20px;
    margin-bottom: 20px
  }

  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 460px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const RecaptchaWrapper = styled.div`
  @media (max-width: 460px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;



const ImgContact = styled.div`
  margin-top: 100px;
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 350px) {
    padding:  0px;
  }
`;