// ZoneEditor.js
import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useZoneManagement } from '../hooks/useZoneManagement';
import { useMapData } from '../hooks/useMapData';
import { useUpdateCityMapMutation } from '../../../GraphQL/hooks/useUpdateCityMapMutation';
import { stripTypenames } from '../../../shared/Utils';

const ZoneEditor = () => {
  const [form] = Form.useForm();
  const [disableSave, setDisableSave] = useState(false);
  const [{ selectedCity: currentSelectedCity, ...mapData }, setMapData] = useMapData();
  const { handleRemoveZone, handleEditZoneName } = useZoneManagement();
  const mutation = useUpdateCityMapMutation();

  useEffect(() => {
    if (currentSelectedCity) {
      form.setFieldValue('items', currentSelectedCity?.zones)
    }
  }, [form, currentSelectedCity])

  const handleUpdateCityMap = () => {
    mutation(stripTypenames(currentSelectedCity))
      .then((result) => console.log("Updated city data: ", result))
      .catch((err) => console.error("Error while saving new City data: ", err));
  };

  return (
    <div>

      <Form
        form={form}
        name="dynamic_form_item"
        style={{ width: '100%' }}
        autoComplete="off"
      >
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <div style={{ display: "flex", rowGap: 16, flexDirection: "column" }}>
              {fields.map((field, index) => {
                const zone = currentSelectedCity?.zones[field?.name];

                return (
                  <Form.Item
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input zone's name or delete this field."
                      }
                    ]}
                    label={`Zone`}
                    key={index}
                  >
                    <Input style={{ width: "80%" }}
                      placeholder="Zone name"
                      defaultValue={zone?.name_zone}
                      onChange={(event) => handleEditZoneName(zone?.id_zone, event.target.value)} />

                    {fields.length > 1 ? (
                      <DeleteOutlined
                        className="dynamic-delete-button"
                        style={{ paddingLeft: 10, color: "red" }}
                        onClick={() => {
                          remove(field.name);
                          handleRemoveZone(zone?.id_zone);
                        }}
                      />
                    ) : null}
                  </Form.Item>
                );
              })}

              {currentSelectedCity?._id &&
                <Button
                  type="primary"
                  disabled={disableSave}
                  onClick={() => handleUpdateCityMap()}
                  block
                >
                  Save changes
                </Button>
              }
            </div>
          )}
        </Form.List>
      </Form>

    </div>
  );
};

export default ZoneEditor;
