import { Col, Pagination, Row, Space, Spin, Table } from 'antd';
import React, { useState } from "react";
import { CheckCircleOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { useCurrentRider } from '../hooks/useCurrentRider';
import { useSortedRiderQuery } from '../hooks/useSortedRiderQuery';
import { RidersFilter } from './RidersFilter';
import RiderViewModal from './RiderViewModal';
import { Rider } from '../../../shared/types';
import RiderEditModal from './RiderEditModal';

const RiderTable = () => {

    const { sortedRiders: riders, refetch, loading, ...pagination } = useSortedRiderQuery();
    const [isViewModalVisible, setViewModalVisible] = useState(false);
    const [isEditModalVisible, setEditModalVisible] = useState(false);
    const { setCurrentRider } = useCurrentRider();

    const handleViewRider = (rider) => {
        setCurrentRider(rider);
        setViewModalVisible(true);
    };
console.log(riders)
    const handleEditRider = (user: Rider) => {
        setCurrentRider(user);
        setEditModalVisible(true);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'logicalId',
            key: 'logicalId',
            sorter: (a, b) => a.logicalId - b.logicalId,
            width: 80,
        },
        {
            title: 'fullName',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone number',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'signing method',
            dataIndex: 'signingMethod',
            key: 'signingMethod',
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            render: (text, record) => { return record.country }
        },
        {
            title: 'Identity Check',
            dataIndex: 'identityCheck',
            key: 'identityCheck',
            render: (text, record: Rider) => { return record.identityCheck ? <CheckCircleOutlined title='Checked' style={{ fontSize: '16px', color: 'green' }} /> : <ExclamationCircleOutlined  title='Not checked' style={{ fontSize: '16px', color: 'red' }} /> }
        },
        {
            title: "Action",
            key: "action",
            render: (_, user) =>
                <Space size="middle">
                    <a href="#" onClick={() => handleViewRider(user)} ><EyeOutlined /></a>
                    <a href="#" onClick={() => handleEditRider(user)} ><EditOutlined style={{ fontSize: '16px', color: 'green' }} /></a>
                </Space>
        },

    ];

    const Paginator = () => (
        <Row justify={'center'}>
            <Col>
                <Pagination
                    size="default"
                    total={pagination.totalCount}
                    pageSize={pagination.pageSize}
                    current={pagination.page}
                    showTotal={(total) => `Total Riders ${total}`}
                    onChange={(newPage) => pagination.setPage(newPage)}
                    showQuickJumper={true}
                    showSizeChanger={false}
                    showLessItems={false}
                />
            </Col>
        </Row>
    );


    return (
        <>

            {isEditModalVisible && <RiderEditModal updateList={refetch} isOpen={isEditModalVisible} onRequestClose={setEditModalVisible} />}
            {isViewModalVisible && <RiderViewModal isOpen={isViewModalVisible} onRequestClose={setViewModalVisible} />}

            <div style={{ flex: '1 1 auto' }}>
                <Row gutter={[16, 6]} style={{ paddingBottom: 10, paddingTop: 20 }}>
                    <RidersFilter />
                </Row>

                <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
                    <Col xs={24}>
                    <Spin spinning={loading}>
                        <Table
                            dataSource={riders}
                            columns={columns}
                            pagination={false}
                            rowKey={() => Math.random().toString()}
                            scroll={{ x: 1500, y: 400 }}>
                        </Table>
                        </Spin>
                    </Col>
                </Row>
                <Paginator />
            </div>
        </>
    )
};

export default RiderTable;