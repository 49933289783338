import React, { useState } from "react";
import { Input, Form, InputNumber, Select, Modal, Button } from 'antd';
import { Pass, allPriceUnitsTypes, allVehiculeTypes } from "../../../shared/types";
import { handleFormChange, layoutEdit, validateMessages } from "../../../shared/Utils";
import { useCreatePassMutation } from "../../../GraphQL/hooks/useCreatePassMutation";

const PassCreateModal = ({ isOpen, onRequestClose, updateList }) => {
  const [form] = Form.useForm();
  const [formError, setFormError] = useState(false);

  const mutation = useCreatePassMutation();


  const handleCommit = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        mutation({ ...values })
          .then(() => {
            onRequestClose(false)
            updateList()
          })
          .finally(() => {
            form.resetFields();
            console.log("New Pass was successfuly added")
          })
          .catch((err) => console.error(err));
      }
    } catch (errorInfo) {
      console.log('Failed: Form is empty');
    }
  };

  const handleCancel = () => {
    onRequestClose(false);
  };



  return (
    <Modal forceRender title="Add Pass" open={isOpen} visible={isOpen} onCancel={handleCancel} footer={[
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="create" type="primary" onClick={handleCommit} disabled={formError}>
        Create
      </Button>,
    ]}>
      <Form<Pass> {...layoutEdit} form={form} name="nest-messages" autoComplete="off" onFieldsChange={() => { setFormError(handleFormChange(form)) }} validateMessages={validateMessages}>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input type="textarea" placeholder="Name of the pass" style={{ width: '60%' }} />
        </Form.Item>
        <Form.Item name="note" label="Note" rules={[{ required: true }]}>
          <Input type="textarea" placeholder="Description of the pass" style={{ width: '60%' }} />
        </Form.Item>
        <Form.Item name="initialPrice" label="Initial price" rules={[{ required: true }]}>
          <InputNumber placeholder="Initial pass price" style={{ width: '50%' }}/>
        </Form.Item>
        <Form.Item name="priceUnit" label="Price unit" rules={[{ required: true }]}>
          <Select placeholder="Select price unit" style={{ width: '60%' }}>
            {(allPriceUnitsTypes ?? []).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="discount" label="Discount" rules={[{ required: true }]}>
          <InputNumber placeholder="Discounted pourcent %" style={{ width: '50%' }}/>
        </Form.Item>
        <Form.Item name="duration" label="Duration" rules={[{ required: true }]}>
          <InputNumber placeholder="Duration" />
        </Form.Item>
        <Form.Item name="unlockPrice" label="Unlock price" rules={[{ required: true }]}>
          <InputNumber placeholder="Unlock price"/>
        </Form.Item>
        <Form.Item name="minutePrice" label="Minute price" rules={[{ required: true }]}>
          <InputNumber placeholder="Minute price"/>
        </Form.Item>
        <Form.Item name="vehiculeType" label="Vehicule type" rules={[{ required: true }]}>
          <Select placeholder="Select type of vehicule" style={{ width: '60%' }}>
            {(allVehiculeTypes ?? []).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PassCreateModal;