import { Col, Row } from 'antd';
import React, { useEffect } from "react";
import { Select } from 'antd';
import { useGetCitiesMapQuery } from '../../../GraphQL/hooks/useGetCitiesMapQuery';
import { useGetVehiculesMapQuery } from '../../../GraphQL/hooks/useGetVehiculesMapQuery';
import ZoneEditor from './ZoneEditor';
import MapContainer from './MapContainer';
import { useMapData } from '../hooks/useMapData';


const MapContent = () => {
  const { data: cities, loading: isCitiesLoading } = useGetCitiesMapQuery();
  const { data: vehicules, loading: isVehiculesLoading } = useGetVehiculesMapQuery();
  const [mapData, setMapData] = useMapData();

  useEffect(() => {
    setMapData({
      ...mapData,
      cities: cities,
      vehicules: vehicules,
      allZones: [].concat(...cities.map(city => city.zones))
    }
    )
  }, [cities, vehicules])


  const handleCityChange = (city) => {
    const selectedCity = mapData?.cities?.filter((e) => e.cityName === city);
    setMapData({ ...mapData, selectedCity: selectedCity[0] });
  };

  return (
    <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
      <Col span={4}>
        <Select placeholder="Select City.." style={{ width: '100%' }} onChange={handleCityChange} >
          {mapData?.cities?.map((city) => (
            <Select.Option key={city.cityName} value={city.cityName}>
              {city.cityName}
            </Select.Option>
          ))}
        </Select>
        <div style={{ flex: '1 1 auto' }}>
          <Row gutter={20} style={{ paddingBottom: 10, paddingTop: 15 }}>
            {mapData?.cities?.length &&
              <ZoneEditor />
            }
          </Row>
        </div>
      </Col>
      <Col span={20}>
        {mapData?.cities?.length > 0 && mapData?.vehicules?.length > 0 ? ( // Check if cities and vehicules are available
          <MapContainer />
        ) : (
          // Render a loading indicator or message when data is not available
          <div>Loading...</div>
        )}
      </Col>
    </Row>
  )
};

export default MapContent;