import { useMutation } from '@apollo/client';
import { Vehicule, VehiculeInput } from '../../shared/types';
import { CITRIN_CREATE_VEHICULE, CreateVehiculeRequest, CreateVehiculeResponse } from '..';

type CreateMutationFunction = (request: VehiculeInput) => Promise<Vehicule>;
export const useCreateVehiculeMutation = (): CreateMutationFunction => {

    const [mutation, { client }] = useMutation<CreateVehiculeResponse, CreateVehiculeRequest>(CITRIN_CREATE_VEHICULE);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const created = data?.CITRIN_MGT_createVehicule;
     //   updateGetVehiculeCache(client, updated, ...(keys(request) as any));
        return created;
    };
};
