import { SliderMarks } from "antd/es/slider";
import { isEmpty } from 'lodash';

export const isNotEmpty = (s: string) => s && !isEmpty(s);
export const formatter = (value) => `${value}%`;
export const markSteps: SliderMarks = {
  0: { label: <strong>1%</strong> },
  25: { label: <strong>25%</strong> },
  50: { label: <strong>50%</strong> },
  75: { label: <strong>75%</strong> },
  100: { label: <strong>100%</strong> },
};

export const confirmPasswordRules = [{
  required: true,
  message: 'Please confirm your password!',
},
({ getFieldValue }) => ({
  validator(_, value) {
    if (!value || getFieldValue('settingId').password === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('The new password that you entered do not match!'));
  },
}),
]