import { useMutation } from '@apollo/client';
import { Setting } from '../../shared/types';
import { CITRIN_UPDATE_SETTING, UpdateSettingRequest, UpdateSettingResponse,  } from '..';

type UpdateMutationFunction = (request: Setting) => Promise<Setting>;
export const useUpdateSettingMutation = (): UpdateMutationFunction => {

    const [mutation, { client }] = useMutation<UpdateSettingResponse, UpdateSettingRequest>(CITRIN_UPDATE_SETTING);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const updated = data?.CITRIN_MGT_updateSetting;
     //   updateGetOperatorCache(client, updated, ...(keys(request) as any));
        return updated;
    };
};
