import { useMutation } from '@apollo/client';
import { Rider } from '../../shared/types';
import { CITRIN_UPDATE_RIDER, UpdateRiderRequest, UpdateRiderResponse } from '..';

type UpdateMutationFunction = (request: Rider) => Promise<Rider>;
export const useUpdateRiderMutation = (): UpdateMutationFunction => {

    const [mutation, { client }] = useMutation<UpdateRiderResponse, UpdateRiderRequest>(CITRIN_UPDATE_RIDER);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const updated = data?.CITRIN_MGT_updateRider;
     //   updateGetVehiculeCache(client, updated, ...(keys(request) as any));
        return updated;
    };
};
