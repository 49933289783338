import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
     
      <span className="buttonTitle">{title}</span>
    </Wrapper>
  );
}

const Wrapper = styled.button`
border: 1px solid ${(props) => (props.border ? "#F5D91D" : "#F5D91D")};
background-color: ${(props) => (props.border ? "#F5D91D" : "#F5D91D")};
width: 120%;
padding: 15px;
outline: none;
color: ${(props) => (props.border ? "#F5D91D" : "#000000")};
transition: all 0.3s ease; /* Add transition for smooth hover effect */

&:hover {
  background-color: ${(props) => (props.border ? "#F5D91D" : "#F5D91D")};
  border-color: #F5D91D;
  color: ${(props) => (props.border ? "#F5D91D" : "#000000")};
}
`;
