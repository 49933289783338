import { useMutation } from '@apollo/client';
import { Vehicule } from '../../shared/types';
import { CITRIN_UPDATE_VEHICULE, UpdateVehiculeRequest, UpdateVehiculeResponse } from '..';

type UpdateMutationFunction = (request: Vehicule) => Promise<Vehicule>;
export const useUpdateVehiculeMutation = (): UpdateMutationFunction => {

    const [mutation, { client }] = useMutation<UpdateVehiculeResponse, UpdateVehiculeRequest>(CITRIN_UPDATE_VEHICULE);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const updated = data?.CITRIN_MGT_updateVehicule;
     //   updateGetVehiculeCache(client, updated, ...(keys(request) as any));
        return updated;
    };
};
