import { Col, Pagination, Row, Space, Spin, Table } from 'antd';
import React, { useEffect, useState } from "react";
import { EyeOutlined, DownloadOutlined} from '@ant-design/icons';
import moment from 'moment';
import { TripsFilter } from './TripsFilter';
import { useSortedTripQuery } from '../hooks/useSortedTripQuery';
import { useCurrentTrip } from '../hooks/useCurrentTrip';
import TripsViewModal from './TripsViewModal';
import { useGetInvoiceByTripIdQuery } from '../../../GraphQL/hooks/useGetInvoiceByTripIdQuery';

const TripTable = () => {

  const { sortedTrips: trips, refetch, loading, ...pagination } = useSortedTripQuery();

  const [isViewModalVisible, setViewModalVisible] = useState(false);
  const { setCurrentTrip } = useCurrentTrip();

  const [tripId, setTripId] = useState(null);

  const { loading: loadingInvoice, data: invoiceData } = useGetInvoiceByTripIdQuery(tripId);

  console.log("tripId", tripId);
  console.log("ij", invoiceData);

  useEffect(() => {
    if (tripId && invoiceData && 'invoiceAttachment' in invoiceData && invoiceData.invoiceAttachment) {
      const { url } = invoiceData.invoiceAttachment;
      if (url) {
        window.open(url, '_blank');
        setTripId(null);
      }
    }
  }, [invoiceData, tripId]);


  const handleViewTrip = (rider) => {
    setCurrentTrip(rider);
    setViewModalVisible(true);
  };

  const handleViewInvoice = (trip) => {
    setTripId(trip._id);
    setViewModalVisible(false);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'logicalId',
      key: 'logicalId',
      sorter: (a, b) => a.logicalId - b.logicalId,
      width: 80,

    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',

    },
    {
      title: 'Start date',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: (a, b) => a.startDate - b.startDate,
      render: (text, record) => { return moment(Number(record.startDate)).format("DD-MM-YYYY"); }
    },
    {
      title: 'End date',
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: (a, b) => a.endDate - b.endDate,
      render: (text, record) => { return moment(Number(record.endDate)).format("DD-MM-YYYY"); }
    },
    {
      title: "Action",
      key: "action",
      render: (_, trip) =>
        <Space size="middle">
          <a href="#" onClick={() => handleViewTrip(trip)} ><EyeOutlined /></a>
          <a href="#" onClick={() => handleViewInvoice(trip)}><DownloadOutlined /></a>

        </Space>
    },

  ];

  const Paginator = () => (
    <Row justify={'center'}>
      <Col>
        <Pagination
          size="default"
          total={pagination.totalCount}
          pageSize={pagination.pageSize}
          current={pagination.page}
          showTotal={(total) => `Total Trips ${total}`}
          onChange={(newPage) => pagination.setPage(newPage)}
          showQuickJumper={true}
          showSizeChanger={false}
          showLessItems={false}
        />
      </Col>
    </Row>
  );

  return (
    <>
      {isViewModalVisible && <TripsViewModal isOpen={isViewModalVisible} onRequestClose={setViewModalVisible} />}

      <div style={{ flex: '1 1 auto' }}>
        <Row gutter={[16, 6]} style={{ paddingBottom: 10, paddingTop: 20 }}>
          <TripsFilter />
        </Row>
        <Row gutter={16} style={{ paddingBottom: 10, paddingTop: 10 }}>
          <Col xs={24}>
          <Spin spinning={loading || loadingInvoice}>
            <Table
              dataSource={trips}
              columns={columns}
              pagination={false}
              rowKey={() => Math.random().toString()}
              scroll={{ x: 800 }}>
            </Table>
            </Spin>
          </Col>
        </Row>
        <Paginator />
      </div>
    </>
  )
};

export default TripTable;