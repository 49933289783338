import { useEffect, useMemo } from "react";
import { City } from "../../../shared/types";
import { chunk } from 'lodash';
import { usePageState } from "../../../Hooks/usePageState";
import { useGetAllCityQuery } from "../../../GraphQL/hooks/useGetAllCityQuery";
import { useOverviewLinkState } from "../linkstate/cityOverview.linkstate";

const pageSize = 10;
const getPageCount = (Cities: City[]) => Math.ceil(Cities?.length / pageSize);
const getCurrentPage = (requestedPage: number, cities: City[]) => {
    const pageCount = getPageCount(cities);
    return requestedPage < 1 ? 1 : requestedPage > pageCount ? pageCount : requestedPage;
};

export const useSortedCityQuery = () => {
    const { setLoading } = usePageState();
    const [state, setState] = useOverviewLinkState();
    const { page } = state;


    const { data: unsortedCities, refetch, loading } = useGetAllCityQuery();
    useEffect(() => setLoading(loading), [loading]);

    const setPage = (newPage: number) => {
        const pageCount = getPageCount(unsortedCities);
        // console.log('new page', newPage, pageCount);
        const page = newPage < 1 ? 1 : newPage > pageCount ? pageCount : newPage;
        setState({ ...state, page });
    };

    const { pageCount, totalCount, sortedCities } = useMemo(() => {
        const totalCount = unsortedCities?.length ?? 0;
        const pageCount = getPageCount(unsortedCities);
        const sortedCities = chunk(unsortedCities, pageSize);

        return { totalCount, pageCount, sortedCities };
    }, [unsortedCities]);

    const currentPage = getCurrentPage(page, unsortedCities);

    return {
        sortedCities: sortedCities?.length > 0 ? sortedCities[currentPage - 1] : [],
        refetch: () => refetch(),
        page: page ?? 1,
        pageCount,
        totalCount,
        pageSize,
        setPage,
        loading
    };
};