import { Col, Modal, Row, Tabs } from 'antd';
import React, { useState } from "react";
import Title from "antd/es/typography/Title";
import RidePassDetails from './RidePassDetails';
import DescriptionDetails from './DescriptionDetails';
import { ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import { useCurrentRider } from '../hooks/useCurrentRider';


const RiderViewModal = ({ isOpen, onRequestClose }) => {
    const [currentTab, setCurrentTab] = useState('0')
    const { currentRider } = useCurrentRider();

    const handleCancel = () => {
        setCurrentTab('1')
        onRequestClose(false);
    };

    const items = [
        {
            label: <span><UserOutlined />Description details</span>,
            children: <DescriptionDetails />,
            key: '0',
            disabled: false
        },
        {
            label: <span><ShoppingCartOutlined />Pass details</span>,
            children: <RidePassDetails />,
            key: '1',
            disabled: !currentRider?.ridePassId
        },

    ];


    return (
        <Modal open={isOpen} onCancel={handleCancel} footer={null} >

            <Title level={3}>Rider Details</Title>
            <Row>
                <Col span={24}>
                    {<Tabs defaultActiveKey={currentTab} items={items} />}
                </Col>
            </Row>
        </Modal>

    )
};

export default RiderViewModal;