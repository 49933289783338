import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMapProvider } from "@ubilabs/google-maps-react-hooks";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import SuperClusterAlgorithm from "../Utils/superClusterAlgorithm";
import { useZoneManagement } from '../hooks/useZoneManagement';
import { polygonOptions } from '../Utils/mapOptions';
import { useMapData } from '../hooks/useMapData';
import { v4 as uuidv4 } from 'uuid';
import { Coord } from '../../../shared/types';

const MapContainer = () => {
  const [{ vehicules, allZones, selectedCity, ...mapData }, setMapData] = useMapData();
  const { handleAddZone, handleEditZoneCoord } = useZoneManagement();

  const [mapContainer, setMapContainer] = useState(null);
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: 37.2167, lng: 10.1333 });
  const [drawingManager, setDrawingManager] = useState(null);

  const onLoad = useCallback((map) => {
    setMap(map);
    addMarkers(map, vehicules)
    addZones(map, allZones)
    drawZones(map)
  }, []);

  useEffect(() => {
    if (selectedCity && map && mapContainer) {
      const { latitude, longitude } = selectedCity.location.coordinates;
      map.panTo({ lat: latitude, lng: longitude });
    }
  }, [selectedCity?.location]);

  const addZones = (map, zones) => {
    const polygons = zones?.map(({ id_zone, name_zone, coord }) => {
      const polygon = new google.maps.Polygon({
        map,
        paths: coord,
        ...polygonOptions
      });

      addLabelToZone(polygon, coord, name_zone, map);

      // Get paths from polygon and set event listeners for each path separately
      polygon.getPaths().forEach(function (path, index) {
        google.maps.event.addListener(path, 'insert_at', update_polygon_closure('insert_at', path, id_zone));
        google.maps.event.addListener(path, 'remove_at', update_polygon_closure('remove_at', path, id_zone));
        google.maps.event.addListener(path, 'set_at', update_polygon_closure('set_at', path, id_zone));
      });

      return polygon;
    });

    new MarkerClusterer({
      markers: polygons,
      map,
      algorithm: new SuperClusterAlgorithm({ radius: 50, maxZoom: 18 }),
    });
  }

  const addMarkers = (map, vehicules) => {
    const infoWindow = new google.maps.InfoWindow();
    const markers = vehicules?.map(({ qrcode, location, batteryLevel, powerType, status, type }) => {
      const marker = new google.maps.Marker({ position: { lat: location.coordinates.latitude, lng: location.coordinates.longitude }, icon: `./assets/${type}.png` });
      marker.addListener("click", () => {
        infoWindow.setPosition({ lat: location.coordinates.latitude, lng: location.coordinates.longitude });
        infoWindow.setContent(`
          <div class="info-window">
            <h4>QrCode:   ${qrcode}</h5>
            <h4>Battery:  ${batteryLevel}%</h4>
            <h4>Type:     ${powerType}</h4>
            <h4>Status:   ${status}</h4>
          </div>
        `);
        infoWindow.open({ map });
      });

      return marker;
    });

    new MarkerClusterer({
      markers,
      map,
      algorithm: new SuperClusterAlgorithm({ radius: 200 }),
    });
  }


  const drawZones = (map) => {
    const newDrawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.MARKER,
          google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      polygonOptions: polygonOptions
    });

    newDrawingManager.setMap(map);

    const create_polygon_closure = (event) => {
      let id_zone = uuidv4();
      let polygon = event.overlay;

      polygon.set("editable", true);
      let paths = polygon.getPath().getArray().map((point) => ({
        lat: point.lat(),
        lng: point.lng(),
      }));
      handleAddZone(id_zone, paths);

      // Get paths from polygon and set event listeners for each path separately
      polygon.getPaths().forEach(function (path, index) {
        google.maps.event.addListener(path, 'insert_at', update_polygon_closure('insert_at', path, id_zone));
        google.maps.event.addListener(path, 'remove_at', update_polygon_closure('remove_at', path, id_zone));
        google.maps.event.addListener(path, 'set_at', update_polygon_closure('set_at', path, id_zone));
      });
    }
    google.maps.event.addListener(newDrawingManager, "overlaycomplete", create_polygon_closure);

    setDrawingManager(newDrawingManager);
  }

  const update_polygon_closure = (event, path, id_zone) => () => {
    let paths = path.getArray().map((point) => ({
      lat: point.lat(),
      lng: point.lng(),
    }));

    handleEditZoneCoord(id_zone, paths);
  };

  const addLabelToZone = (polygon, coord: Coord[], name_zone: string, map) => {
    // Calculate the center of the polygon
    const polygonBounds = new google.maps.LatLngBounds();
    coord.forEach((point) => polygonBounds.extend(point));
    const center = polygonBounds.getCenter();

    // Create an InfoWindow to display the label
    const infoWindow = new google.maps.InfoWindow({ content: name_zone });

    // Attach an event listener to the polygon to open the InfoWindow
    google.maps.event.addListener(polygon, 'click', () => {
      infoWindow.setPosition(center);
      infoWindow.open(map);
    });
  }

  return (
    <div>
      <GoogleMapProvider
        googleMapsAPIKey={`${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=drawing&callback`}
        options={{ zoom: 12, center }}
        mapContainer={mapContainer}
        onLoad={onLoad}
      >
        <div ref={(node) => setMapContainer(node)} style={{ height: "100vh" }} />
      </GoogleMapProvider>
    </div>
  );
};
export default MapContainer;
