import React from 'react';
import { Col, Input, Select, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useOverviewLinkStateActions } from '../hooks/useOverviewLinkStateActions';
import { useOverviewLinkState } from '../linkstate/OperatorOverview.linkstate';
import { useGetAllCityQuery } from '../../../GraphQL/hooks/useGetAllCityQuery';
import { allOperatorTypes } from '../../../shared/types';

export const OperatorFilter = () => {
    const { data: cities } = useGetAllCityQuery();
    const [{ search, selectedCities, selectedTypes }] = useOverviewLinkState();
    const { patch: patchState } = useOverviewLinkStateActions();

    return (
        <>
            <Col style={{ width: 300 }}>
                <Input
                    value={search}
                    placeholder={'Search by name, email, TVA'}
                    onChange={(e) => patchState({ search: e.target.value })}
                    addonBefore={<SearchOutlined />}
                />
            </Col>
            <Col style={{ width: 200 }}>
                <Tooltip title={`Filter by Type: ${(selectedTypes ?? []).map((type) => `#${type}`).join(' ')}`}>
                    <Select
                        placeholder="Type of operator"
                        mode="multiple"
                        value={selectedTypes ?? []}
                        style={{ width: '100%', height: 32 }}
                        maxTagCount={1}
                        maxTagTextLength={10}
                        onChange={(e) => patchState({ selectedTypes: e })}
                    >
                        {(allOperatorTypes ?? []).map((type) => (
                            <Select.Option key={type} value={type}>
                                {type}
                            </Select.Option>
                        ))}
                    </Select>
                </Tooltip>
            </Col>
            <Col style={{ width: 250 }}>
                <Tooltip title={`Filter by City: ${(selectedCities ?? []).map((city) => `#${city}`).join(' ')}`}>
                    <Select
                        placeholder="Cities"
                        mode="multiple"
                        value={selectedCities ?? []}
                        style={{ width: '100%', height: 32 }}
                        maxTagCount={1}
                        maxTagTextLength={10}
                        onChange={(e) => patchState({ selectedCities: e })}
                    >
                        {(cities ?? []).map((city) => (
                            <Select.Option key={city._id} value={city.cityName}>
                                {city.cityName}
                            </Select.Option>
                        ))}
                    </Select>
                </Tooltip>
            </Col>
            <Col>

            </Col>
            <Col>

            </Col>

        </>
    );
};

