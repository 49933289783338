import { useMutation } from '@apollo/client';
import { ResetPasswordRequestInput } from '../../shared/types';
import { CITRIN_RESET_PASSWORDREQUEST, CreateResetPasswordRequestRequest, CreateResetPasswordRequestResponse } from '..';

type CreateMutationFunction = (request: ResetPasswordRequestInput) => Promise<String>;
export const useResetPasswordRequestMutation = (): CreateMutationFunction => {

    const [mutation, { client }] = useMutation<CreateResetPasswordRequestResponse, CreateResetPasswordRequestRequest>(CITRIN_RESET_PASSWORDREQUEST);
    return async (request) => {
        const { data } = await mutation({ variables: { request } });
        const ResetRequestResponse = data?.CITRIN_MGT_resetPasswordRequest;
        return ResetRequestResponse;
    };
};
