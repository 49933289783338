import React, { useState } from "react";
import { Button, Form, Input, Tooltip, Upload, notification } from 'antd';
import { SupportInput } from "../../../shared/types";
import { handleFormChange, layout } from "../../../shared/Utils";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { useSupportUserMutation } from "../../../GraphQL/hooks/useSupportUserMutation";
import { useUserState } from "../../../Hooks/useUserState";

const SupportModalView = () => {

    const [form] = Form.useForm();
    const [formError, setFormError] = useState(false);
    const [fileList, _setFileList] = useState<File[]>([]);
    const { ...userState } = useUserState();

    const setFileList = (newValue: File[]) => {
        _setFileList(newValue);
    };

    const mutation = useSupportUserMutation();

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 16,
            },
        },
    };

    const onFinish = async () => {
        const values = await form.validateFields();
        if (values) {
            mutation({ email: values.email, subject: values.subject, description: values.description, operatorId: userState.operatorId },
                fileList[0]
            ).then((result: String) => {
                form.resetFields();
                notification.success({
                    message: "Mail was sent successfully.",
                    description: "Our support team with contact you soon!",
                });
            }).catch((err) => {
                notification.error({
                    message: "Mail was not sent.",
                    description: "Please try later!",
                });
            });
        }
    };

    const onReset = () => {
        form.resetFields();
    };

    return (
        <div>
            <Form<SupportInput>
                form={form}
                name="normal_login"
                className="login-form"
                {...layout}
                onFinish={onFinish}
                onFieldsChange={() => { setFormError(handleFormChange(form)) }}
                {...formItemLayout}
                autoComplete="off"
            >
                <Form.Item name="email" label="E-mail"
                    rules={[{ type: 'email', message: 'Please provide a valid email!' }, { required: true, message: 'Please input your email!' }]}
                    style={{ width: '150%' }}
                >
                    <Input placeholder="Enter a valid email address" />
                </Form.Item>

                <Form.Item name="subject" label="Subject" rules={[{ required: true, message: 'Please input your subject!' }]} style={{ display: 'inline-block', width: '150%' }}>
                    <Input placeholder="Please enter your subject" />
                </Form.Item>

                <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input your description message!' }]} style={{ display: 'inline-block', width: '150%' }}>
                    <Input.TextArea placeholder="Please enter your description message" showCount maxLength={100} rows={4} />
                </Form.Item>

                <Form.Item name={"attachmentsFiles"} label={
                    
                    <Tooltip title="Here you could attach files e.g. PDF or screenshot" placement="rightTop">
                          Attachment  <InfoCircleOutlined style={{ color: '#1677ff', fontSize: '15px' }} />
                    </Tooltip>
                    
                    } valuePropName='fileList' getValueFromEvent={(event) => { return event?.fileList }}
                    rules={[{ required: false, message: "please upload your File." },
                    {
                        validator(_, fileList) {
                            return new Promise((resolve, reject) => {
                                if (fileList?.size > 5000000) {
                                    reject("File size exceeded")
                                } else {
                                    resolve("Successs");
                                }
                            });
                        }
                    }
                    ]}
                    style={{ display: 'inline-block', width: '150%' }}
                >  
                    <Upload
                        accept="image/png, image/jpeg, .pdf"
                        maxCount={1}
                        fileList={fileList as any}
                        beforeUpload={(_, fileList) => {
                            setFileList(fileList);
                            return false;
                        }}
                        customRequest={(info) => {
                            setFileList([info?.file as File])
                        }}
                        showUploadList={false}
                    >
                        <Button icon={<UploadOutlined />}>Select file</Button>
                        {fileList[0]?.name}
                    </Upload>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24 }} style={{ width: '150%', justifyContent: 'end', display: 'flex' }} >
                    <Button type="default" htmlType="reset" onClick={onReset} style={{ marginRight: "8px" }}>
                        Reset
                    </Button>
                    <Button type="primary" htmlType="submit" disabled={formError}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default SupportModalView;

