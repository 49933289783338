import { useState } from 'react';
import './App.css';
import CreateRoutes from './routes';
import { PageState, PageStateContext } from './Hooks/usePageState';
import { UserState, UserStateContext } from './Hooks/useUserState';
import { Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; 

const App = () =>  {
    const [pageState, setPageState] = useState<PageState>({} as PageState);
    const [userState, setUserState] = useState<UserState>({} as UserState);

    return (
        <div className="App">
            <UserStateContext.Provider value={[userState, setUserState]}>
              <PageStateContext.Provider value={[pageState, setPageState]}>
                <I18nextProvider i18n={i18n}>
                  <Routes>
                    <Route path="/*" element={<CreateRoutes/>} />
                  </Routes>
                </I18nextProvider>
              </PageStateContext.Provider>
            </UserStateContext.Provider>
        </div>
    );
}

export default App;
