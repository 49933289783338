import { Vehicule } from "../../../shared/types";
import React, { createContext, useContext, useState } from "react";

type ContextType<S> = [S, React.Dispatch<React.SetStateAction<S>>] | null;

const initialState = null;
const VehiculeInfoContext = createContext<ContextType<Vehicule>>(null);
VehiculeInfoContext.displayName = "VehiculeInfoContext";

function useCurrentVehicule() {
    const context = useContext(VehiculeInfoContext);
    if (!context) {
        throw new Error(`useCurrentVehicule must be used within a VehiculeInfoProvider`);
    }

    const [currentVehicule, setCurrentVehicule] = context;

    return {
        currentVehicule,
        setCurrentVehicule
    };
}

function VehiculeInfoProvider(props) {
    const vehicule = useState(initialState);
    return <VehiculeInfoContext.Provider value={vehicule} {...props} />;
}

export { VehiculeInfoProvider, useCurrentVehicule };
