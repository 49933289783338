import { useEffect, useMemo } from "react";
import { useGetTripsQuery } from "../../../GraphQL/hooks/useGetTripsQuery";
import { useOverviewLinkState } from "../linkstate/tripOverview.linkstate";
import { Trip, TripQueryFilter } from "../../../shared/types";
import { chunk, isEmpty, isString } from 'lodash';
import { usePageState } from "../../../Hooks/usePageState";
import { useUserState } from "../../../Hooks/useUserState";

const pageSize = 10;
const getPageCount = (trips: Trip[]) => Math.ceil(trips?.length / pageSize);
const getCurrentPage = (requestedPage: number, trips: Trip[]) => {
    const pageCount = getPageCount(trips);
    return requestedPage < 1 ? 1 : requestedPage > pageCount ? pageCount : requestedPage;
};

export const useSortedTripQuery = () => {
    const { setLoading } = usePageState();
    const [state, setState] = useOverviewLinkState();
    const { ...userState } = useUserState();
    const { page, quickStatusFilter, selectedDateRange } = state;

    const request = useMemo(
        () =>
        ({
            status: quickStatusFilter ?? '',
            dateRange: selectedDateRange,
            operatorId: userState.operatorId,
        } as TripQueryFilter),
        [quickStatusFilter, selectedDateRange]
    );
    const { data: unsortedTrips, refetch, loading } = useGetTripsQuery(request);
    useEffect(() => setLoading(loading), [loading]);

    const setPage = (newPage: number) => {
        const pageCount = getPageCount(unsortedTrips);
        // console.log('new page', newPage, pageCount);
        const page = newPage < 1 ? 1 : newPage > pageCount ? pageCount : newPage;
        setState({ ...state, page });
    };

    const { pageCount, totalCount, sortedTrips } = useMemo(() => {
        const totalCount = unsortedTrips?.length ?? 0;
        const pageCount = getPageCount(unsortedTrips);
        const sortedTrips = chunk(unsortedTrips, pageSize);
        return { totalCount, pageCount, sortedTrips };
    }, [unsortedTrips]);

    const currentPage = getCurrentPage(page, unsortedTrips);

    return {
        sortedTrips: sortedTrips?.length > 0 ? sortedTrips[currentPage - 1] : [],
        refetch: () => refetch({ request }),
        page: page ?? 1,
        pageCount,
        totalCount,
        pageSize,
        setPage,
        loading
    };
};